//basic
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

//common
import { formatDateToYYYYmmdd } from '../../../../utils/dateFunction.js';

/* ───────────────────────────────────────────────────────────────
 * StockSummary
 * 주식 요약 내용 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const StockSummary = ({ data }) => {
  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD
  const [showMessage, setShowMessage] = useState(true);

  const topMostItem = data && data.length > 0 ? [data[0]] : [];

  useEffect(() => {
    if (data && data.length > 0) {
      const hasSummaryData = data.some(item => item.summary_date === today);
      setShowMessage(!hasSummaryData);
    } else {
      setShowMessage(true);
    }
  }, [data, today]);

  // 요약 수집기준 시간 포멧
  const formatCreatedAt = createdAt => {
    const date = new Date(createdAt);
    const formattedDate = `${date.getFullYear()}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')} ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;

    return formattedDate;
  };

  // const filteredStock = data ? data.filter(item => item.summary_date === today) : [];
  return (
    <div className="maker cont_box">
      {topMostItem ? (
        <div>
          {topMostItem.map(item => (
            <div key={item.id}>
              <br />
              <p className="label2 primary">
                ※ 주식 현황 요약 ({formatCreatedAt(data[0].created_at)} 기준)
              </p>
              <div>
                <Text
                  className="body3 line-height"
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        showMessage && (
          <div>
            <Text className="label2">현재 데이터 분석 중입니다.</Text>
          </div>
        )
      )}
    </div>
  );
};

export default StockSummary;

const Text = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;
