import React, { useState, useEffect } from 'react';

// css
import '../../styles/SignupPage.css';

/* ─────────────────────────────────────────────────────
 * ConsentForm:  이용약관 form 컴포넌트
 * ─────────────────────────────────────────────────────
 */

function ConsentForm({ agreed, setAgreed }) {
  const [termsOfService, setTermsOfService] = useState(''); // 서비스 이용약관 담을 변수
  const [privacyPolicy, setPrivacyPolicy] = useState(''); // 개인정보처리방침 담을 변수

  // 개별 동의 체크박스 상태 변경 함수.
  const handleAgree = index => {
    setAgreed(prevAgreements => {
      const newAgreements = [...prevAgreements];
      newAgreements[index] = !newAgreements[index];
      return newAgreements;
    });
  };

  // 모든 동의 체크박스 상태 변경 함수.
  const handleAgreeAll = () => {
    setAgreed(prevAgreements => {
      // 모든 항목에 대해 동의 상태를 반전시킴
      const newAgreements = prevAgreements.map(() => !prevAgreements.every(agreed => agreed));
      return newAgreements;
    });
  };

  useEffect(() => {
    setTermsOfService('이용약관 <span>*</span>');
    setPrivacyPolicy('개인정보 처리방침 <span>*</span>');
  }, [termsOfService, privacyPolicy]);

  return (
    <div className="consentForm_wrap">
      {/* 이용약관 */}
      <div className="consent_cont">
        <p dangerouslySetInnerHTML={{ __html: termsOfService }}></p>
        <div className="scroll_wrap">
          <div className="scroll_box">
            <span>서비스 운영방침</span>
            <br />
            저희 ㈜레인보우브레인(이하 ‘회사’)은 회원의 원활한 서비스 이용을 위해 주기적으로 운영 및
            관리합니다.
            <br />
            <br />
            회원이 관련 법령, 본 약관, 계정 및 운영정책 등을 준수하지 않을 경우, 회사는 회원의 관련
            행위 내용을 확인하고 서비스 관련 규정 준수에 대한 주의를 당부하거나 서비스 이용을
            중지시키는 등 제제할 수 있습니다. 더 나아가 이러한 제제에도 관련 행위를 지속할 경우
            부득이 회원과의 이용계약을 일방적으로 해지할 수 있습니다. 다만, 이 경우 회원이 서비스
            이용과 관련하여 내용을 숙지할 수 있도록 회사는 회원에게 공지사항, 전자우편 등을 통해
            충분히 안내합니다.
            <br />
            <br />
            회사는 365일 24시간 안정적으로 서비스를 제공하기 위해 최선을 다하고 있으며, 운영상
            상당한 이유(컴퓨터, 서버 등 정보통신 설비의 보수점검, 교체 또는 고장, 통신두절 등)가
            있는 경우 부득이 서비스의 전부 또는 일부를 중단할 수 있습니다.
            <br />
            <br />
            또한, 회사는 서비스 운영 또는 개선을 위해 서비스의 전부 또는 일부를 수정, 변경 또는
            중단하는 것이 필요한 경우 공지사항 및 전자우편 등을 통해 회원에게 사전에 내용을 전달하고
            안내합니다. 다만, 예측 불가능한 상황이 발생하면 동일한 방식으로 사후에 상세히 설명하고
            안내합니다. <br />
            <br />
            <span>개인정보 처리방침</span>
            <br />
            회사는 원활한 서비스 제공을 위하여 회원이 동의한 목적과 범위 내에서만 개인정보를 수집 및
            이용하며, 수집한 개인정보는 개인정보 보호 관련 법령에 따라 안전하게 관리합니다. 회사가
            회원 개인정보를 안전하게 처리하기 위하여 반영한 사항은 개인정보 처리방침 상세에서 확인할
            수 있습니다.
            <br />
            <br />
            <span>가입 시 주의사항</span>
            <br />
            회사는 회원에게 좋은 서비스를 편리하게 이용할 수 있도록 최선을 다하고 있습니다.
            <br />
            <br />
            회원 가입 시 이름, 이메일 등 필수정보를 허위로 기재하는 경우 부득이 하게 회원의 서비스
            이용을 제제할 수 있으므로 항상 정확한 최신 정보가 유지될 수 있도록 관리하시기 바랍니다.
            <br />
            <br />
            또한, 자신의 계정을 다른 사람에게 판매, 양도, 대여 또는 담보로 제공하거나 다른 사람에게
            그 사용을 허락하는 경우에도 회원의 서비스 이용이 제한될 수 있으니 이에 대해 확인하여
            준수하시기 바랍니다.
            <br />
            <br />
            <span>부칙</span>
            <br />
            공지일자: 2024년 02월 12일
            <br />
            적용일자: 2024년 02월 12일
          </div>
        </div>
        <label className="checkbox body2">
          <input
            name="termsOfService"
            type="checkbox"
            checked={agreed[0]}
            onChange={() => handleAgree(0)}
          />
          해당 이용약관에 동의합니다.
        </label>
      </div>

      {/* 개인정보 처리방침 */}
      <div className="consent_cont" style={{ marginBottom: '5px' }}>
        <p dangerouslySetInnerHTML={{ __html: privacyPolicy }}></p>
        <div className="scroll_wrap">
          <div className="scroll_box">
            <span>개인정보처리방침</span>
            <br />
            (주)레인보우브레인(이하 ‘회사’)는 서비스 기획부터 종료까지 개인정보보호법 등 국내의
            개인정보 보호 법령을 철저히 준수합니다.
            <br />
            <br />
            <span>1. 개인정보처리방침의 의의</span>
            <br />
            회사는 본 개인정보처리방침을 개인정보보호법을 기준을 작성하였으며, 모든 회원이 최대한
            이애하기 쉽도록 상세하게 설명하기 위해 노력하였습니다.​ 개인정보처리방침은 회사가
            수집하는 정보 항목 및 정보 활용 용도, 필요에 따른 공유(”위탁” 및 “제공”), 수집 정보 파기
            등 개인정보처리와 관련한 정보를 투명하게 공개합니다. 이는 개인정보와 관련하여 회사와
            회원 간 권리 및 의무 관계를 규정하여 회원의 ‘개인정보 자기결정권’을 보장 받습니다.
            <br />
            <br />
            <span>2. 개인정보 수집</span>
            <br />
            회원이 회사가 제공하는 서비스를 이용하려면 회원가입 후 로그인해야 하며, 이러한 서비스
            이용을 위해 회사는 아래와 같은 회원의 개인정보를 최소한으로 수집합니다.
            <table>
              <thead>
                <tr>
                  <th>수집시점​</th>
                  <th>수집항목​</th>
                  <th>수집목적​</th>
                  <th>보유기간​</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    회원가입 시 또는
                    <br />
                    회원정보 변경 시
                  </td>
                  <td>
                    [필수] 계정, 비밀번호, 성명, 이메일 주소
                    <br />
                    [선택] 소속, 휴대전화번호
                  </td>
                  <td>
                    &middot;&nbsp;회원 식별 및 가입의사 확인
                    <br />
                    &middot;&nbsp;본인 확인 부정이용 방지
                    <br />
                    &middot;&nbsp;회사의 현 서비스 및 예정 서비스 제공
                  </td>
                  <td>탈퇴시까지​</td>
                </tr>
              </tbody>
            </table>
            수집하는 최소한의 개인정보는 아래와 같이 구분합니다.
            <br />
            &middot;&nbsp;필수항목: 서비스의 기본 기능을 수행하기 위해 필요한 정보​
            <br />
            &middot;&nbsp;선택항목: 서비스 이용 자체에 영향을 미치지 않으나 회원에게 부가 가치를
            제공하기 위해 추가로 수집하는 정보​
            <br />
            <br />
            개인정보를 수집하는 경우, 원칙적으로 사전에 회원에게 해당 사실을 알리고 동의를 구하고
            있으며, 아래와 같은 방법을 통해 개인정보를 수집합니다.
            <br />
            &middot;&nbsp;회원가입 및 서비스 이용 과정에서 회원이 개인정보 수집에 대해 동의를 하고
            직접 정보를 입력하는 경우​
            <br />
            &middot;&nbsp;제휴 서비스 또는 단체 등으로부터 개인정보를 제공받은 경우​
            <br />
            &middot;&nbsp;고객지원을 통한 상담 과정에서 웹 페이지, 메일, 팩스 전화 등​
            <br />
            &middot;&nbsp;온/오프라인에서 진행되는 이벤트/행사 등 참여​
            <br />
            <br />
            서비스 이용 과정에서 회원으로부터 추가 개인정보를 자동으로 수집할 수 있으며, 수집하는
            개인정보는 아래와 같습니다.
            <br />
            &middot;&nbsp;PC웹 이용 과정에서 단말기 정보​
            <br />
            &middot;&nbsp;IP 주소​
            <br />
            &middot;&nbsp;쿠키
            <br />
            &middot;&nbsp;방문일시
            <br />
            &middot;&nbsp;부정이용기록
            <br />
            &middot;&nbsp;서비스 이용 기록 등​
            <br />
            <br />
            <span>3. 개인정보 이용</span>
            <br />
            수집한 개인정보는 회원관리, 서비스 제공 및 개선, 신규 서비스 개발 등을 위해 이용합니다.
            <br />
            &middot;&nbsp;회원 식별/가입의사 확인, 본인/연령 확인, 부정이용 방지​
            <br />
            &middot;&nbsp;신규 서비스 개발, 다양한 서비스 제공, 문의사항 또는 불만처리, 공지사항
            전달​
            <br />
            &middot;&nbsp;유료서비스 이용 시 콘텐츠 등의 전송이나 배송 및 요금 정산​
            <br />
            &middot;&nbsp;서비스의 원활한 운영에 지장을 주는 행뒤(계정 도용 및 부정 이용 행위 등
            포함)에 대한 방지 및 제재​
            <br />
            &middot;&nbsp;서비스 이영 기록, 접속 빈도 및 서비스 이용에 대한 통계, 프라이버시 보호
            측면의 서비스 환경 구축, 서비스 개선에 활용​
            <br />
            <br />
            <span>4. 개인정보 제공​</span>
            <br />
            회사는 회원의 별도 동의가 있거나 법령에 규정된 경우를 제외하고는 회원의 개인정보를
            제3자에게 제공하지 않습니다. 다만, 재난, 감염병, 급박한 생명 및 신체 위험을 초래하는
            사건사고, 급박한 재산 손실 등 긴급상황이 발생하면 정보주체의 동의 없이 관계기관에
            개인정보를 제공할 수 있습니다.
            <br />
            <br />
            <span>5. 개인정보 파기​</span>
            <br />
            수집 및 이용목적이 달성된 경우 수집한 개인정보는 지체없이 파기하며, 절차 및 방법은
            아래와 같습니다. 수집 및 이용목적의 달성 또는 회원 탈퇴 등 파기 사유가 발생하는 경우
            개인정보의 형태를 고려하여 파기 방법을 정합니다. 전자적 파일 형태인 경우 복구 및
            재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나
            소각하여 파기합니다.
            <br />
            <br />
            내부 방침에 따라 탈퇴일로부터 최대 1년간 보관 후 파기하는 정보는 아래와 같습니다.
            <br />
            &middot;&nbsp;안내메일 발송 및 CS문의 대응을 위해 계정과 이메일 주소를 암호화하여 보관
            <br />
            &middot;&nbsp;서비스 부정이용 기록​
            <br />
            <br />이 외에 법령에 따라 일정기간 보관해야 하는 개인정보는 아래 표와 같습니다.
            <table>
              <thead>
                <tr>
                  <th scope="col">보존항목​</th>
                  <th scope="col">근거법령​</th>
                  <th scope="col">보존기간​</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>계약 또는 청약철회 등에 관한 기록​</td>
                  <td>전자상거래 등에서의 소비자 보호에 관한 법률​</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>대금결제 및 재화 등의 공급에 관한 기록​</td>
                  <td>전자상거래 등에서의 소비자 보호에 관한 법률​</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>소비자 불만 또는 분쟁처리에 관한 기록​​</td>
                  <td>전자상거래 등에서의 소비자 보호에 관한 법률​</td>
                  <td>3년</td>
                </tr>
                <tr>
                  <td>표시/광고에 관한 기록​​</td>
                  <td>전자상거래 등에서의 소비자 보호에 관한 법률​</td>
                  <td>6개월</td>
                </tr>
                <tr>
                  <td>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류​​​</td>
                  <td>국세기본법​​</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>전자금융 거래에 관한 기록​​​</td>
                  <td>전자금융거래법​​</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>서비스 방문 기록​​​</td>
                  <td>통신비밀보호법​​</td>
                  <td>3개월</td>
                </tr>
                <tr>
                  <td>고객확인 및 의심되는 거래의 보고 등에 관한 기록​​​</td>
                  <td>특정 금융거래정보의 보고 및 이용 등에 관한 법률​​</td>
                  <td>서비스 탈퇴일로부터 5년</td>
                </tr>
              </tbody>
            </table>
            <br />
            <span>보유기간 및 파기​</span>
            <br />
            수집 및 이용 목적의 달성 또는 회원 탈퇴 등 파기사유가 발생한 개인정보는 안전하게
            파기합니다. 수집된 개인정보는 사전에 안내된 보유기간 동안만 보관 후 파기하며, 관계법령에
            따라 일정보관이 필요한 정보는 별도로 보관합니다.
            <br />
            <br />
            <span>6. 개인정보 안정성 및 보안 확보</span>
            <br />
            &middot;&nbsp;회원의 개인정보를 암호화하여 보관합니다.
            <br />
            &middot;&nbsp;해킹이나 컴퓨터 바이러스로부터 보호하기 위하여 노력합니다.
            <br />
            &middot;&nbsp;개인정보에 접근할 수 있는 사람을 최소화합니다.
            <br />
            &middot;&nbsp;임직원에게 회원의 개인정보 보호에 대해 정기적으로 교육을 실시합니다.
            <br />
            <br />
            <span>7. 개인정보 보호책임자 및 담당자​</span>
            <br />
            회사는 회원의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와 같이 개인정보
            보호책임자 및 담당자를 지정하고 있습니다.
            <table>
              <thead>
                <tr>
                  <th>항목</th>
                  <th>
                    개인정보
                    <br />
                    책임자​
                  </th>
                  <th>
                    개인정보
                    <br />
                    보호담당자
                  </th>
                </tr>
              </thead>
              <tbody className="bg_gray">
                <tr>
                  <td>이름</td>
                  <td>이형호​</td>
                  <td>김승현</td>
                </tr>
                <tr>
                  <td>소속​</td>
                  <td>플랫폼사업본부​</td>
                  <td>플랫폼사업본부​</td>
                </tr>
                <tr>
                  <td>직위​</td>
                  <td>이사</td>
                  <td>선임</td>
                </tr>
                <tr>
                  <td>메일</td>
                  <td>hhlee@rbrain.co.kr</td>
                  <td>shkim@rbrain.co.kr</td>
                </tr>
              </tbody>
            </table>
            기타 개인정보 침해에 대해 신고나 상담이 필요한 경우에 아래 기관으로 문의 가능합니다.
            <table>
              <tbody>
                <tr>
                  <th className="table_title">개인정보침해 신고센터</th>
                  <td>국번없이 118</td>
                  <td>privacy.kisa.or.kr</td>
                </tr>
                <tr>
                  <th className="table_title">대검찰청 사이버수사과​</th>
                  <td>국번없이 1301</td>
                  <td>www.spo.go.kr</td>
                </tr>
                <tr>
                  <th className="table_title">경찰청 사이버수사국​</th>
                  <td>국번없이 182</td>
                  <td>police.go.kr</td>
                </tr>
              </tbody>
            </table>
            <br />
            <span>8. 개정 전 고지의 의무​</span>
            <br />
            본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에
            ‘공지사항’을 통해 사전 공지를 할 것입니다.
            <br />
            다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 회원의 권리의 중대한 변경이
            발생할 때에는 최소 30일 전에 공지하며, 필요 시 회원의 동의를 다시 받을 수도 있습니다.
            <br />
            <br />
            공고일자: 2023년 06월 15일
            <br />
            시행일자: 2023년 06월 22일
          </div>
        </div>
        <label className="checkbox body2">
          <input
            name="privacyPolicy"
            type="checkbox"
            checked={agreed[2]}
            onChange={() => handleAgree(2)}
          />
          해당 개인정보 처리방침에 동의합니다.
        </label>
      </div>

      {/* 모든 약관 동의 */}
      <label className="all_agree checkbox body2">
        <input
          name="privacyPolicy"
          type="checkbox"
          checked={agreed.every(agreed => agreed)}
          onChange={handleAgreeAll}
        />
        모든 약관에 동의합니다.
      </label>
    </div>
  );
}

export default ConsentForm;
