import { useAuth } from '../../contexts/AuthProvider';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// api
import { settings_api } from '../../apis/settings_api';
import { market_update } from '../../apis/tsb_setting_api';

const MarketSetting = forwardRef((props, ref) => {
  const { user } = useAuth();
  const { market_keywords, id } = user.result;
  const sanitizedMarketKeywords = market_keywords || '';
  const [exchangeRes, setExchangeRes] = useState([]);
  const [worldExchangeRes, setWorldExchangeRes] = useState([]);
  const [oilgoldRes, setOilgoldRes] = useState([]);
  const [materialRes, setMaterialRes] = useState([]);

  // console.log(user);

  const [selectedButtons, setSelectedButtons] = useState(sanitizedMarketKeywords);
  let updatedButtons;
  // console.log(selectedButtons);

  useEffect(() => {
    const fetchData = async () => {
      const result = await settings_api();
      const exchangeItems = result.result.filter(item => item.category === 'exchange');
      setExchangeRes(exchangeItems);
      const worldExchangeItems = result.result.filter(item => item.category === 'worldExchange');
      setWorldExchangeRes(worldExchangeItems);
      const oilgoldItem = result.result.filter(item => item.category === 'oilgold');
      setOilgoldRes(oilgoldItem);
      const materialItem = result.result.filter(item => item.category === 'material');
      setMaterialRes(materialItem);

      const resultKey = await market_update({ id: id });
      const rendingArray = resultKey.result.market_keywords.split(',');
      setSelectedButtons(rendingArray);
    };

    fetchData();
  }, [id]);

  const handleButtonClick = async (event, category) => {
    if (event.target) {
      const selectedButton = event.target.innerText;
      setSelectedButtons(prevState => {
        if (prevState.includes(selectedButton)) {
          // 버튼이 이미 선택된 상태인 경우, 해당 버튼을 배열에서 제거
          updatedButtons = prevState.filter(button => button !== selectedButton);

          marketDelete(id, selectedButton);
          toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
        } else {
          // 버튼이 선택되지 않은 상태인 경우, 배열에 추가
          updatedButtons = [...prevState, selectedButton];

          marketInsert(id, selectedButton);
          toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
        }
        // console.log('선택된버튼 :: ', updatedButtons);
        return updatedButtons;
      });
    }
  };

  const marketInsert = async (id, selectedButton) => {
    try {
      const options = {
        id: id,
        keywordValue: selectedButton,
      };
      const response = await market_update(options);
      console.log('Update successful:', response.result.market_keywords);
      const InsertArray = response.result.market_keywords.split(',');
      setSelectedButtons(InsertArray);
      return response;
    } catch (error) {
      console.error('Update failed:', error);
      throw error;
    }
  };

  const marketDelete = async (id, selectedButton) => {
    try {
      const options = {
        id: id,
        keywordValue: selectedButton,
        removedKeyword: selectedButton,
      };
      const response = await market_update(options);
      console.log('Delete successful:', response.result.market_keywords);
      const deleteArray = response.result.market_keywords.split(',');
      setSelectedButtons(deleteArray);
      return response;
    } catch (error) {
      console.error('Delete failed:', error);
      throw error;
    }
  };

  return (
    <Container
      className="setting_box"
      ref={marketRef => (ref.current[5] = marketRef)}
      style={{ paddingBottom: '45px' }}
    >
      <Text className="heading1">금융 지수 설정</Text>
      <Title className="body1">선택한 항목에 대한 정보만 Board화면에 나타납니다.</Title>

      <div className="keyword_box">
        <SubTitle className="label1">환전고시</SubTitle>
        <div className="button-list">
          {Array.isArray(exchangeRes) && exchangeRes.length > 0 ? (
            exchangeRes.map(button => (
              <button
                className="btn_item"
                key={button.id}
                onClick={event => handleButtonClick(event, 'exchange')}
                style={{
                  backgroundColor: `${
                    selectedButtons.includes(button.item) ? '#0D1A43' : '#dbdbdb'
                  }`,
                  color: `${selectedButtons.includes(button.item) ? '#fff' : '#333'}`,
                }}
              >
                {button.item}
              </button>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>

      <div className="keyword_box">
        <SubTitle className="label1">국제 시장 환율</SubTitle>
        <div className="button-list">
          {Array.isArray(worldExchangeRes) && worldExchangeRes.length > 0 ? (
            worldExchangeRes.map(button => (
              <button
                className="btn_item"
                key={button.id}
                onClick={event => handleButtonClick(event, 'worldExchange')}
                style={{
                  backgroundColor: `${
                    selectedButtons.includes(button.item) ? '#0D1A43' : '#dbdbdb'
                  }`,
                  color: `${selectedButtons.includes(button.item) ? '#fff' : '#333'}`,
                }}
              >
                {button.item}
              </button>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>

      <div className="keyword_box">
        <SubTitle className="label1">유가 금 시세</SubTitle>
        <div className="button-list">
          {Array.isArray(oilgoldRes) && oilgoldRes.length > 0 ? (
            oilgoldRes.map(button => (
              <button
                className="btn_item"
                key={button.id}
                onClick={event => handleButtonClick(event, 'oilgold')}
                style={{
                  backgroundColor: `${
                    selectedButtons.includes(button.item) ? '#0D1A43' : '#dbdbdb'
                  }`,
                  color: `${selectedButtons.includes(button.item) ? '#fff' : '#333'}`,
                }}
              >
                {button.item}
              </button>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>

      <div className="keyword_box">
        <SubTitle className="label1">원자재</SubTitle>
        <div className="button-list">
          {Array.isArray(materialRes) && materialRes.length > 0 ? (
            materialRes.map(button => (
              <button
                className="btn_item"
                key={button.id}
                onClick={event => handleButtonClick(event, 'material')}
                style={{
                  backgroundColor: `${
                    selectedButtons.includes(button.item) ? '#0D1A43' : '#dbdbdb'
                  }`,
                  color: `${selectedButtons.includes(button.item) ? '#fff' : '#333'}`,
                }}
              >
                {button.item}
              </button>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </Container>
  );
});

export default MarketSetting;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;
const Title = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const SubTitle = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;