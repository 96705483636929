import { Helmet } from 'react-helmet';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

// component
import SideNav from '../../components/settingPage/SideNav';
import NewsSetting from '../../components/settingPage/NewsSetting';
import MarketSetting from '../../components/settingPage/MarketSetting';
import StockSetting from '../../components/settingPage/StockSetting';
import LawSetting from '../../components/settingPage/LawSetting';
import PatentSetting from '../../components/settingPage/PatentSetting';
import InvestSetting from '../../components/settingPage/InvestSetting';
import EventSetting from '../../components/settingPage/EventSetting';
import UserSetting from '../../components/settingPage/UserSetting';
import PasswordSetting from '../../components/settingPage/PasswordSetting';
import CompanySetting from '../../components/settingPage/CompanySetting';
import OnoffSetting from '../../components/settingPage/OnoffSetting';
import HomeBtn from '../../components/common/HomeBtn';

import { useAuth } from '../../contexts/AuthProvider';
import { latest_keywords_api } from '../../apis/latest_keywords_api';

// css
import '../../styles/Setting.css';

function SettingPage() {
  const { user } = useAuth();
  const { user_email } = user.result;

  const [newsDomestic, setNewsDomestic] = useState('');
  const [newsOverseas, setNewsOverseas] = useState('');
  const [lawsDomestic, setLawsDomestic] = useState('');
  const [lawsOverseas, setLawsOverseas] = useState('');
  const [patentsDomestic, setPatentsDomestic] = useState('');
  const [patentsOverseas, setPatentsOverseas] = useState('');
  const [event, setEvent] = useState('');
  const [except, setExcept] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const latest_setting_keyword = await latest_keywords_api({
          user_email: user_email,
        });
        const newsdomestic = await latest_setting_keyword.filter(
          keyword => keyword.division === 'news_domestic',
        );
        const newsoverseas = await latest_setting_keyword.filter(
          keyword => keyword.division === 'news_overseas',
        );
        const lawsdomestic = await latest_setting_keyword.filter(
          keyword => keyword.division === 'laws_domestic',
        );
        const lawsoverseas = await latest_setting_keyword.filter(
          keyword => keyword.division === 'laws_overseas',
        );
        const patentsdomestic = await latest_setting_keyword.filter(
          keyword => keyword.division === 'patents_domestic',
        );
        const patentsoverseas = await latest_setting_keyword.filter(
          keyword => keyword.division === 'patents_overseas',
        );
        const event = await latest_setting_keyword.filter(keyword => keyword.division === 'event');

        const except = await latest_setting_keyword.filter(
          keyword => keyword.division === 'except',
        );

        setNewsDomestic(newsdomestic);
        setNewsOverseas(newsoverseas);
        setLawsDomestic(lawsdomestic);
        setLawsOverseas(lawsoverseas);
        setPatentsDomestic(patentsdomestic);
        setPatentsOverseas(patentsoverseas);
        setEvent(event);
        setExcept(except);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchData();
  }, [user_email]);

  const scrollRef = useRef([]);

  const DETAIL_NAV = [
    { idx: 0, name: '개인 정보 설정', option: 'user' },
    { idx: 1, name: '비밀 번호 변경', option: 'pwd' },
    { idx: 2, name: '회사 정보 설정', option: 'tsb' },
    { idx: 3, name: '노출 영역 설정', option: 'tsb' },
    { idx: 4, name: '뉴스 검색 설정', option: 'tsb' },
    { idx: 5, name: '금융 지수 설정', option: 'tsb' },
    { idx: 6, name: '주식 종목 설정', option: 'tsb' },
    { idx: 7, name: '법규 검색 설정', option: 'tsb' },
    { idx: 8, name: '특허 검색 설정', option: 'tsb' },
    { idx: 9, name: '투자 정보 설정', option: 'tsb' },
    { idx: 10, name: '행사 일정 설정', option: 'tsb' },
  ];

  // DETAIL_NAV에서 idx가 0인 객체의 option 값을 가져오기
  const defaultOption = DETAIL_NAV.find(item => item.idx === 0)?.option;

  // defaultOption이 없으면 기본값 'user' 사용
  const [screenType, setScreenType] = useState(defaultOption);

  // 동적으로 선택할 컴포넌트를 정의합니다.
  const componentMap = {
    user: (
      <>
        <UserSetting ref={scrollRef} />
      </>
    ),
    pwd: (
      <>
        <PasswordSetting ref={scrollRef} />
      </>
    ),
    tsb: (
      <>
        <CompanySetting ref={scrollRef} />
        <OnoffSetting ref={scrollRef} />
        <NewsSetting
          ref={scrollRef}
          newsDomestic={newsDomestic}
          newsOverseas={newsOverseas}
          newsExcept={except}
        />
        <MarketSetting ref={scrollRef} />
        <StockSetting ref={scrollRef} />
        <LawSetting ref={scrollRef} lawsDomestic={lawsDomestic} lawsOverseas={lawsOverseas} />
        <PatentSetting
          ref={scrollRef}
          patentsDomestic={patentsDomestic}
          patentsOverseas={patentsOverseas}
        />
        <InvestSetting ref={scrollRef} />
        <EventSetting ref={scrollRef} event={event} />
        <ToastContainer autoClose={1500} />
      </>
    ),
  };

  const handleNavClick = option => {
    setScreenType(option);
  };

  return (
    <>
      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>Rainbowbrain Trend Sensing Board</title>
      </Helmet>

      <Container className="setting_page_wrap">
        <SideNav
          scrollRef={scrollRef}
          DETAIL_NAV={user.role === '사용자' ? [DETAIL_NAV[0], DETAIL_NAV[1]] : DETAIL_NAV}
          onNavClick={handleNavClick}
        />
        <div className="setting_container">
          {user.role === '사용자' ? componentMap[screenType] : componentMap[screenType]}
        </div>
        <HomeBtn />
      </Container>
    </>
  );
}

export default SettingPage;

const Container = styled.div`
  border-right: 1px solid ${props => props.theme.colors.border};
  border-top: 1px solid ${props => props.theme.colors.border};
`;
