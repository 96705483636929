import React, { useState } from 'react';

export default function PlusBtn() {
  const [zoomLevel, setZoomLevel] = useState(0);
  const browser = navigator.userAgent.toLowerCase();

  const zoomIn = () => {
    if (zoomLevel < 2) {
      const newZoomLevel = zoomLevel + 1;
      setZoomLevel(newZoomLevel);
      zoom(newZoomLevel);
    }
  };

  const zoomOut = () => {
    if (zoomLevel > 0) {
      const newZoomLevel = zoomLevel - 1;
      setZoomLevel(newZoomLevel);
      zoom(newZoomLevel);
    }
  };

  const zoom = level => {
    let seemSize = 1 + 0.025 * level;
    let zoomSize = Math.pow(1.1, level);
    const zoomContainer = document.querySelector('.zoom-container');

    if (browser.indexOf('firefox') !== -1) {
      zoomContainer.style.webkitTransform = `scale(${seemSize})`;
      zoomContainer.style.webkitTransformOrigin = '50% 0 0';
      zoomContainer.style.msTransform = `scale(${seemSize})`;
      zoomContainer.style.msTransformOrigin = '50% 0 0';
      zoomContainer.style.transform = `scale(${seemSize})`;
      zoomContainer.style.transformOrigin = '50% 0 0';
      zoomContainer.style.OTransform = `scale(${seemSize})`;
      zoomContainer.style.OTransformOrigin = '50% 0 0';
    } else {
      zoomContainer.style.zoom = zoomSize;
    }
  };

  return (
    <div>
      <div className="area-zoom-btn">
        <div className="zoom-btn">
          <button className="zoom" onClick={zoomIn}>
            +
          </button>
          <button className="zoom zoomout" onClick={zoomOut}>
            -
          </button>
        </div>
      </div>
    </div>
  );
}
