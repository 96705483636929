//시용
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import SignUpForm from '../../components/signupPage/SignUpForm.js';
import Footer from '../../components/common/Footer';

// css
import '../../styles/SignupPage.css';

/* ─────────────────────────────────────────────────────
 * SignUpPage:  회원가입 페이지 컴포넌트
 * ─────────────────────────────────────────────────────
 */
const SignUpPage = () => {
  const handleContactButtonClick = () => {
    scroll.scrollTo(0, {
      //스크롤값
      duration: 100,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 0,
      top: 0,
    });
  };

  return (
    <div className="signup_wrap">
      <Helmet>
        <title>Rainbowbrain Trend Sensing Board Brain</title>
      </Helmet>

      <div className="intro_login nav_container">
        <div className="nav">
          <Link to="/" onClick={handleContactButtonClick}>
            <h1 className="title2">Trend Sensing Board</h1>
          </Link>
        </div>
      </div>

      <h1 className="title1">회원가입</h1>

      <div className="signup_container">
        <h1 className="heading1">개인정보 입력</h1>
        <SignUpForm />
      </div>

      <Footer />
    </div>
  );
};

export default SignUpPage;
