import React, { forwardRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Toggle } from '../common/Toggle';
import { useAuth } from '../../contexts/AuthProvider';
import { update_screen_onoff_api } from '../../apis/tsb_setting_api';
import { toast } from 'react-toastify';

const OnoffSetting = forwardRef((props, ref) => {
  const { user } = useAuth();
  const { id } = user.result;

  // let user_screen_onoff = user.result.screen_onoff; //0,1,2 문자로넘어옴
  // console.log('1:: ', user_screen_onoff);

  // if (!user_screen_onoff) {
  //   user_screen_onoff = '';
  // }

  const [get_onoff_arry, setGetOnOffArray] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // API를 호출하여 최신 데이터를 가져옵니다.
        const result = await update_screen_onoff_api(id);
        // console.log('fersutl: ', result.result);
        const userScreenOnOff = result.result.screen_onoff;
        // 가져온 데이터를 처리하여 배열로 변환합니다.
        const ids = userScreenOnOff ? userScreenOnOff.split(',').map(item => item.trim()) : [];
        // 상태를 업데이트합니다.
        setGetOnOffArray(ids);
      } catch (error) {
        console.error('Error fetching screen on/off data:', error);
      }
    };

    // 페이지가 로딩될 때 최신 데이터를 가져오도록 fetchData 함수를 호출합니다.
    fetchData();
  }, [id]);

  const inputLabels = [
    '업계 동향 및 경쟁 정보',
    '금융 및 시장 현황',
    '특허 정보',
    '규제 정보',
    '법규 정보',
    '투자 정보',
    '기업 보고서',
    '소셜 트렌드 현황',
    '행사 일정',
    '테크미미(Techmeme)',
    // 'ulex 판례',
    // 'ulex 법률검색',
    // '오늘의 추천법률Q&A',
    // 'ules 법률Q&A',
  ];

  const toggleHandler = async index => {
    let updatedArray;
    if (get_onoff_arry[0] === '') {
      console.log('array0일때');
      updatedArray = [String(index)];
      // console.log('up::', updatedArray);
      toast.success('설정 정보가 저장되었습니다.');
      await update_screen_onoff_api(id, updatedArray);
      // window.location.reload();
      setGetOnOffArray(updatedArray);
    } else {
      if (get_onoff_arry.includes(String(index))) {
        console.log('array1개이상일때 포함하면 제거');
        updatedArray = get_onoff_arry.filter(item => item !== String(index));
        // console.log('up::', updatedArray);
        toast.success('설정 정보가 저장되었습니다.');
        await update_screen_onoff_api(id, updatedArray);
        // window.location.reload();
        setGetOnOffArray(updatedArray);
      } else {
        console.log('array1개이상일때 없으면 추가');
        updatedArray = [...get_onoff_arry, String(index)];
        // console.log('up::', updatedArray);
        toast.success('설정 정보가 저장되었습니다.');
        await update_screen_onoff_api(id, updatedArray);
        // window.location.reload();
        setGetOnOffArray(updatedArray);
      }
    }
  };

  // const ids = get_onoff_arry;
  // useEffect(() => {
  //   update_screen_onoff_api(id, ids)
  //     .then(result => {
  //       const resultOnoff = result.result.screen_onoff;
  //       const ids = resultOnoff ? resultOnoff.split(',').map(item => item.trim()) : [''];
  //       setGetOnOffArray(ids);
  //     })
  //     .catch(error => {
  //       console.error('Error updating screen index:', error);
  //     });
  // }, [id]);

  return (
    <>
      <Container className="toggle_wrap" ref={onoffRef => (ref.current[3] = onoffRef)}>
        <Text>노출 영역 설정</Text>
        <SubText className="body1">
          BOARD 화면에 노출할 영역을 설정합니다. (설정 시 바로 적용됩니다.)
        </SubText>
        <div className="toggle-title d-flex">
          <Title className="label1">영역</Title>
          <Title className="label1">on/off</Title>
        </div>
        {inputLabels.map((label, index) => (
          <div key={index} className="d-flex">
            <SubTitle className="body1">{label}</SubTitle>
            <Toggle
              index={index}
              // on={!get_onoff_arry[index]} // get_onoff_arry에서 해당 인덱스가 false이면 토글이 켜진 상태로 설정
              onClick={() => toggleHandler(index)}
              array={get_onoff_arry}
            />
          </div>
        ))}
      </Container>
    </>
  );
});

export default OnoffSetting;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border-bottom: 1px solid ${props => props.theme.colors.border};
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;

const SubText = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const Title = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const SubTitle = styled.label`
  color: ${props => props.theme.colors.subTitleColor};
`;
