// basic
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

//components
import Slider from 'react-slick';

/* ───────────────────────────────────────────────────────────────
 * InvestsContent : 투자 화면  테이블 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const InvestsContent = ({ data }) => {
  const [selectedButton, setSelectedButton] = useState('전체');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // 초기에 data를 받아올 때, 전체 데이터로 filteredData 설정
    setFilteredData(data);
  }, [data]);

  const uniqueDivision = [...new Set(data.map(item => item.division))];
  const keywordBtnLabel = [
    { id: 0, name: '전체' },
    ...uniqueDivision.map((division, index) => ({
      id: index + 1,
      name: division,
    })),
  ];
  // console.log('중복제거된 :: ', keywordBtnLabel);

  const handleKeywordBtn = event => {
    const selectedDivision = event.target.innerText;

    // division이 선택된 버튼의 이름과 일치하는 데이터만 필터링
    const filtering =
      selectedDivision === '전체' ? data : data.filter(item => item.division === selectedDivision);

    // 필터링된 데이터를 사용하여 원하는 작업 수행
    setFilteredData(filtering);

    // 선택된 버튼 업데이트
    setSelectedButton(selectedDivision);
  };

  // console.log('필터드데이트 ::::: ', filteredData);

  // 데이터가 널일 때 처리
  if (!filteredData || filteredData.length === 0) {
    // console.log('데이터가 없습니다.');
    return null; // 혹은 다른 처리 방법을 선택할 수 있습니다.
  }

  // service 값을 기준으로 객체를 그룹화하여 중복 제거
  const uniqueServicesMap = filteredData.reduce((accumulator, currentValue) => {
    accumulator[currentValue.service] = currentValue;
    return accumulator;
  }, {});

  // 중복이 제거된 객체들을 배열로 변환
  const uniqueServices = Object.values(uniqueServicesMap);

  // console.log(uniqueServices);

  //슬라이드
  const sliderSettings = {
    dots: false,
    infinite: true,
    // arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
  };

  return (
    <div className="invests_slider">
      <div className="slider-container">
        <Slider {...sliderSettings}>
          {keywordBtnLabel.map(button => {
            return (
              <button
                key={button.id}
                onClick={handleKeywordBtn}
                className={`slide_btn label2 ${selectedButton === button.name ? 'selected' : ''}`}
              >
                {button.name}
              </button>
            );
          })}
        </Slider>
      </div>
      <div className="table">
        {uniqueServices && uniqueServices.length > 0 ? ( // data가 존재하고 비어있지 않을 때 테이블을 렌더링합니다.
          <table>
            <Thead className="label3">
              <tr key={filteredData.id}>
                <th style={{ paddingLeft: '20px' }}>분류</th>
                <th>기업명</th>
                <th>기업설명</th>
                <th>서비스</th>
                <th>일자</th>
                <th>단계</th>
                <th>금액</th>
              </tr>
            </Thead>
            <tbody className="body2 scroll">
              {uniqueServices.map(item => (
                <Border key={item.id}>
                  <Title style={{ paddingLeft: '20px' }}>{item.division}</Title>
                  <Title>{item.company}</Title>
                  <Title>{item.company_description}</Title>
                  <Title>{item.service}</Title>
                  <Title>{item.last_invest_date}</Title>
                  <Title>{item.investment_stage}</Title>
                  <Title>{item.investment_amount}</Title>
                </Border>
              ))}
            </tbody>
          </table>
        ) : (
          <Text className="body2">현재 데이터 수집 중입니다.</Text> // 데이터가 없는 경우 해당 문구를 표시합니다.
        )}
      </div>
    </div>
  );
};

export default InvestsContent;
const Thead = styled.thead`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border-top: 1px solid ${props => props.theme.colors.border};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Border = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Title = styled.td`
  color: ${props => props.theme.colors.subTitleColor};
`;
