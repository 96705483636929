import React, { useMemo, useState, forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import { passwordValid } from '../../utils/passwordValid';
import { useAuth } from '../../contexts/AuthProvider';
import { password_confirm } from '../../apis/password_change_api';
import { updateUserInfo, userSelectSub } from '../../apis/users_api.js';
import { Link } from 'react-router-dom';

// component
import SettingPopup from './Popup';

const PasswordSetting = forwardRef((props, ref) => {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState({});
  const { id, user_email } = userInfo[0] ? userInfo[0] : user.result;
  // console.log('-----------------', id, user_email, user.role, user.login_user);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const [userChangeCheck, setUserChangeCheck] = useState(false);
  const [userError, setUserError] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const checkOption = useMemo(() => [0, 1, 2, 3, 4], []);

  // 비밀번호
  const [userPwMatched, setUserPwMatched] = useState(checkOption[2]); //비밀번호 일치 여부
  const [userPwValidState, setUserPwValidState] = useState(checkOption[2]); // 비밀번호 유효성 상태
  const passwordMessages = {
    0: '사용할 수 없는 비밀번호입니다. 규칙에 맞게 다시 입력해주시기 바랍니다.',
    1: '사용 가능한 비밀번호입니다.',
    2: '영문자, 숫자, 특수기호를 포함하여 최소 8글자를 입력 해 주세요.',
  };
  const confirmPasswordMessages = {
    0: '비밀번호와 일치하지 않습니다. 다시 입력해주시기 바랍니다.',
    1: '비밀번호를 확인하였습니다.',
    2: '',
  };

  const handlePasswordChange = e => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = e => {
    const { name } = e.target;
    const newPassoword = e.target.value;

    if (name === 'newPassoword') {
      setNewPassword(e.target.value);

      // 입력된 비밀번호 유효성 체크
      setUserPwValidState(passwordValid(newPassoword) ? checkOption[1] : checkOption[0]);
      if (newPassoword === '') {
        setUserPwValidState(checkOption[2]);
      }
    }
  };

  const handleNewPasswordConfirmChange = e => {
    const { name } = e.target;
    if (name === 'newPasswordConfirm') {
      setNewPasswordConfirm(e.target.value);
    }
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    const checkPassOptions = {
      user_role: user.role,
      user_email: user.login_user || user_email,
      user_pw: currentPassword,
    };
    const result = await password_confirm(checkPassOptions);
    console.log(result);
    if (result.message !== '0000') {
      setCurrentPassword(''); // 인풋 비우기
      setErrorMessage('현재 비밀번호가 일치하지 않습니다.');
      return;
    }

    if (!passwordValid(newPassword)) {
      setNewPassword('');
      setNewPasswordConfirm('');
      setErrorMessage(
        '새 비밀번호는 최소 8자 이상, 영문 대소문자, 숫자, 특수문자를 각각 1개 이상 포함해야 합니다.',
      );
      return;
    }

    if (newPassword !== newPasswordConfirm) {
      setNewPassword('');
      setNewPasswordConfirm('');
      setErrorMessage('새 비밀번호 확인이 일치하지 않습니다.');
      return;
    }

    try {
      const updateOptions = {
        user_role: user.role || user_email,
        user_pw: newPassword,
      };
      await updateUserInfo(id, updateOptions);
      setUserChangeCheck(true);
    } catch (error) {
      console.error('사용자 정보 업데이트 오류:', error);
      setUserError(true);
    }
  };

  useEffect(() => {
    // console.log(user.role);
    if (user.role === '사용자') {
      const options = { input_name: 'email', user_email: user.login_user };
      const fetchData = async () => {
        try {
          const userData = await userSelectSub(options);
          setUserInfo(userData);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchData();
    } else {
      setUserInfo(user.result);
    }

    if (newPassword === newPasswordConfirm && newPassword !== '') {
      setUserPwMatched(checkOption[1]);
    } else if (newPassword !== newPasswordConfirm) {
      setUserPwMatched(checkOption[0]);
      if (newPasswordConfirm === '') {
        setUserPwMatched(checkOption[2]);
      }
    }
  }, [user.role, user.result, user.login_user, newPasswordConfirm, newPassword, checkOption]);

  const handleClick = () => {
    // 페이지 맨 위로 스크롤
    window.scrollTo(0, 0);
  };

  const closePopup = () => {
    setUserChangeCheck(false);
    setUserError(false);
    setErrorMessage('');
  };

  return (
    <Container className="setting_box information_wrap" ref={newsRef => (ref.current[1] = newsRef)}>
      <div>
        <Text className="heading1">비밀번호 변경</Text>
        <Title className="body1" style={{ marginBottom: '50px' }}>
          비밀번호를 변경할 수 있습니다.
        </Title>
        <div>
          <form onSubmit={handleFormSubmit}>
            <div className="keyword_box">
              <label>
                <SubTitle className="label1">현재 비밀번호</SubTitle>
                <Input
                  type="password"
                  name="password"
                  placeholder="현재 비밀번호를 입력 해 주세요."
                  value={currentPassword}
                  onChange={handlePasswordChange}
                  // onBlur={handlePasswordBlur}
                />
              </label>
            </div>
            <div className="keyword_box">
              <label>
                <SubTitle className="label1">새 비밀번호</SubTitle>
                <Input
                  type="password"
                  name="newPassoword"
                  placeholder="새 비밀번호를 입력 해 주세요."
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  // onBlur={handleNewPasswordBlur}
                  // ref={passwordInputRef}
                />
              </label>
              <span className="error_text body3" style={{ display: 'block', marginTop: '5px' }}>
                {passwordMessages[userPwValidState]}
              </span>
            </div>
            <div className="keyword_box">
              <label>
                <SubTitle className="label1">새 비밀번호 확인</SubTitle>
                <Input
                  type="password"
                  name="newPasswordConfirm"
                  placeholder="새 비밀번호를 확인 해 주세요."
                  value={newPasswordConfirm}
                  onChange={handleNewPasswordConfirmChange}
                  // onBlur={handleNewPasswordConfirmBlur}
                />
              </label>
              <span className="error_text body3" style={{ display: 'block', marginTop: '5px' }}>
                {confirmPasswordMessages[userPwMatched]}
              </span>
            </div>
            <div className="d-end">
              <Btn className="setting_btn label1" type="submit">
                비밀번호 변경
              </Btn>
            </div>
            {errorMessage && <SettingPopup close={closePopup} header={errorMessage} />}
            <Link to="/" onClick={handleClick}>
              {userChangeCheck && (
                <SettingPopup close={closePopup} header="비밀번호가 변경되었습니다." />
              )}
            </Link>
            {userError && (
              <SettingPopup close={closePopup} header="비밀번호 업데이트 중 오류가 발생했습니다." />
            )}
          </form>
        </div>
      </div>
    </Container>
  );
});

export default PasswordSetting;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;
const Title = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const SubTitle = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Btn = styled.button`
  background: ${props => props.theme.colors.btnBg};
  color: ${props => props.theme.colors.bgColor};
`;

const Input = styled.input`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border: 1px solid ${props => props.theme.colors.navTitleColor};
`;
