// -- basic -- //
import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * reports_api
 *
 * PAX Net 국내 기업 리포트 데이터 (기업 리포트) // 국내
 * https://www.paxnet.co.kr/stock/report/report?wlog_rpt=jm&menuCode=2222
 *
 * 미래에셋 해외 기업 리포트 데이터 (기업 리포트) // 해외
 * https://securities.miraeasset.com/bbs/board/message/list.do?categoryId=1544
 * ─────────────────────────────────────────────────────────────
 */

// 국내 기업 리포트 api
export async function reports_api(options) {
  let query = null;

  if (options.division) {
    query = `division=${options.division}&post_date=${options.post_date}`;
  }

  try {
    const response = await fetch(`${BASE_URL}/crop_reports/?${query}`);
    const data = await response.json();
    // console.log('api결과 :: ', data);
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}
