// basic
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { animateScroll as scroll } from 'react-scroll';

// api
import GoogleKeyword from './GoogleKeyword';
// import NaverKeyword from './NaverKeyword';
import GoogleRelatedKeywrod from './GoogleRelatedKeyword.js';
import NaverTrend from './NaverTrend';
import { google_keywords_api } from '../../../apis/google_keywords_api.js';

// components
import Tooltip from '../../common/Tooltip';

// css
import '../../../styles/Reset.css';
import '../../../styles/Common.css';
import '../../../styles/SocialTrend.css';

//img
import plus from '../../../assets/plus.png';
import naver from '../../../assets/naver_logo.png';
import google from '../../../assets/google.png';

/* ───────────────────────────────────────────────────────────────
 * SocialTrend
 * - 구글 네이버 키워드 보여주는 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

export default function SocialTrend(editBtn) {
  const screen_edit = editBtn.editBtn;

  const tooltipValue =
    '<p>오늘의 인기 검색어에 따른 연관 검색어 및 검색량 변화를 보여줍니다.</p><br /><span class="primary">[검색량 추이]</span><br />- 최근 일주일 동안 해당 키워드가 언급된 추이를 보여줍니다.<br />- 키워드 언급량은 상대 지표로 최대값이 100입니다.';
  const [param, setParam] = useState({});
  const [changeIndex, setChangeIndex] = useState(0);

  const fetchData = useCallback(async () => {
    const result = await google_keywords_api();
    const item = result.rss.channel[0];

    setParam(item);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDataFromChild = index => {
    // console.log('Data from child:', data);
    // console.log('Data from child:', index);
    setChangeIndex(index);
  };

  const handleContactButtonClick = () => {
    scroll.scrollTo(5325, {
      //스크롤값
      duration: 100,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 0,
      top: 0,
    });
  };

  return (
    <Container className="cont_wrap w980">
      <ContTitle className="cont_title d-between">
        {/* screen_edit 변수가 true 일때만 가려지는 css 적용 */}
        {screen_edit && <div className="arrange_wrap"></div>}

        <div className="d-flex">
          {/* 타이틀 */}
          <Text className="title3">소셜 트렌드 현황</Text>

          {/* 툴팁 */}
          <div className="tooltip_one">
            <Tooltip data={tooltipValue} />
          </div>
        </div>

        {/* 더보기 버튼 */}
        <Link to="/detail">
          <PlusBtn className="plus_btn label4" onClick={handleContactButtonClick}>
            더보기 <IconImg src={plus} alt="아이콘 이미지" />
          </PlusBtn>
        </Link>
      </ContTitle>

      <ChartBg className="d-flex" style={{ alignItems: 'flex-start' }}>
        <GoogleKeyword data={param} onDataFromChild={handleDataFromChild} />
        <Border className="naverkeyword_wrap">
          <div className="logo google_logo">
            <img src={google} alt="아이콘 이미지" />
            <Text className="label3">연관 검색어</Text>
          </div>
          <GoogleRelatedKeywrod data={{ param, changeIndex }} />
          {/* <NaverKeyword data={{ param, changeIndex }} /> */}
          {/* {loging ? <GoogleRelatedKeywrod data={{ param, changeIndex }} /> : <div>로딩중</div>} */}
        </Border>
        <div className="navertrend_wrap">
          <div className="logo naver_logo">
            <img src={naver} alt="아이콘 이미지" />
            <Text className="label3">검색량 추이</Text>
          </div>
          <NaverTrend data={{ param, changeIndex }} />
        </div>
      </ChartBg>
    </Container>
  );
}

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Border = styled.div`
  border-left: 1px solid ${props => props.theme.colors.chartBorder};
  border-right: 1px solid ${props => props.theme.colors.chartBorder};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const PlusBtn = styled.button`
  background: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.subTitleColor};
`;

const IconImg = styled.img`
  filter: ${props => props.theme.imgFilter};
`;

const ChartBg = styled.div`
  background: ${props => props.theme.colors.chartBg};
`;
