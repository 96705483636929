// -- basic -- //
import { BASE_URL } from '../config/base_url';

// common
import { formatDateToYYYYmmdd } from '../utils/dateFunction';

/* ─────────────────────────────────────────────────────────────
 * laws_api
 * 관보 법규 데이터 (법규 정보) // 국내
 * https://gwanbo.go.kr/user/search/searchDaily.do
 *
 * 세계법제정보 법규 데이터 (법규 정보) // 해외
 * https://world.moleg.go.kr/web/wli/lgslInfoListPage.do?A=A&searchType=all&searchPageRowCnt=10&searchTy=2&selectLgslCls=[object%20HTMLInputElement]&pageIndex=1
 * ─────────────────────────────────────────────────────────────
 */

//-- 국내 법규 데이터 조회 api -- //
export async function laws_api(options) {
  const today = formatDateToYYYYmmdd(new Date());
  let query = null;

  // 구분
  if (options.division) {
    query = `division=${options.division}`;
    if (options.division === '국내') {
      query += `&post_date=${today}`;
    } else {
      query += `&post_date=${today}`;
    }
  }

  // 키워드
  if (options.keyword) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&keyword=${options.keyword}` : `keyword=${options.keyword}`;
  }

  // console.log(query);
  try {
    const response = await fetch(`${BASE_URL}/laws/?${query}`);
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}
