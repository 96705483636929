import styled from 'styled-components';
// css
import '../../styles/Reset.css';
import '../../styles/Common.css';

// img
import tooltip from '../../assets/tooltip.png';

/* ───────────────────────────────────────────────────────────────
 * Tooltip
 * 툴팁 표시하는 컴포넌트
 * 부모 컴포넌트에서 생성된 데이터를 전달받아 화면에 뿌립니다.
 * ───────────────────────────────────────────────────────────────
 */

const Tooltip = ({ data }) => {
  // console.log(data); // 부모에서 넘어오는 data

  // const test = '<p class="primary">테스트F<br/>테스트</p>';

  return (
    <div className="tooltip">
      <IconImg src={tooltip} alt="툴팁 버튼" />
      <div className="tooltip_text body3" dangerouslySetInnerHTML={{ __html: data }}></div>
    </div>
  );
};

export default Tooltip;

const IconImg = styled.img`
  filter: ${props => props.theme.imgFilter};
`;
