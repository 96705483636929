// css
import '../../styles/Reset.css';
import '../../styles/Common.css';

/* ───────────────────────────────────────────────────────────────
 * Tooltip
 * 툴팁 표시하는 컴포넌트
 * 부모 컴포넌트에서 생성된 데이터를 전달받아 화면에 뿌립니다.
 * ───────────────────────────────────────────────────────────────
 */

const TooltipPage = ({ data }) => {
  // console.log(data); // 부모에서 넘어오는 data

  return (
    <div className="tooltip_box">
      <div className="tooltip_text body3" dangerouslySetInnerHTML={{ __html: data }}></div>
    </div>
  );
};

export default TooltipPage;
