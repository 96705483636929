// basic
import { React } from 'react';

// css
import '../../styles/Chatbot.css';

// img
import arrangeIcon from '../../assets/arrange.png';
import returnIcon from '../../assets/return.png';

/* ───────────────────────────────────────────────────────────────
 * 화면 재배치 되는 버튼
 * ───────────────────────────────────────────────────────────────
 */

function ScreenEditBtn({ editbtn, onClick }) {
  const handleOnClick = () => {
    onClick();
  };

  return (
    <div className="arrange-container">
      <button onClick={handleOnClick}>
        {editbtn ? (
          <div>
            <img src={returnIcon} alt="return button" />
            <div className="arrange-button-text">배치 종료하기</div>
          </div>
        ) : (
          <div>
            <img src={arrangeIcon} alt="return button" />
            <div className="arrange-button-text">화면 배치하기</div>
          </div>
        )}
      </button>
    </div>
  );
}

export default ScreenEditBtn;
