//basic
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

//components
// import { formatDateToYYYYmmdd_ko } from '../../../utils/dateFunction.js';

//img
import countryImages from '../../../utils/countryImg.js';
import lockImg from '../../../assets/lock_img04.png';

/* ───────────────────────────────────────────────────────────────
 * LawsContent
 * 법규 내용 표시 컴포넌트 // 국내 해외 상이함
 * ───────────────────────────────────────────────────────────────
 */

const LawsContent = ({ data, selectedRegion }) => {
  // const today_ko = formatDateToYYYYmmdd_ko(new Date());
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    if (data && data.length > 0) {
      const hasSummaryData = data.some(item => item.division === selectedRegion);
      setShowMessage(!hasSummaryData);
    } else {
      setShowMessage(true);
    }
  }, [data, selectedRegion]);

  const regionData = data ? data.filter(item => item.division === selectedRegion) : [];

  // 해외 데이터 10개만 나오게 하기
  const selectedForeignData = selectedRegion === '해외' ? regionData.slice(-10).reverse() : [];

  const selectedRegionData = regionData.length > 0 ? [...regionData] : [];

  // 중복된 아이템의 갯수를 계산하는 함수
  const countDuplicateItems = value => {
    return selectedRegionData.filter(item => item.index === value).length;
  };

  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('URL이 없습니다.');
    }
  };

  // 해외법규 날짜
  const date = dateString => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);

    return `${year}.${month}.${day}`;
  };

  return (
    <div className="laws_contents_wrap body2">
      {selectedRegionData.length > 0 ? (
        <div className="line-height">
          {selectedRegionData.map((item, index) => {
            const indexValue = item.index;
            return (
              <React.Fragment key={item.id}>
                {/* 국내 법규 */}
                {selectedRegion === '국내' && (
                  <ul>
                    {indexValue !== selectedRegionData[index - 1]?.index && (
                      <Title className="text_box label1 d-flex">
                        {indexValue}{' '}
                        <SubText className="body2">총 {countDuplicateItems(indexValue)}건</SubText>
                      </Title>
                    )}
                    <SubTitle className="text_item" onClick={() => detailed_page_load(item)}>
                      {item.title}
                    </SubTitle>
                  </ul>
                )}
              </React.Fragment>
            );
          })}
          {/* 해외 법규 */}
          {selectedRegion === '해외' && (
            <table className="law_table">
              <Thead className="label3">
                <tr>
                  <th>국가</th>
                  <th>제목</th>
                  <th>등록일자</th>
                </tr>
              </Thead>
              <tbody className="body2">
                {selectedForeignData
                  .filter(
                    (item, index, array) => array.findIndex(i => i.title === item.title) === index,
                  )
                  .map(item => (
                    <Border key={item.id} onClick={() => detailed_page_load(item)}>
                      <TitleOver className="title3 center">
                        <img
                          src={countryImages.find(img => img.alt === item.country)?.src}
                          alt={item.country}
                        />
                        {item.country}
                      </TitleOver>
                      <TdOver>{item.title}</TdOver>
                      <TdOver>{date(item.post_date)}</TdOver>
                    </Border>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        showMessage && (
          <div className="no-news" style={{ height: '390px' }}>
            <img src={lockImg} alt="요약중 이미지" />
            <Text className="label1">
              법규 키워드를 설정해주세요.
              <span>
                수집된 정보가 없습니다.
                <br />
                법규 키워드를 설정해주세요.
                <br />
                해당 설정로 수집된 정보가 없다면
                <br />
                다음 날 최신 정보를 수집하여 보여 드립니다.
              </span>
            </Text>
          </div>
        )
      )}
    </div>
  );
};

export default LawsContent;
const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Title = styled.li`
  color: ${props => props.theme.colors.titleColor};
`;
const SubTitle = styled.li`
  color: ${props => props.theme.colors.subTitleColor};
`;

const TitleOver = styled.td`
  color: ${props => props.theme.colors.titleColor};
`;
const TdOver = styled.td`
  color: ${props => props.theme.colors.subTitleColor};
`;

const Border = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const SubText = styled.span`
  color: ${props => props.theme.colors.subTitleColor};
`;

const Thead = styled.thead`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border-top: 1px solid ${props => props.theme.colors.border};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;
