// 말풍선에 나오는 답변 입력 하는곳

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
  }
  handleOptions = options => {
    const message = this.createChatBotMessage(
      'How can I help you? Below are some possible options.',
      {
        widget: 'overview',
        loading: true,
        terminateLoading: true,
        ...options,
      },
    );

    this.addMessageToState(message);
  };

  // (1번) 질문 버튼 답변 (TSB를 활용하여 무엇을 할 수 있습니까?)
  handleChatAnswers_01 = () => {
    const message = this.createChatBotMessage(
      `Q.
      TSB를 활용하여 무엇을 할 수 있습니까?

      A.
      TSB는 맞춤 설정에 따라 다양한 정보를 수집하여 제공하고,비즈니스 의사결정을 내릴 수 있는 인사이트를 얻도록 도울 수 있습니다.

       더 궁금한 점이 있습니까?`,
      {
        widget: 'questionBtn02', // 답변과 함께 나올 다음 질문 버튼 목록 (chatbotConfig.js)
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      },
    );

    this.addMessageToState(message);
  };

  // (1-1번) 질문 버튼 답변
  handleChatAnswers_01_01 = () => {
    const message = this.createChatBotMessage(
      `Q.
      TSB에서 제공하는 정보는 어떤 것들이 있습니까?

      A.
      TSB는 현재 아래에 정보를 제공하고 있습니다.
      1) 업계 동향 및 경쟁 정보
      2) 금융 및 시장 현황
      3) 특허 정보
      4) 규제 정보
      5) 법규 정보
      6) 투자 정보
      7) 기업 보고서
      8) 소셜 트렌드 현황
      9) 행사 일정
      10) Techmeme 소식

      관련하여 정보 출처 및 수집 시간 등 내용은 
      각 영역의 툴팁을 통해 확인해주시기 바랍니다.
      
      더 궁금한 점이 있습니까?`,
      {
        widget: 'questionBtn03',
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      },
    );
    // console.log(message);

    this.addMessageToState(message);
  };

  // (1-2번) 질문 버튼 답변
  handleChatAnswers_01_02 = () => {
    const message = this.createChatBotMessage(
      `Q.
      TSB에서 제공하는 정보는 어떻게 자동으로 수집됩니까?
      
      A.
      사용자 님의 설정에 따라 지정된 주기에 정보를 자동으로 수집하고 최신화 합니다.
      
      우측 상단의 아이콘을 클릭하여 [설정 - TSB]으로 이동하면 맞춤 설정이 가능합니다.
      변경된 설정 정보에 따른 정보 수집 및 최신화는 정해진 주기에 따라 진행되니 참고하시기 바랍니다.
      (수집 주기와 관련된 상세 정보는 툴팁 참고)
      
      더 궁금한 점이 있습니까?`,
      {
        widget: 'questionBtn04',
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      },
    );
    // console.log(message);

    this.addMessageToState(message);
  };

  // (1-3번) 질문 버튼 답변
  handleChatAnswers_01_03 = () => {
    const message = this.createChatBotMessage(
      `Q.
      TSB에서 데이터 수집은 어떻게 설정합니까?
      
      A.
      우측 상단의 아이콘을 클릭하여 [설정 - TSB]설정으로 이동하여 설정할 수 있습니다.
      
      각 영역에 따른 설정이 개별로 존재하며,설정이 없는 영역은 별도 설정 없이 데이터가 자동으로
      수집되는 항목이니 알아두시기 바랍니다.
      
      더 궁금한 점이 있습니까?`,
      {
        widget: 'questionBtn05',
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      },
    );

    this.addMessageToState(message);
  };

  // (1-4번) 질문 버튼 답변
  handleChatAnswers_01_04 = () => {
    const message = this.createChatBotMessage(
      `Q.
      TSB에서 데이터가 확인되지 않습니다.
      어떻게 해야 합니까?

      A.
      데이터가 확인되지 않는 경우는 다음과 같습니다.

      1) 기존 설정한 키워드로 수집된 데이터가 없는 경우
      2) 방금 설정한 키워드로 데이터가 아직 수집되지 않은 경우
      
      새롭게 키워드를 설정해주신 경우라면 다음날 데이터가 수집된 후 확인해주시기 바랍니다.
      
      더 궁금한 점이 있습니까?`,
      {
        widget: 'questionBtn06',
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      },
    );

    this.addMessageToState(message);
  };

  // (1-5번) 질문 버튼 답변
  handleChatAnswers_01_05 = () => {
    const message = this.createChatBotMessage(
      `TSB와 관련하여 도움이 되셨습니까?
      
      더 나은 서비스를 제공하기 위해 최선을 다해 노력하고 있으니 의견을 전달하고 싶다면
      mspark@rbrain.co.kr로 문의해주시기 바랍니다.
      
      감사합니다.`,
      {
        widget: 'questionBtn07',
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      },
    );

    this.addMessageToState(message);
  };

  // (1-6번) 질문 버튼 답변
  handleChatAnswers_01_06 = () => {
    const message = this.createChatBotMessage(
      `안녕하세요? Trend Sensing Board입니다.

    맞춤 기준으로 여러 출처의 다양한 정보를 한눈에 볼 수 있도록 제공하는 서비스입니다.
    우측 상단의 아이콘을 클릭하여 [설정 - TSB 설정]에서 맞춤 기준을 설정하시기 바랍니다.

    더 궁금한 점이 있습니까?`,
      {
        widget: 'questionBtn01',
        loading: true,
        terminateLoading: true,
        withAvatar: true,
      },
    );

    this.addMessageToState(message);
  };

  // 사용자가 질문창에 임의로 아무렇게 쳤을때 답변
  handleError = () => {
    const message = this.createChatBotMessage('죄송합니다. 현재는 준비되지 않은 질문입니다.', {
      widget: 'chatAnswers',
      loading: true,
      terminateLoading: true,
      // withAvatar: true,
    });

    this.addMessageToState(message);
  };

  // handleMedicine = () => {
  //   const message = this.createChatBotMessage(
  //     'To have clinical medicine safely delivered to your home, please refer to the link below.',
  //     {
  //       widget: 'medicineDelivery',
  //       loading: true,
  //       terminateLoading: true,
  //       withAvatar: true,
  //     },
  //   );

  //   this.addMessageToState(message);
  // };

  // handleJoke = () => {
  //   var jokes = [
  //     'So many coronavirus jokes out there, it’s a pundemic!',
  //     'I’ll tell you a coronavirus joke now, but you’ll have to wait two weeks to see if you got it!',
  //     'Did you hear the joke about coronavirus? Never mind, I don’t want to spread it around!',
  //     'What should you do if you don’t understand a coronavirus joke? Be patient!',
  //     'Why do they call it the novel coronavirus? It’s a long story...',
  //     'Since we’re all in quarantine I guess we’ll be making only inside jokes from now on!',
  //   ];

  //   var randomJoke = jokes[Math.floor(Math.random() * jokes.length)];

  //   const message = this.createChatBotMessage(randomJoke);

  //   this.addMessageToState(message);
  // };

  // handleThanks = () => {
  //   const message = this.createChatBotMessage("You're welcome, and stay safe!");

  //   this.addMessageToState(message);
  // };

  addMessageToState = message => {
    this.setState(state => ({
      ...state,
      messages: [...state.messages, message],
    }));
  };
}

export default ActionProvider;
