// basic
import React from 'react';

// components
import Tooltip from '../../components/common/TooltipPage';

// img
import korea from '../../assets/korea.png';
import global from '../../assets/global.png';

/* ───────────────────────────────────────────────────────────────
 * RegionButtons
 * 국내, 해외 구분 버튼 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function RegionButtons({ options, selectedRegion, onRegionChange }) {
  const tooltipK = '클릭 시 국내 기준 정보를 보여줍니다.';
  const tooltipG = '클릭 시 해외 기준 정보를 보여줍니다.';

  return (
    <div className="global_btn_wrap d-center body3 sm_global_wrap">
      <div className="d-center">
        {options.map((region, index) => (
          <React.Fragment key={region}>
            {region === '해외' ? (
              <div
                className={`global_btn sm_global d-flex ${
                  region === selectedRegion ? 'selected' : ''
                }`}
                onClick={() => onRegionChange(region)}
              >
                <img src={global} alt="해외 아이콘 이미지" />
                {region}
                <div className="tooltip_wrap">
                  <Tooltip data={tooltipG} />
                </div>
              </div>
            ) : (
              <div
                className={`global_btn sm_global d-flex ${
                  region === selectedRegion ? 'selected' : ''
                }`}
                onClick={() => onRegionChange(region)}
              >
                <img src={korea} alt="국내 아이콘 이미지" />
                {region}
                <div className="tooltip_wrap tooltip_k">
                  <Tooltip data={tooltipK} />
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default RegionButtons;
