import React, { useState, useEffect } from 'react';

export default function DashDate() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

  const formattedDate = `${String(currentTime.getMonth() + 1).padStart(2, '0')}. ${String(
    currentTime.getDate(),
  ).padStart(2, '0')}(${daysOfWeek[currentTime.getDay()]})　|　${String(
    currentTime.getHours(),
  ).padStart(2, '0')} : ${String(currentTime.getMinutes()).padStart(2, '0')}`;

  return (
    <div>
      <div className="dash-data d-between">
        <div className="d-flex">
          {formattedDate}
          <p>오늘의 정보를 전달드립니다.</p>
        </div>
        {/* <div>업데이트 : </div> */}
      </div>
    </div>
  );
}
