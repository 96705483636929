// -- basic -- //
import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * openai_api
 *
 */

// openai api // gpt-4o
export async function openai_api(options) {
  let query = null;

  if (options.type === 'news') {
    query = `ai-news-keywords?user_email=${options.user_email}`;
  }

  query += `&division=${options.division}`;

  // console.log(query);

  try {
    const response = await fetch(`${BASE_URL}/openai/${query}`);
    const data = await response.text();
    // console.log('이벤트스케쥴 :: ', data);
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}

export async function openai_using_api(options) {
  console.log(options);
  const response = await fetch(`${BASE_URL}/open-ai-using/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  });
  if (!response.ok) {
    throw new Error('저장실패');
  }
  const result = await response.json();
  // console.log('airesult :: ', result);
  return result;
}
