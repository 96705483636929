//bassic
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

//common
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';
import Tooltip from '../../common/Tooltip.js';

//api
import { market_api, market_summary_api } from '../../../apis/market_api.js';

//components
import MarketList from './MarketList.js';
import MarketSummary from './MarketSummary.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

//css
import '../../../styles/MarketStock.css';

//img
import dollar from '../../../assets/dollar.png';

/* ───────────────────────────────────────────────────────────────
 * Market
 * 금융 및 시장 현황 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Market() {
  const { user } = useAuth();

  const user_email = user.result.user_email;

  const tooltipValue =
    '자동으로 수집한 최신 주요 금융 정보를 <span class="primary">AI</span>로 요약해드립니다.<br />선물 정보는 사용자가 설정한 항목만 보여드립니다.<br />우측 상단 [아이콘]을 클릭하여 [설정 - 금융 지수 설정]에서 설정해보세요.<br /><br /><span class="primary">[정보 출처]</span><br />- 금융 정보: 기획재정부<br />- 선물 정보: 네이버 증권<br /><br /><span class="primary">[수집 시간]</span><br />- 금융 정보: 00:00 ~ 05:00 수집<br />- 선물 정보: 10:00 ~ 15:00 사이 <span class="primary">매시간 업데이트</span>';

  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  const [marketData, setMarketData] = useState(null);
  const [marketSummaryData, setMarketSummaryData] = useState(null);

  const fetchData = useCallback(async () => {
    const options = { market_collection_date: today, user_email: user_email };

    try {
      const [marketResult, summaryResult] = await Promise.all([
        market_api(options),
        market_summary_api(options),
      ]);
      setMarketData(marketResult);
      setMarketSummaryData(summaryResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [today, user_email]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="market_wrap">
      <div className="d-flex">
        {/* 타이틀 */}
        <TextTitle>금융 및 시장 현황</TextTitle>

        {/* 툴팁 */}
        <div className="tooltip_one">
          <Tooltip data={tooltipValue} />
        </div>
      </div>

      <Container className="container">
        <ContTitle className="sub_title d-between">
          <div className="d-flex">
            <img src={dollar} alt="달러 아이콘 이미지" />
            <Text className="title2">현황 요약</Text>
          </div>
        </ContTitle>

        <div className="d-between">
          <Border className="cont_left">
            <div className="scroll maker">
              {/* 현황요약 */}
              <MarketSummary data={marketSummaryData} />
            </div>
          </Border>

          <div className="cont_right">
            <MarketList data={marketData} />
          </div>
        </div>
      </Container>
    </div>
  );
}
export default Market;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Border = styled.div`
  border-right: 1px solid ${props => props.theme.colors.border};
`;
const TextTitle = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;