//basic
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

//components
import TagKeyword from '../../common/TagKeyword.js';
import Tooltip from '../../common/Tooltip.js';
import LawsContent from './LawsContent.js';

//api
import { laws_api } from '../../../apis/laws_api.js';
import { setting_keyword } from '../../../apis/users_api.js';
import RegionButtons from '../../common/RegionButtons.js';

//css
import '../../../styles/LawLegislation.css';

//img
import news from '../../../assets/news.png';
import { useAuth } from '../../../contexts/AuthProvider.js';

/* ───────────────────────────────────────────────────────────────
 * Laws : 국내 법규 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Laws() {
  const tooltipValue =
    '<p>설정한 <span class="primary">키워드</span>에 따라 자동으로 수집한 법규 정보를 확인해보세요.<br />우측 상단 [아이콘]을 클릭하여 [설정 - 법규 검색 설정]에서 키워드를 설정해보세요.<br />법규 정보 클릭 시 해당 상세 자료를 볼 수 있습니다.<br /><br /><span class="primary">[정보 출처]</span><br />- 법규 정보: 행정안전부<br /><br /><span class="primary">[수집 시간]</span><br />- 법규 정보: 00:00 ~ 05:00 수집</p>';

  // TagKeyword 컴포넌트를 사용하는 변수
  const tagOption = ['laws_keywords_domestic', 'laws_keywords_overseas'];
  const [tagValue, setTagValue] = useState(tagOption[0]);

  // 법규 해외, 국내 탭으로 구분할 경우 대비해서 남김
  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);

  const [tagKeyword, setTagKeyword] = useState({});

  const [lawsData, setLawsData] = useState(null);

  const handleRegionChange = region => {
    setSelectedRegion(region);

    if (region === regionOption[1]) {
      setTagValue(tagOption[1]);
    } else {
      setTagValue(tagOption[0]);
    }
  };

  const { user } = useAuth();
  const { user_email } = user.result;

  // -- 같은 조건으로 뉴스, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { division: selectedRegion, user_email: user_email };

    try {
      const [lawsResult, keywordResult] = await Promise.all([
        laws_api(options),
        setting_keyword(options),
      ]);

      setLawsData(lawsResult);
      setTagKeyword(keywordResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, user_email]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="laws_wrap">
      <div className="d-flex">
        {/* 타이틀 */}
        <TextTitle>법규 정보</TextTitle>

        {/* 툴팁 */}
        <div className="tooltip_one">
          <Tooltip data={tooltipValue} />
        </div>
      </div>

      <Container className="container">
        <ContTitle className="sub_title d-between">
          <div className="d-flex">
            <img src={news} alt="뉴스 아이콘 이미지" />
            {selectedRegion === regionOption[0] ? (
              <Text className="title2">
                <span className="span">국내</span>법규 정보 요약
              </Text>
            ) : (
              <Text className="title2">
                <span className="span">해외</span>법규 정보 요약
              </Text>
            )}
          </div>

          {/* 국내/해외 */}
          <RegionButtons
            options={regionOption}
            selectedRegion={selectedRegion}
            onRegionChange={handleRegionChange}
          />
        </ContTitle>

        <div className="laws_container scroll">
          <div className="keyword_wrap d-flex">
            {/* 키워드 */}
            <p className="label2">키워드</p>
            {tagKeyword.length >= 0 ? (
              <div>
                <TagKeyword data={tagKeyword} tag={tagValue} />
              </div>
            ) : (
              <p>No Keywords</p>
            )}
          </div>

          {/* 법규 내용 */}
          <div>
            <LawsContent data={lawsData} selectedRegion={selectedRegion} />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Laws;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const TextTitle = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;
