//basic
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

//api
import { patent_api } from '../../../apis/patent_api.js';
import { setting_keyword } from '../../../apis/users_api.js';

//components
import Tooltip from '../../common/Tooltip.js';
import RegionButtons from '../../common/RegionButtons.js';
import TagKeyword from '../../common/TagKeyword.js';
import PatentsContent from './PatentsContent.js';

//img
import news from '../../../assets/news.png';
import { useAuth } from '../../../contexts/AuthProvider.js';

/* ───────────────────────────────────────────────────────────────
 * Patent
 * 특허 정보 화면 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

export default function Patent() {
  const { user } = useAuth();
  const { user_email } = user.result;

  const tooltipValue =
    '<p>설정한 <span class="primary">키워드</span>에 따라 자동으로 수집된 특허 정보를 확인해보세요.<br />우측 상단 [아이콘]을 클릭하여 [설정 - 특허 검색 설정]에서 키워드를 설정해보세요.<br />특허 정보 클릭 시 상세 정보를 제공하는 페이지가 나타납니다.<br /><br /><span class="primary">[정보 출처]</span><br />- 특허 정보: 특허청 키프리스<br /><br /><span class="primary">[수집 시간]</span><br />- 특허 정보: 00:00 ~ 05:00 수집</p>';

  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const tagOption = ['patents_keywords_domestic', 'patents_keywords_overseas'];
  const [tagValue, setTagValue] = useState(tagOption[0]);

  const [tagKeyword, setTagKeyword] = useState({});
  const [data, setData] = useState(null);

  const handleRegionChange = region => {
    setSelectedRegion(region);

    if (region === regionOption[1]) {
      setTagValue(tagOption[1]);
    } else {
      setTagValue(tagOption[0]);
    }
  };

  // console.log(user);

  // -- 같은 조건으로 뉴스, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { division: selectedRegion, user_email: user_email };

    try {
      const [patentResult, keywordResult] = await Promise.all([
        patent_api(options),
        setting_keyword(options),
      ]);

      setData(patentResult);
      setTagKeyword(keywordResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, user_email]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="laws_wrap">
      <div className="d-flex">
        {/* 타이틀 */}
        <TextTitle>특허 정보</TextTitle>

        {/* 툴팁 */}
        <div className="tooltip_one">
          <Tooltip data={tooltipValue} />
        </div>
      </div>

      <Container className="container">
        <ContTitle className="sub_title d-between">
          <div className="d-flex">
            <img src={news} alt="뉴스 아이콘 이미지" />
            {selectedRegion === regionOption[0] ? (
              <Text className="title2">
                <span className="span">국내</span>특허 정보
              </Text>
            ) : (
              <Text className="title2">
                <span className="span">해외</span>특허 정보
              </Text>
            )}
          </div>

          {/* 국내/해외 */}
          <RegionButtons
            options={regionOption}
            selectedRegion={selectedRegion}
            onRegionChange={handleRegionChange}
          />
        </ContTitle>

        <div className="laws_container scroll">
          <div className="keyword_wrap d-flex">
            {/* 키워드 */}
            <p className="label2">키워드</p>
            {tagKeyword.length > 0 ? (
              <div>
                <TagKeyword data={tagKeyword} tag={tagValue} />
              </div>
            ) : (
              <p>No Keywords</p>
            )}
          </div>

          {/* 특허 내용 */}
          <div>
            <PatentsContent data={data} selectedRegion={selectedRegion} />
          </div>
        </div>
      </Container>
    </div>
  );
}

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const TextTitle = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;