/* ───────────────────────────────────────────────────────────────
 * InvestsContent : 투자 화면  테이블 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

// basic
import React from 'react';
import styled from 'styled-components';

// img
import lockImg from '../../../assets/lock_img03.png';

const InvestsContent = ({ data }) => {
  let uniqueServices;

  // 데이터가 널일 때 처리
  if (!data || data.length === 0) {
    // console.log('데이터가 없습니다.');
    uniqueServices = null;
  } else {
    // 데이터가 널이 아닐때 처리

    // service 값을 기준으로 객체를 그룹화하여 중복 제거
    let uniqueServicesMap = data.reduce((accumulator, currentValue) => {
      accumulator[currentValue.service] = currentValue;
      return accumulator;
    }, {});

    // 중복이 제거된 객체들을 배열로 변환
    uniqueServices = Object.values(uniqueServicesMap);
  }

  // console.log(data);
  // console.log(uniqueServices);

  return (
    <div className="table">
      {uniqueServices && uniqueServices.length > 0 ? ( // data가 존재하고 비어있지 않을 때 테이블을 렌더링합니다.
        <table>
          <Thead className="label3">
            <tr key={uniqueServices.company_description}>
              <th>기업명</th>
              <th>서비스</th>
              <th>단계</th>
              <th>금액</th>
            </tr>
          </Thead>

          <tbody className="scroll body2">
            {uniqueServices.map(item => (
              <Border key={item.id}>
                <Title>{item.company}</Title>
                <Title>{item.service}</Title>
                <Title>{item.investment_stage}</Title>
                <Title>{item.investment_amount}</Title>
              </Border>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-news" style={{ height: '240px' }}>
          <img src={lockImg} alt="요약중 이미지" />
          <Text className="label1">
            수집된 최신 정보가 없습니다.
            <br />
            <br />
            다른 정보를 확인 하시려면
            <br />
            설정 페이지에서 다른 카테고리로 설정해주시기 바랍니다.
          </Text>
        </div>
      )}
    </div>
  );
};

export default InvestsContent;

const Thead = styled.thead`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border-top: 1px solid ${props => props.theme.colors.border};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Border = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Title = styled.td`
  color: ${props => props.theme.colors.titleColor};
`;
