import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const SideNav = ({ scrollRef, DETAIL_NAV, onNavClick }) => {
  // 현재 활성화된 네비게이션 항목의 인덱스를 상태로 관리
  const [navIndex, setNavIndex] = useState(0);

  // 네비게이션 항목의 DOM 요소에 대한 참조를 생성
  const navRef = useRef(Array(DETAIL_NAV.length).fill(null));

  useEffect(() => {
    // 페이지 로딩 시 초기 네비게이션 항목에 'active' 클래스 추가
    setActiveClass(0);
  }, []);

  useEffect(() => {
    // 스크롤 이벤트 핸들러
    const handleScroll = () => {
      // 스크롤 이벤트가 발생할 때마다 ref 확인
      scrollRef.current.forEach((ref, idx) => {
        if (ref && ref.offsetTop - 180 < window.scrollY) {
          // 스크롤 위치에 따라 활성화된 네비게이션 항목 변경
          setActiveClass(idx);
          if (window.scrollY > 3500) {
            setActiveClass(9);
          }
          if (window.scrollY > 3800) {
            setActiveClass(10);
          }
        }
      });
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트되거나 `scrollRef`가 변경되면 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollRef]);

  useEffect(() => {
    // 활성화된 네비게이션 항목 업데이트 시 실행되는 효과
    setActiveClass(navIndex);

    // 클릭한 네비게이션 항목의 offsetTop을 가져와서 해당 위치로 스크롤
    const selectedOffsetTop = scrollRef.current[navIndex]?.offsetTop;
    if (selectedOffsetTop !== undefined) {
      window.scrollTo({ top: selectedOffsetTop, behavior: 'smooth' });
    }
  }, [navIndex, scrollRef]);

  // 활성화된 네비게이션 항목의 클래스를 설정하는 함수
  const setActiveClass = index => {
    navRef.current.forEach(ref => {
      if (ref) ref.classList.remove('active');
    });

    if (navRef.current[index]) navRef.current[index].classList.add('active');
  };

  // 네비게이션 항목 클릭 시 호출되는 함수
  const handleNavClick = (index, option) => {
    // 클릭한 네비게이션 항목의 인덱스를 업데이트하고, 해당 옵션을 전달
    setNavIndex(index);
    onNavClick(option);
  };

  return (
    <Container className="side_nav_wrap body2">
      {/* 고정된 네비게이션 항목 */}
      <Text className="title2">기본 정보</Text>
      {DETAIL_NAV.length > 2 && (
        <Text className="title2" style={{ paddingTop: '130px' }}>
          TSB 설정
        </Text>
      )}
      {/* 동적으로 생성되는 네비게이션 항목 맵핑 */}
      {DETAIL_NAV.map(({ idx, name, option }, index) => (
        <ContTitle
          to="/setting"
          className={`d-flex${
            index === 0 ? ' active privacy ' : index === 1 ? ' active password  ' : ''
          }${index === navIndex ? ' active' : ''}`}
          key={idx}
          ref={ref => (navRef.current[idx] = ref)}
          onClick={() => handleNavClick(index, option)}
        >
          <Title>{name}</Title>
        </ContTitle>
      ))}
    </Container>
  );
};

export default SideNav;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.h1`
  color: ${props => props.theme.colors.subTitleColor};
`;
const Title = styled.p`
  color: ${props => props.theme.colors.navTitleColor};
`;
