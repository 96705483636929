// basic
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import styled from 'styled-components';

// api
import { laws_api } from '../../../apis/laws_api.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

// external library
import Tooltip from '../../common/Tooltip.js';
import RegionButtons from '../../common/RegionButtons.js';

// img
import plus from '../../../assets/plus.png';
import lockImg from '../../../assets/lock_img04.png';
import countryImages from '../../../utils/countryImg';

/* ───────────────────────────────────────────────────────────────
 * Laws : 법규 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Laws(editBtn) {
  const screen_edit = editBtn.editBtn;

  const { user } = useAuth();
  // console.log(user);

  const lawsKeywords = `${user?.result?.laws_keywords_domestic || ''},${
    user?.result?.laws_keywords_overseas || ''
  }`;

  const tooltipValue =
    '<p>설정한 <span class="primary">키워드</span>에 따라 자동으로 수집한 법규 정보를 확인해보세요.<br />우측 상단 [아이콘]을 클릭하여 [설정 - 법규 검색 설정]에서 키워드를 설정해보세요.<br />법규 정보 클릭 시 해당 상세 자료를 볼 수 있습니다.<br /><br /><span class="primary">[정보 출처]</span><br />- 법규 정보: 행정안전부<br/><br/><span class="primary">[수집 시간]</span><br/>- 법규 정보: 00:00 ~ 05:00 수집</p> ';

  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const [lawsData, setLawsData] = useState(null);
  const [showMessage, setShowMessage] = useState(true);

  // console.log(lawsData);

  useEffect(() => {
    setShowMessage(
      !(lawsData && lawsData.length > 0 && lawsData.some(item => item.division === selectedRegion)),
    );
  }, [lawsData, selectedRegion]);

  const regionData = lawsData ? lawsData.filter(item => item.division === selectedRegion) : [];

  // 해외 데이터 10개만 나오게 하기
  const selectedForeignData = selectedRegion === '해외' ? regionData.slice(-10).reverse() : [];

  // 중복된 아이템의 총 갯수를 계산
  const selectedRegionData = regionData.length > 0 ? regionData : [];
  const countDuplicateItems = value => {
    return selectedRegionData.filter(item => item.index === value).length;
  };

  // 리스트 클릭시 페이지 이동
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('URL이 없습니다.');
    }
  };

  // 해외법규 날짜
  const formatDate = dateString => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${year}.${month}.${day}`;
  };

  const handleRegionChange = region => {
    setSelectedRegion(region);
    // fetchData();
  };

  // -- 같은 조건으로 법규 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { division: selectedRegion, keyword: lawsKeywords };

    try {
      const [lawsResult] = await Promise.all([laws_api(options)]);

      setLawsData(lawsResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, lawsKeywords]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleContactButtonClick = () => {
    scroll.scrollTo(2388, {
      //스크롤값
      duration: 100,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 0,
      top: 0,
    });
  };

  return (
    <Container className="Laws laws_wrap cont_wrap">
      <ContTitle className="cont_title d-between">
        {/* screen_edit 변수가 true 일때만 가려지는 css 적용 */}
        {screen_edit && <div className="arrange_wrap"></div>}

        <div className="d-flex">
          {/* 타이틀 */}
          <Text className="title3">법규 정보</Text>

          {/* 툴팁 */}
          <Tooltip data={tooltipValue} />
        </div>

        <div className="d-flex">
          {/* 국내/해외 */}
          <div className="sm_global_box">
            <RegionButtons
              options={regionOption}
              selectedRegion={selectedRegion}
              onRegionChange={handleRegionChange}
            />
          </div>

          {/* 더보기 버튼 */}
          <Link to="/detail">
            <PlusBtn className="plus_btn label4" onClick={handleContactButtonClick}>
              더보기 <IconImg src={plus} alt="아이콘 이미지" />
            </PlusBtn>
          </Link>
        </div>
      </ContTitle>

      <div className="scroll" style={{ paddingRight: '10px' }}>
        {selectedRegionData.length > 0 ? (
          <div>
            {selectedRegionData.map((item, index) => {
              const indexValue = item.index;
              return (
                <React.Fragment key={item.id}>
                  {/* 국내 법규 */}
                  {selectedRegion === '국내' && (
                    <ul>
                      {indexValue !== selectedRegionData[index - 1]?.index && (
                        <Title className="text_box label2 d-flex">
                          {indexValue}
                          <SubText className="body3" style={{ paddingLeft: '5px' }}>
                            총 {countDuplicateItems(indexValue)}건
                          </SubText>
                        </Title>
                      )}
                      <Title className="body3 text_item" onClick={() => detailed_page_load(item)}>
                        - {item.title}
                      </Title>
                    </ul>
                  )}
                </React.Fragment>
              );
            })}

            {/* 해외 법규 */}
            {selectedRegion === '해외' && (
              <table className="law_table label3">
                <Thead>
                  <tr>
                    <th>국가</th>
                    <th>제목</th>
                    <th>등록일자</th>
                  </tr>
                </Thead>
                <tbody>
                  {selectedForeignData
                    .filter(
                      (item, index, array) =>
                        array.findIndex(i => i.title === item.title) === index,
                    )
                    .map(item => (
                      <Border key={item.id} onClick={() => detailed_page_load(item)}>
                        <td className="none">
                          <img
                            src={countryImages.find(img => img.alt === item.country)?.src}
                            alt={item.country}
                          />
                          {item.country}
                        </td>
                        <TitleOver>{item.title}</TitleOver>
                        <TitleOver>{formatDate(item.post_date)}</TitleOver>
                      </Border>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        ) : (
          showMessage && (
            <div className="no-news" style={{ height: '280px' }}>
              <img src={lockImg} alt="요약중 이미지" />
              <Text className="label1">
                법규 키워드를 설정해주세요.
                <SubText>
                  수집된 정보가 없습니다.
                  <br />
                  법규 키워드를 설정해주세요.
                  <br />
                  해당 설정로 수집된 정보가 없다면
                  <br />
                  다음 날 최신 정보를 수집하여 보여 드립니다.
                </SubText>
              </Text>
            </div>
          )
        )}
      </div>
    </Container>
  );
}

export default Laws;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Title = styled.li`
  color: ${props => props.theme.colors.titleColor};
`;

const TitleOver = styled.td`
  color: ${props => props.theme.colors.titleColor};
`;

const Border = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const SubText = styled.span`
  color: ${props => props.theme.colors.subTitleColor};
`;

const PlusBtn = styled.button`
  background: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.subTitleColor};
`;

const Thead = styled.thead`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border-top: 1px solid ${props => props.theme.colors.border};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const IconImg = styled.img`
  filter: ${props => props.theme.imgFilter};
`;
