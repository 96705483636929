//basic
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

//api
import { field_keywords_api } from '../../../apis/field_keywords_api.js';

//external library
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';
import Tooltip from '../../common/Tooltip.js';

//components
import RegionButtons from '../../common/RegionButtons.js';

//img
import keyword from '../../../assets/keyword.png';

/* ───────────────────────────────────────────────────────────────
 * FieldKeywords
 * 뉴스 정보에서 주요 키워드를 추출하여 표시하는 컴포넌트
 * 현재는 빅카인즈 데이터를 임시로 사용 하고 있음. // 2024 01 05 기준
 * ───────────────────────────────────────────────────────────────
 */

function FieldKeywords() {
  const { user } = useAuth();

  const user_email = user.result.user_email || '';

  const today = formatDateToYYYYmmdd(new Date());

  const tooltipValue =
    '<p>수집한 뉴스 데이터를 분석하여 핵심 키워드를 보여줍니다.<br />설정한 키워드를 클릭하면 연관된 키워드만 추출하여 보여줍니다.<br /><br /><span class="primary">[핵심 키워드 기준]</span><br />- 언급량</p>';

  const [fieldData, setFieldData] = useState([]);
  const [keywordList, setKeywordList] = useState([]);
  const [newsKeywords, setNewsKeywords] = useState([]); // 뉴스 키워드를 담은 변수임, 메인 화면에 나오는 키워드들

  // console.log(newsKeywords);

  // 국내 해외 구분
  const regionOption = useMemo(() => ['국내', '해외'], []);
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);

  // TagKeyword 컴포넌트를 사용하는 변수
  const tagOption = ['news_keywords_domestic', 'news_keywords_overseas'];
  const [tagValue, setTagValue] = useState(tagOption[0]);

  //색상
  const newColor = '#1EB3B8';
  const colors = ['#2C959C', '#5CCBA3', '#96E587', '#81DBD1', newColor];

  // 버튼 클릭 상태
  const [selectedButton, setSelectedButton] = useState(null);

  const spiralType = 'archimedean'; //archimedean, rectangular
  const withRotation = false;

  const domesticKeywords = user?.result?.news_keywords_domestic || '';
  const overseasKeywords = user?.result?.news_keywords_overseas || '';

  // let result;
  useEffect(() => {
    async function fetchData() {
      try {
        const keyOptions = {
          collection_date: today,
          division: selectedRegion,
          user_email: user_email,
        };

        const result = await field_keywords_api(keyOptions);
        setKeywordList(result);
        setNewsKeywords(result);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    }
    fetchData();
  }, [today, selectedRegion, user_email]);

  const keywordBtnLabel = useMemo(() => {
    let extractedKeywords = [];
    if (selectedRegion === regionOption[0]) {
      extractedKeywords = domesticKeywords
        .split(',')
        .map((keyword, index) => ({ id: index + 1, name: keyword }));
    } else if (selectedRegion === regionOption[1]) {
      extractedKeywords = overseasKeywords
        .split(',')
        .map((keyword, index) => ({ id: index + 1, name: keyword }));
    }
    // Add '전체' (All) at the beginning
    return [{ id: 0, name: '전체' }, ...extractedKeywords];
  }, [domesticKeywords, overseasKeywords, regionOption, selectedRegion]);

  const handleRegionChange = region => {
    setSelectedRegion(region);

    if (region === regionOption[1]) {
      setTagValue(tagOption[1]);
    } else {
      setTagValue(tagOption[0]);
    }
  };

  const fontSizeSetter = datum => fontScale(datum.value);
  const fixedValueGenerator = () => 0.5;

  const fontScale = scaleLog({
    domain: [Math.min(...fieldData.map(w => w.value)), Math.max(...fieldData.map(w => w.value))],
    range: [10, 50],
  });

  function getRotationDegree() {
    const rand = Math.random();
    const degree = rand > 0.5 ? 60 : -60;

    return rand * degree;
  }

  // 키워드 버튼 누를때마다 실행 함수.
  const handleKeywordBtn = async e => {
    const keywordName = e.target.innerText;
    // console.log(keywordName); // 버튼누른거
    // console.log(newsKeywords); // result값

    const filterData =
      keywordName === '전체'
        ? newsKeywords
        : newsKeywords.filter(item => item.type === keywordName);

    // console.log('버튼누를때 로드될 데이터 :: ', filterData);

    const updatedData = filterData.map(item => ({
      text: item.keyword,
      value: item.count, //노출수
    }));
    // 워드클라우드 표시 data -- 김수정
    setFieldData(updatedData);
    // 키워드 순위 표시 data -- 박지영
    setKeywordList(filterData);
    // 선택된 버튼 업데이트
    setSelectedButton(keywordName);
  };

  const handleLoad = useCallback(async () => {
    try {
      const updatedData = newsKeywords.map(item => ({
        text: item.keyword,
        value: item.count,
      }));

      setFieldData(updatedData);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }, [newsKeywords]);

  useEffect(() => {
    setSelectedButton(keywordBtnLabel[0].name); //페이지 첫 로드될때 첫번째 버튼에 클래스 붙기
    handleLoad();
  }, [keywordBtnLabel, handleLoad]);

  // 정렬
  const sortedKeywordList = keywordList
    .sort((a, b) => {
      if (b.count !== a.count) {
        return b.count - a.count;
      }
      return a.keyword.localeCompare(b.keyword);
    })
    .filter((keyword, index, self) => {
      // 중복제거
      return index === self.findIndex(k => k.keyword === keyword.keyword);
    });

  return (
    <div className="fieldKeywords_wrap">
      <div className="d-flex">
        {/* 타이틀 */}
        <TextTitle>
          <span>경제</span>뉴스 주요 키워드
        </TextTitle>

        {/* 툴팁 */}
        <div className="tooltip_one">
          <Tooltip data={tooltipValue} />
        </div>
      </div>

      <Container className="container" style={{ height: '490px' }}>
        <ContTitle className="sub_title d-between">
          <div className="d-flex">
            <img src={keyword} alt="돋보기 아이콘 이미지" />
            <Title className="title2">키워드 순위</Title>
          </div>

          {/* 국내/해외 */}
          <RegionButtons
            options={regionOption}
            selectedRegion={selectedRegion}
            onRegionChange={handleRegionChange}
          />
        </ContTitle>

        <div className="inlineFlex">
          <ul className="keyword_list">
            <Border className="keyword_list_title d-between">
              <div className="d-flex">
                <SubText className="label2 gray2">순위</SubText>
                <SubText className="label2 gray2">키워드</SubText>
              </div>
              <SubText className="label2">노출수</SubText>
            </Border>

            {sortedKeywordList.slice(0, 10).map((keyword, index) => (
              <li key={index} className="d-between" style={{ paddingTop: '13px' }}>
                <div className="d-flex">
                  <p className={`num body3 d-center ${index + 1 <= 3 ? 'highlight' : ''}`}>
                    {index + 1}
                  </p>
                  <Title className="body2">{keyword.keyword}</Title>
                </div>
                <p className="count body2">{keyword.count}</p>
              </li>
            ))}
          </ul>

          {fieldData && (
            <BorderLeft className="wordcloud_container">
              <div className="d-flex">
                {keywordBtnLabel.map(button => {
                  return (
                    <button
                      key={button.id}
                      value={tagValue}
                      onClick={handleKeywordBtn}
                      className={`btn label2 ${selectedButton === button.name ? 'selected' : ''}`}
                    >
                      {button.name}
                    </button>
                  );
                })}
              </div>
              <Wordcloud
                className="wordcloud"
                words={fieldData}
                width={600}
                height={375}
                fontSize={fontSizeSetter}
                font="Impact"
                padding={3}
                spiral={spiralType}
                rotate={withRotation ? getRotationDegree : 0}
                random={fixedValueGenerator}
              >
                {cloudWords =>
                  cloudWords.map((w, i) => (
                    <Text
                      key={i}
                      className={`word size-${w.size}`}
                      fill={colors[i % colors.length]}
                      textAnchor="middle"
                      transform={`translate(${w.x * 0.8}, ${w.y * 0.8}) rotate(${w.rotate})`}
                      fontSize={w.size * 0.8}
                    >
                      {w.text}
                    </Text>
                  ))
                }
              </Wordcloud>
            </BorderLeft>
          )}
        </div>
      </Container>
    </div>
  );
}

export default FieldKeywords;
const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const Title = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const TextTitle = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;
const SubText = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;
const Border = styled.li`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;
const BorderLeft = styled.div`
  border-left: 1px solid ${props => props.theme.colors.border};
`;
