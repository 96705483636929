// -- basic -- //
import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * techmeme_api
 *
 */

// techmeme api
export async function techmeme_api(options) {
  let query = '';

  if (options) {
    query = `?return_size=${options.return_size}`;
  }

  if (options.collection_date) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query
      ? `${query}&collection_date=${options.collection_date}`
      : `collection_date=${options.collection_date}`;
  }

  // console.log(query);

  try {
    const response = await fetch(`${BASE_URL}/techmeme/${query}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}
