import { Helmet } from 'react-helmet';

import News from '../../components/detailPage/news/News.js';
import FieldKeywords from '../../components/detailPage/news_keyword/FieldKeywords.js';
import Invests from '../../components/detailPage/invests/Invests.js';
import Laws from '../../components/detailPage/law/Laws.js';
import SocialTrend from '../../components/detailPage/socialTrend/SocialTrend.js';
import Patent from '../../components/detailPage/patent/Patent.js';
import Stock from '../../components/detailPage/stock/Stock.js';
import Market from '../../components/detailPage/market/Market.js';
import Reports from '../../components/detailPage/report/Reports.js';
import Legislation from '../../components/detailPage/legislation/Legislation.js';
import HomeBtn from '../../components/common/HomeBtn.js';

// css
import { ParentSize } from '@visx/responsive';
import '../../styles/DetailPage.css';

function DetailPage() {
  return (
    <>
      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>Rainbowbrain Trend Sensing Board</title>
      </Helmet>

      <div className="dashboard">
        <div className="box_wrap">
          <div className="box">
            <News />
          </div>
          <div className="box">
            <ParentSize>
              {({ width, height }) => <FieldKeywords width={width} height={height}></FieldKeywords>}
            </ParentSize>
          </div>
          <div className="box">
            <Market />
          </div>
          <div className="box">
            <Stock />
          </div>
          <div className="box">
            <Laws />
          </div>
          <div className="box">
            <Legislation />
          </div>
          <div className="box">
            <Patent />
          </div>
          <div className="box">
            <Invests />
          </div>
          <div className="box">
            <Reports />
          </div>
          <div className="box">
            <SocialTrend />
          </div>
        </div>
        <HomeBtn />
      </div>
    </>
  );
}

export default DetailPage;
