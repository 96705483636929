// -- basic -- //
import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * latest_keyword_api
 *
 */

//get keywords
export async function latest_keywords_api(options) {
  let query = null;

  // 구분
  if (options.user_email) {
    query = `user_email=${options.user_email}`;
  }

  // 키워드
  if (options.division) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&division=${options.division}` : `division=${options.division}`;
  }

  try {
    const response = await fetch(`${BASE_URL}/latest_keywords/?${query}`);
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}

// insert latest_keywords
export async function insert_latest_keywords_api(options) {
  const response = await fetch(`${BASE_URL}/latest_keywords/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  });

  if (!response.ok) {
    throw new Error('로그인 실패했습니다.');
  }
  const body = await response.json();
  return body;
}

//delte latest_keyword /latest_keywords/delete/:id
export async function delete_latest_keyword_api(options) {
  // console.log('id:: ', options.id);
  // console.log('id:: ', options.user_email);
  // console.log('id:: ', options.division);
  let query = null;
  if (options.user_email) {
    query = `?user_email=${options.user_email}`;
  }
  if (options.division) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&division=${options.division}` : `?division=${options.division}`;
  }

  const response = await fetch(`${BASE_URL}/latest_keywords/delete/${options.id}${query}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    // body: JSON.stringify(user_email),
  });

  if (!response.ok) {
    throw new Error('항목 삭제에 실패했습니다.');
  }

  const body = await response.json();
  return body;
}
