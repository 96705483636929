import { createChatBotMessage } from 'react-chatbot-kit';
import QuestionBtn01 from '../components/homePage/widgets/QuestionBtn01';
import QuestionBtn02 from '../components/homePage/widgets/QuestionBtn02';
import QuestionBtn03 from '../components/homePage/widgets/QuestionBtn03';
import QuestionBtn04 from '../components/homePage/widgets/QuestionBtn04';
import QuestionBtn05 from '../components/homePage/widgets/QuestionBtn05';
import QuestionBtn06 from '../components/homePage/widgets/QuestionBtn06';
import QuestionBtn07 from '../components/homePage/widgets/QuestionBtn07';
import CoBotAvatar from './CoBotAvatar';

// console.log('chatbotConfig');

const config = {
  lang: 'no',
  botName: 'CoBot',
  customStyles: {
    botMessageBox: {
      backgroundColor: '#04668a',
    },
    chatButton: {
      backgroundColor: '#0f5faf',
    },
  },
  initialMessages: [
    // 처음 인사 말.
    createChatBotMessage(
      `안녕하세요? Trend Sensing Board입니다.

      맞춤 기준으로 여러 출처의 다양한 정보를 한눈에 볼 수 있도록 제공하는 서비스입니다.
      우측 상단의 아이콘을 클릭하여 [설정 - TSB 설정]에서 맞춤 기준을 설정하시기 바랍니다.
      
      더 궁금한 점이 있습니까?`,
      {
        withAvatar: true,
        delay: 400,
        widget: 'questionBtn01',
      },
    ),
    // createChatBotMessage('', {
    //   withAvatar: false,
    //   delay: 400,
    //   widget: 'overview',
    // }),
  ],
  state: {},
  customComponents: { botAvatar: props => <CoBotAvatar {...props} /> },
  widgets: [
    {
      widgetName: 'questionBtn01',
      widgetFunc: props => <QuestionBtn01 {...props} />,
      mapStateToProps: ['messages'],
    },
    {
      widgetName: 'questionBtn02',
      widgetFunc: props => <QuestionBtn02 {...props} />,
    },
    {
      widgetName: 'questionBtn03',
      widgetFunc: props => <QuestionBtn03 {...props} />,
    },
    {
      widgetName: 'questionBtn04',
      widgetFunc: props => <QuestionBtn04 {...props} />,
    },
    {
      widgetName: 'questionBtn05',
      widgetFunc: props => <QuestionBtn05 {...props} />,
    },
    {
      widgetName: 'questionBtn06',
      widgetFunc: props => <QuestionBtn06 {...props} />,
    },
    {
      widgetName: 'questionBtn07',
      widgetFunc: props => <QuestionBtn07 {...props} />,
    },
  ],
};

export default config;
