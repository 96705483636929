//basic
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

//api
import { field_keywords_api } from '../../../../apis/field_keywords_api.js';

//external library
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';

//components
import { formatDateToYYYYmmdd } from '../../../../utils/dateFunction.js';

// contexts
import { useAuth } from '../../../../contexts/AuthProvider.js';

//css
import '../../../../styles/Reset.css';
import '../../../../styles/Common.css';
import '../../../../styles/FieldKeywords.css';

/* ───────────────────────────────────────────────────────────────
 * FieldKeywords : 키워드 분류 화면 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function FieldKeywords(selectedRegion) {
  const { user } = useAuth();
  const user_email = user.result.user_email || '';

  const today = formatDateToYYYYmmdd(new Date());
  const [fieldData, setFieldData] = useState([]);
  const [keywordList, setKeywordList] = useState([]);

  const division = selectedRegion.selectedRegion;

  // console.log(selectedRegion.selectedRegion);
  // console.log(division);

  const newColor = '#1EB3B8';
  const colors = ['#2C959C', '#5CCBA3', '#96E587', '#81DBD1', newColor];
  const spiralType = 'archimedean'; //archimedean, rectangular
  const withRotation = false;

  const fontSizeSetter = datum => fontScale(datum.value);
  const fixedValueGenerator = () => 0.5;

  const fontScale = scaleLog({
    domain: [Math.min(...fieldData.map(w => w.value)), Math.max(...fieldData.map(w => w.value))],
    range: [10, 50],
  });

  function getRotationDegree() {
    const rand = Math.random();
    const degree = rand > 0.5 ? 60 : -60;
    return rand * degree;
  }

  const handleLoad = useCallback(async () => {
    try {
      const keyOptions = {
        collection_date: today,
        division: division,
        user_email: user_email,
      };

      // console.log(keyOptions);

      const result = await field_keywords_api(keyOptions);

      const updatedData = result.map(item => ({
        text: item.keyword,
        value: item.count,
      }));

      setFieldData(updatedData);
      setKeywordList(result);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }, [today, division, user_email]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  const sortedKeywordList = keywordList
    .sort((a, b) => {
      if (b.count !== a.count) {
        return b.count - a.count;
      }
      return a.keyword.localeCompare(b.keyword);
    })
    .filter((keyword, index, self) => {
      return index === self.findIndex(k => k.keyword === keyword.keyword);
    });

  return (
    <>
      <div className="wordcloud">
        {fieldData && (
          <Wordcloud
            words={fieldData}
            width={300}
            height={180}
            fontSize={fontSizeSetter}
            font="Impact"
            padding={3}
            spiral={spiralType}
            rotate={withRotation ? getRotationDegree : 0}
            random={fixedValueGenerator}
          >
            {cloudWords =>
              cloudWords.map((w, i) => (
                <Text
                  key={i}
                  className={`word size-${w.size}`}
                  fill={colors[i % colors.length]}
                  textAnchor="middle"
                  transform={`translate(${w.x * 0.8}, ${w.y * 0.8}) rotate(${w.rotate})`}
                  fontSize={w.size * 0.8}
                >
                  {w.text}
                </Text>
              ))
            }
          </Wordcloud>
        )}
      </div>

      <div className="home_keyword_title d-between body3">
        <ContTitle className="keyword_title d-between">
          <div className="d-flex">
            <Title>순위</Title>
            <Title style={{ paddingLeft: '5px' }}>키워드</Title>
          </div>
          <Title>노출수</Title>
        </ContTitle>
        <ContTitle className="keyword_title d-between">
          <div className="d-flex">
            <Title>순위</Title>
            <Title style={{ paddingLeft: '5px' }}>키워드</Title>
          </div>
          <Title>노출수</Title>
        </ContTitle>
      </div>

      <div className="d-flex">
        <ul className="keyword_list">
          {sortedKeywordList.slice(0, 5).map((keyword, index) => (
            <li key={index} className="d-between">
              <div className="d-flex">
                <p className="num label2 d-center">{index + 1}</p>
                <Title className="body3">{keyword.keyword}</Title>
              </div>
              <p className="body3 count">{keyword.count}</p>
            </li>
          ))}
        </ul>
        <ul className="keyword_list">
          {sortedKeywordList.slice(5, 10).map((keyword, index) => (
            <li key={index} className="d-between">
              <div className="d-flex">
                <p className="num label2 d-center">{index + 6}</p>
                <Title className="body3">{keyword.keyword}</Title>
              </div>
              <p className="body3 count">{keyword.count}</p>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default FieldKeywords;

const Title = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;
