import { createContext, useContext, useEffect, useState } from 'react';
import { auth_token } from '../apis/auth_api';
import { Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/loginPage/LoginPage';
import SignUpPage from '../pages/signupPage/SignupPage';
import FindPassword from '../components/loginPage/FindPW';
import TsbIntroductionPage from '../pages/introductionPage/TsbIntroductionPage';

/* ───────────────────────────────────────────────────────────────
 * AuthProvider
 * 해당 Context 로 감싸져 있는 컴포넌트들은 모두 인가를 거쳐서 렌더링 되게끔.
 * 로그인 페이지는 별도로, 로그인 페이지에서 로그인 성공하면 Token을 우선 로컬스토리지에 저장하게 했음.(https로 변경하면 쿠키로 설정할 예정)
 *
 * 로컬 스토리지에서 토큰을 가져온 후 서버로 보내서 토큰 유효성을 확인하고, 유효한 경우 유저 정보를 받아와서 Context value 로 설정. (user)
 * 토큰이 유효 하지 않으면 로그인 페이지로 보냄.(로컬스토리지 토큰 삭제시킴)
 *
 * useAuth(커스텀훅) 을 사용하여 인증된 유저 정보데이터는 AuthProvider로 감싸져 있는 컴포넌트는 어디서든 꺼내서 사용할 수 있도록 함
 * ───────────────────────────────────────────────────────────────
 */

const AuthContext = createContext({ user: null });

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  // console.log(user);

  useEffect(() => {
    // 토큰 가져오기 (로컬스토리지)
    const token = localStorage.getItem('token');

    // 토큰이 있을 경우 토큰으로 유저 정보 가져오기.
    if (token) {
      async function getMe(token) {
        const options = {
          token: token,
        };

        try {
          const res = await auth_token(options);
          // console.log('auth_token api return :: ', res);
          if (!res) {
            console.log('사용자 조회 응답없으므로, 현재 토큰 삭제');
            localStorage.removeItem('token');
            return;
          }
          const userData = res;
          setUser(userData);
        } catch (error) {
          console.error('사용자 데이터를 가져오는 도중 에러가 발생했습니다.', error);
        }
      }

      getMe(token);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>
      {user !== null ? (
        children
      ) : (
        <Routes>
          <Route path="/" element={<TsbIntroductionPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<SignUpPage />} />
          <Route path="password" element={<FindPassword />} />
        </Routes>
      )}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('반드시 AuthProvider 안에서 사용해야 합니다.');
  }

  return context;
}
