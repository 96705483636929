//basic
import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import styled from 'styled-components';

//api
import { patent_api } from '../../../apis/patent_api.js';

//contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

//external library
import Tooltip from '../../common/Tooltip.js';
import RegionButtons from '../../common/RegionButtons.js';

// img
import plus from '../../../assets/plus.png';
import lockImg from '../../../assets/lock_img04.png';
import countryImages from '../../../utils/countryImg';

/* ───────────────────────────────────────────────────────────────
 * Patent
 * 특허 정보 화면 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Patent(editBtn) {
  const screen_edit = editBtn.editBtn;

  const { user } = useAuth();

  const tooltipValue =
    '<p>설정한 <span class="primary">키워드</span>에 따라 자동으로 수집된 특허 정보를 확인해보세요.<br />우측 상단 [아이콘]을 클릭하여 [설정 - 특허 검색 설정]에서 키워드를 설정해보세요.<br/>특허 정보 클릭 시 상세 정보를 제공하는 페이지가 나타납니다.<br/><br/><span class="primary">[정보 출처]</span><br/>- 특허 정보: 특허청 키프리스<br/><br/><span class="primary">[수집 시간]</span><br/>- 특허 정보: 00:00 ~ 05:00 수집</p>';

  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const [patentData, setPatentData] = useState(null);

  const patentKeywordsDomestic = `${user?.result?.patents_keywords_domestic || ''}`;
  const patentKeywordsOverseas = `${user?.result?.patents_keywords_overseas || ''}`;

  // -- 특허 클릭시 상세 페이지 열기 -- //
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('URL이 없습니다.');
    }
  };

  const handleRegionChange = region => {
    setSelectedRegion(region);
  };

  // -- 같은 조건으로 뉴스, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    let options;
    if (selectedRegion === '국내') {
      options = { division: selectedRegion, keyword: patentKeywordsDomestic };
    } else {
      options = { division: selectedRegion, keyword: patentKeywordsOverseas };
    }

    try {
      const [patentResult] = await Promise.all([patent_api(options)]);
      setPatentData(patentResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, patentKeywordsDomestic, patentKeywordsOverseas]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleContactButtonClick = () => {
    scroll.scrollTo(3562, {
      //스크롤값
      duration: 100,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 0,
      top: 0,
    });
  };

  const uniqueTitleMap = (patentData || []).reduce((accumulator, currentValue) => {
    accumulator[currentValue.title] = currentValue;
    return accumulator;
  }, {});

  // 중복이 제거된 객체들을 배열로 변환
  const uniqueName = Object.values(uniqueTitleMap);

  return (
    <Container className="Laws cont_wrap">
      <ContTitle className="cont_title d-between">
        {/* screen_edit 변수가 true 일때만 가려지는 css 적용 */}
        {screen_edit && <div className="arrange_wrap"></div>}

        <div className="d-flex">
          {/* 타이틀 */}
          <Text className="title3">특허 정보</Text>

          {/* 툴팁 */}
          <Tooltip data={tooltipValue} />
        </div>

        <div className="d-flex">
          {/* 국내/해외 */}
          <div className="sm_global_box">
            <RegionButtons
              options={regionOption}
              selectedRegion={selectedRegion}
              onRegionChange={handleRegionChange}
            />
          </div>

          {/* 더보기 버튼 */}
          <Link to="/detail">
            <PlusBtn className="plus_btn label4" onClick={handleContactButtonClick}>
              더보기 <IconImg src={plus} alt="아이콘 이미지" />
            </PlusBtn>
          </Link>
        </div>
      </ContTitle>

      <div className="scroll" style={{ paddingTop: '5px' }}>
        {uniqueName && uniqueName.length > 0 ? (
          uniqueName.map((item, index) => {
            if (item.division === selectedRegion || selectedRegion === '') {
              return (
                <ContTitle
                  className="patents_text"
                  key={item.id}
                  onClick={() => detailed_page_load(item)}
                >
                  <ul className="d-flex">
                    <li>
                      <img
                        src={countryImages.find(img => img.alt === item.country)?.src}
                        alt={item.country}
                      />
                    </li>
                    <li>
                      <SubText className="label2">{item.title}</SubText>
                    </li>
                  </ul>
                </ContTitle>
              );
            }
            return null;
          })
        ) : (
          <div className="no-news">
            <img src={lockImg} alt="요약중 이미지" />
            <p className="label1">
              수집된 정보가 없습니다.
              <br />
              특허 키워드를 설정해주세요. <br />
              해당 설정로 수집된 정보가 없다면
              <br />
              다음 날 최신 정보를 수집하여 보여 드립니다.
            </p>
          </div>
        )}
      </div>
    </Container>
  );
}
export default Patent;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const SubText = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const PlusBtn = styled.button`
  background: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.subTitleColor};
`;

const IconImg = styled.img`
  filter: ${props => props.theme.imgFilter};
`;
