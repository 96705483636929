/* ───────────────────────────────────────────────────────────────
 *  InvestsKeyword : 투자 화면 주요 키워드 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

// basic
import React from 'react';
import styled from 'styled-components';

// img
import lockImg from '../../../assets/lock_img02.png';

const KeywordsList = ({ keywords }) => {
  const displayKeywordsAndRank = keywords => {
    if (!keywords) {
      return (
        <tr className="no-news" style={{ width: '160px', height: '250px' }}>
          <td>
            <img src={lockImg} alt="요약중 이미지" />
          </td>
          <td className="label1">
            투자 정보 키워드를
            <br />
            분석 중입니다.
          </td>
        </tr>
      );
    }

    const keywordArray = keywords.split(',');

    return (
      <>
        {Array.from({ length: 10 }).map((_, index) => (
          <tr key={index} className="d-flex" style={{ paddingTop: '7px' }}>
            <td className="num label2 d-center">{index + 1}</td>
            <SubText className="body3">
              {index < keywordArray.length ? keywordArray[index] : '-'}
            </SubText>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="Keywords_list">
      <table>
        <tbody>{displayKeywordsAndRank(keywords)}</tbody>
      </table>
    </div>
  );
};

export default KeywordsList;

const SubText = styled.td`
  color: ${props => props.theme.colors.subTitleColor};
`;