import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthProvider';

import { updateUserInfo } from '../../apis/users_api';

// component
import SettingPopup from './Popup';

//css
import '../../styles/Setting.css';

const CompanySetting = forwardRef((props, ref) => {
  const { user } = useAuth();
  const { id, user_company, collection_company, collection_company_info } = user.result;

  // popup
  const [companySubmit, setCompanySubmit] = useState(false);
  const [userError, setUserError] = useState(false);

  // 회사명(GPT질문첨부용)
  const [collectionCompany, setCollectionCompany] = useState(collection_company || user_company);

  // 회사 소개(GPT질문첨부용)
  const [collectionCompanyInfo, setCollectionCompanyInfo] = useState(collection_company_info);

  //─────────────────── 회사명(GPT질문첨부용) ───────────────────//

  const handleCollectionCompanyChange = event => {
    const CollectionCompany = event.target.value;
    setCollectionCompany(CollectionCompany);
  };

  //─────────────────── 회사 소개(GPT질문첨부용) ───────────────────//

  const handleCollectionCompanyInfoChange = event => {
    const CollectionCompanyInfo = event.target.value;
    setCollectionCompanyInfo(CollectionCompanyInfo);
  };

  // console.log(department);

  //──────────────────────────────────────//

  const handleEditBtnClick = async () => {
    const options = {
      collection_company: collectionCompany,
      collection_company_info: collectionCompanyInfo,
    };

    // 유저 정보 업데이트 성공여부에 따라 알림창 다르게 표시.
    try {
      await updateUserInfo(id, options);
      setCompanySubmit(true);
    } catch (error) {
      console.error('사용자 정보 업데이트 오류:', error);
      setUserError(true);
    }
  };

  const closePopup = () => {
    setCompanySubmit(false);
  };

  return (
    <Container className="setting_box" ref={newsRef => (ref.current[2] = newsRef)}>
      <>
        <Text className="heading1">회사 정보</Text>
        <Title className="body1">
          TSB의 맞춤 정보를 확인하기 위한 회사 정보를 입력하세요.
          <br />
          회사 정보가 없는 경우, 소속 회사 기준 정보를 자동으로 가져옵니다.
        </Title>

        <div className="keyword_box">
          <SubTitle className="label1">회사명</SubTitle>
          <Input
            id="collection_company"
            value={collectionCompany}
            onChange={handleCollectionCompanyChange}
          />
        </div>

        <div className="keyword_box">
          <SubTitle className="label1">회사 소개</SubTitle>
          <Textarea
            id="collection_company_info"
            value={collectionCompanyInfo}
            onChange={handleCollectionCompanyInfoChange}
            className="textarea"
          />
        </div>

        <div className="d-end">
          <Btn onClick={handleEditBtnClick} className="setting_btn label1">
            설정 저장
          </Btn>
        </div>
      </>
      {companySubmit && (
        <SettingPopup close={closePopup} header="입력하신 회사정보가 변경되었습니다." />
      )}
      {userError && (
        <SettingPopup close={closePopup} header="회사 정보 업데이트 중 오류가 발생했습니다." />
      )}
    </Container>
  );
});

export default CompanySetting;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
`;

const Text = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;
const Title = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const SubTitle = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Btn = styled.button`
  background: ${props => props.theme.colors.btnBg};
  color: ${props => props.theme.colors.bgColor};
`;

const Input = styled.input`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border: 1px solid ${props => props.theme.colors.navTitleColor};
`;

const Textarea = styled.textarea`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border: 1px solid ${props => props.theme.colors.navTitleColor};
`;
