import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

// compo
import SignUpPopup from '../settingPage/Popup.js';

// api
import { userSelectSub, userCreateSub } from '../../apis/users_api.js';

function UserAddModal({ admin_id, onClose }) {
  // 회원가입 버튼 활성 체크
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 팝업 형태
  const [failedPopup, setFailedPopup] = useState(false);
  const [signUpPopup, setSignUpPopUp] = useState(false);
  const [idDoubleCheck, setIdDoubleCheck] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);

  // 체크 옵션 = 0: 중복or사용불가, 1:사용가능, 2:기본, 3:경고1, 4:경고2.
  const checkOption = useMemo(() => [0, 1, 2, 3, 4], []);

  // 이메일
  const [userEmail, setUserEmail] = useState('');
  const [userEmailCheck, setUserEmailCheck] = useState(checkOption[2]); // email 중복 체크
  const [userEmailValidState, setUserEmailValidState] = useState(checkOption[2]); // email 유효성

  // 유저 성명
  const [userName, setUserName] = useState('');

  // 유저 부서
  const [userDepartment, setUserDepartment] = useState('');

  const emailMessages = {
    0: '이미 등록된 이메일 주소입니다. 다시 입력해주시기 바랍니다.',
    1: '사용 가능한 이메일 형식 입니다.',
    2: 'abc@email.com 형식으로 작성 해 주세요.',
    3: '올바른 이메일 주소가 아닙니다. 이메일 주소를 확인해 주세요.',
    4: '이메일 주소를 입력하지 않으셨습니다. 이메일 주소를 입력해주시기 바랍니다.',
  };

  //───────────────────────────────────────────────────[ ▲ 기본 변수 선언 ]───────────────────────────────────────────────────

  // 모달 창 닫기 함수
  const handleCloseBtn = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  // 이메일 유효성 검사 함수
  const isEmailValid = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // 입력된 이메일 변수설정 및 유효성 검사 함수, true or false
  const handleEmailChange = e => {
    const newEmail = e.target.value;

    setUserEmail(e.target.value);

    if (!newEmail) {
      setUserEmailValidState(checkOption[4]);
      return;
    }

    // 입력된 이메일 유효성 체크
    setUserEmailValidState(isEmailValid(newEmail) ? checkOption[1] : checkOption[3]); // 1 : 사용가능형식, 3 : 올바른형식 X
  };

  // email 중복확인 함수
  const checkDuplicate = async e => {
    const inputName = e.target.name; // email or username

    const options = {
      input_name: inputName,
      user_email: userEmail,
      user_department: userDepartment,
    };

    try {
      const users = await userSelectSub(options);

      // getUsers 함수에서 반환된 결과(users)를 사용하여 중복 여부를 판단
      if (users.length > 0) {
        // 중복된 경우 처리
        if (inputName === 'email') {
          setUserEmailValidState(checkOption[0]); // 이메일 중복 체크.
        }

        alert(`사용 불가능한 ${inputName} 입니다.`);
      } else {
        // 중복되지 않은 경우 처리
        if (inputName === 'email') {
          setUserEmailCheck(checkOption[1]); // 이메일 사용가능 체크.
        }
        alert(`사용 가능한 ${inputName} 입니다.`);
      }
    } catch (error) {
      console.error('중복확인 에러:', error);
    }
  };

  // 추가 버튼 클릭시 실행되는 함수.
  const handleSubmit = async () => {
    // 버튼을 비활성화 상태로 변경
    setIsSubmitting(true);

    // 필수 항목 누락되면 알림창 발생.
    if (!userEmail || !userName) {
      // 버튼을 활성화 상태로 변경
      setIsSubmitting(false);
      setFailedPopup(true);
      return;
    }

    // 이메일 중복확인 안했으면 알림창 발생.
    if (userEmailCheck !== 1) {
      // 버튼을 활성화 상태로 변경
      setIsSubmitting(false);
      setIdDoubleCheck(true);
      return;
    }

    const uploadData = {
      admin_id: admin_id, // 관리자 계정
      user_email: userEmail, // 유저 이메일
      user_name: userName, // 유저 명
      user_department: userDepartment, // 유저 부서
      user_role: '사용자', // 권한은 사용자 고정
    };

    console.log(uploadData);

    try {
      await userCreateSub(uploadData);
      // onSuccess();

      setSignUpPopUp(true);
    } catch (error) {
      // 에러 로그 설정
      console.error('사용자 추가 에러:', error);

      // 에러가 발생한 경우에 대한 알림창을 표시
      setErrorPopup(true);
    } finally {
      // 버튼을 활성화 상태로 변경
      setIsSubmitting(false);
    }
  };

  const closePopup = () => {
    setSignUpPopUp(false);
    setFailedPopup(false);
    setIdDoubleCheck(false);
    setErrorPopup(false);
  };

  const handleClick = () => {
    // 페이지 맨 위로 스크롤
    window.scrollTo(0, 0);

    window.location.reload();
  };

  return (
    <div className="userAdd_wrap">
      {/* 닫기 */}
      <div className="d-end" onClick={handleCloseBtn} style={{ cursor: 'pointer' }}>
        ✖
      </div>

      {/* 이메일 계정 */}
      <div className="form_box">
        <label className="label1">
          이메일 계정 <span>*</span>
        </label>
        <div className="d-flex">
          <input
            type="email"
            name="email"
            className="email"
            value={userEmail}
            onChange={handleEmailChange}
          />
          <button
            name="email"
            onClick={checkDuplicate}
            // disabled={isEmailValidState !== 1}
            className="btn"
          >
            중복확인
          </button>
        </div>
        <p className="body3">{emailMessages[userEmailValidState]}</p>
      </div>

      {/* 성명 */}
      <div className="form_box">
        <label className="label1">
          성명 <span>*</span>
        </label>
        <input type="text" value={userName} onChange={e => setUserName(e.target.value)} />
      </div>

      {/* 소속 부서 */}
      <div className="form_box">
        <label className="label1">
          소속 부서 <span>*</span>
        </label>
        <input
          type="text"
          value={userDepartment}
          onChange={e => setUserDepartment(e.target.value)}
        />
      </div>

      <div className="d-end">
        {/* 취소 버튼 */}
        <button onClick={handleCloseBtn} className="btn label1">
          취소
        </button>
        {/* 추가 버튼 */}
        <button
          onClick={handleSubmit}
          className={`btn label1 ${isSubmitting ? 'disabled' : ''}`}
          disabled={isSubmitting}
        >
          추가
        </button>
      </div>

      <Link to="/user_management" onClick={handleClick}>
        {signUpPopup && (
          <SignUpPopup
            close={closePopup}
            title="사용자가 추가 되었습니다.🎉"
            header="회원님이 등록하신 이메일계정으로 비밀번호 메일이 발송되었습니다."
            body="이메일로 비밀번호를 확인 바랍니다."
          />
        )}
      </Link>
      {failedPopup && (
        <SignUpPopup
          close={closePopup}
          title="필수 항목을 입력하지 않으셨습니다."
          header="모두 입력한 후에 다시 시도해 주시기 바랍니다."
        />
      )}
      {idDoubleCheck && <SignUpPopup close={closePopup} header="이메일 중복 확인을 해 주세요." />}
      {errorPopup && (
        <SignUpPopup
          close={closePopup}
          header="사용자 추가 중 에러가 발생했습니다."
          body="잠시 후 다시 시도해주세요."
        />
      )}
    </div>
  );
}

export default UserAddModal;
