import { BASE_URL } from '../config/base_url';
/* ─────────────────────────────────────────────────────────────
 * tsb_setting_api
    셋팅페이지 공통 api
 * ─────────────────────────────────────────────────────────────
 */

// 뉴스
export async function news_keywords(type, keywordValue, id, removedKeyword, aiChange) {
  const token = localStorage.getItem('token');

  let bodyContent = {};
  type === 'domestic'
    ? (bodyContent = { news_keywords_domestic: keywordValue })
    : (bodyContent = { news_keywords_overseas: keywordValue });

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  let query;

  if (aiChange) {
    query = 'user-update-ai';
  } else {
    query = 'user-update';
  }

  // console.log(bodyContent);

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/${query}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    // console.log(response);

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

//Market
export async function market_update(options = {}) {
  const { id, keywordValue, removedKeyword } = options;
  const token = localStorage.getItem('token');
  let bodyContent = {};
  bodyContent = { market_keywords: keywordValue };

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

//Invest
export async function invest_update(options = {}) {
  const { id, keywordValue, removedKeyword } = options;
  const token = localStorage.getItem('token');
  let bodyContent = {};
  bodyContent = { invests_keywords: keywordValue };

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

// 법규
export async function laws_keywords(type, keywordValue, id, removedKeyword) {
  const token = localStorage.getItem('token');

  let bodyContent = {};
  type === 'domestic'
    ? (bodyContent = { laws_keywords_domestic: keywordValue })
    : (bodyContent = { laws_keywords_overseas: keywordValue });

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

// 특허
export async function patent_keywords(type, keywordValue, id, removedKeyword) {
  const token = localStorage.getItem('token');

  let bodyContent = {};
  type === 'domestic'
    ? (bodyContent = { patents_keywords_domestic: keywordValue })
    : (bodyContent = { patents_keywords_overseas: keywordValue });

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

//stock
export async function stock_update(options = {}) {
  const { id, keywordValue, removedKeyword } = options;
  const token = localStorage.getItem('token');
  let bodyContent = {};
  bodyContent = { stock_keywords: keywordValue };

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

// 행사
export async function event_update(keywordValue, id, removedKeyword) {
  const token = localStorage.getItem('token');
  let bodyContent = {};
  bodyContent = { event_keywords: keywordValue };

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

// 컴포넌트 렌더링 순서 업데이트
export async function screen_index(id, keywordValue, removedKeyword) {
  const token = localStorage.getItem('token');
  let bodyContent = {};
  bodyContent = { screen_index: keywordValue };

  // console.log(bodyContent);
  // console.log(id);

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    // console.log(body);
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

// 화면 onoff index 가져옴 - 사용안함(useAuth로 대체)
export async function get_screen_onoff_api(id, keywordValue, removedKeyword) {
  const token = localStorage.getItem('token');
  let bodyContent = {};
  bodyContent = { screen_index: keywordValue };

  // console.log(bodyContent);
  // console.log(id);

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    // console.log(body);
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

// 화면 onoff 저장
export async function update_screen_onoff_api(id, keywordValue, removedKeyword) {
  const token = localStorage.getItem('token');
  let bodyContent = {};
  let arrayToString;
  if (Array.isArray(keywordValue)) {
    if (keywordValue.length === 0) {
      console.log('배열이 비어있습니다.');
      arrayToString = '00001';
    } else {
      arrayToString = keywordValue.map(item => item).join(',');
    }
  } else {
    // console.log('배열이 아닙니다.');
  }

  bodyContent = { screen_onoff: arrayToString };

  // console.log(id);
  // console.log('bodyContent :: ', bodyContent);

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    // console.log('ddddddddddddddddd', body);
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

// 제외키워드
export async function except_keyword_update(keywordValue, id, removedKeyword) {
  const token = localStorage.getItem('token');
  let bodyContent = {};
  bodyContent = { except_keywords: keywordValue };

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}
