import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * legislation_api
 * 한국행정연구원 규제정보 데이터 (규제 정보) // 국내,해외
 * https://www.kipa.re.kr/site/kipa/research/selectPublishView.do?gubun=D1&pblcteId=PUBL_000000000000869
 * ─────────────────────────────────────────────────────────────
 */

export async function legislation_api(options) {
  let query = '';

  if (options) {
    if (options.division) {
      query += `?division=${options.division}`;
    }
    if (options.summary_date) {
      query += `&collection_date=${options.collection_date}`;
    }
  }

  try {
    const response = await fetch(`${BASE_URL}/legislation${query}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}

/* ─────────────────────────────────────────────────────────────
 * legislation_summary_api
 * 규제 정보 PDF파일을 GPT4.0에 질문하여 답변받은 요약 내용 데이터
 * ─────────────────────────────────────────────────────────────
 */

export async function legislation_summary_api(options) {
  let query = '';

  if (options) {
    if (options.division) {
      query += `?division=${options.division}`;
    }
    if (options.summary_date) {
      query += `&summary_date=${options.summary_date}`;
    }
  }
  try {
    const response = await fetch(`${BASE_URL}/legislation_summary${query}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}
