import { useAuth } from '../../contexts/AuthProvider';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useEffect, useState, useRef } from 'react';
import { DropDownContainer } from '../settingPage/StyleComponent';
import { toast } from 'react-toastify';

// api
import { news_keywords, except_keyword_update } from '../../apis/tsb_setting_api';
import { insert_latest_keywords_api } from '../../apis/latest_keywords_api';
import { delete_latest_keyword_api } from '../../apis/latest_keywords_api';
import { openai_api, openai_using_api } from '../../apis/openai_api';

//common
import { formatDateToYYmm } from '../../utils/dateFunction';

// component
import Tooltip from '../common/TooltipPage.js';
import KeywordPopup from './Popup';

/* ───────────────────────────────────────────────────────────────
 * NewsSetting
 * - 뉴스 키워드로 검색 후 설정 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */
const NewsSetting = forwardRef(({ newsDomestic, newsOverseas, newsExcept }, ref) => {
  //newsExcept

  const { user } = useAuth();
  const { id, user_email } = user.result;

  const dropdownRef = useRef(); //ul에 대한 ref
  const [isDomesticDropdownView, setDomesticDropdownView] = useState(false);
  const [isOverseasDropdownView, setOverseasDropdownView] = useState(false);
  const [isExceptDropdownView, setExceptDropdownView] = useState(false);

  const [domesticKeyword, setDomesticKeyword] = useState('');
  const [overseasKeyword, setOverseasKeyword] = useState('');
  const [exceptKeyword, setExceptKeyword] = useState('');

  const [tagKeyword, setTagKeyword] = useState({
    message: '',
    result: {
      news_keywords_domestic: '',
      news_keywords_overseas: '',
      except_keywords: '',
    },
  });

  const [limitKeywordsPopup, setLimitKeywordsPopup] = useState(false);
  const [limitKeywordLengthPopup, setLimitKeywordLengthPopup] = useState(false);
  const [blankKeywordPopup, setBlankKeywordsPopup] = useState(false);
  const [AiRecommendPopup, setAiRecommendPopup] = useState(false);
  const [AiRecommendPopup_overseas, setAiRecommendPopup_overseas] = useState(false);

  const { news_keywords_domestic, news_keywords_overseas } = tagKeyword.result;
  let { except_keywords } = tagKeyword.result;

  const [aiNewsKeywords_domestic, setAiNewsKeywords_domestic] = useState([]);
  const [aiNewsKeywords_overseas, setAiNewsKeywords_overseas] = useState([]);

  function chunkArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  const tooltipK = '<p>클릭 시 회사 정보를 바탕으로 AI가 추천한 키워드를 설정할 수 있습니다.</p>';
  const tooltipG = '<p>클릭 시 회사 정보를 바탕으로 AI가 추천한 키워드를 설정할 수 있습니다.</p>';

  //드롭다운용
  const [newsDomesticChunks, setNewsDomesticChunks] = useState('');
  const [newsOverseasChunks, setNewsOverseasChunks] = useState('');
  const [exceptChunks, setExceptChunks] = useState('');

  useEffect(() => {
    setNewsDomesticChunks(chunkArray(newsDomestic, 10));
  }, [newsDomestic]);

  useEffect(() => {
    setNewsOverseasChunks(chunkArray(newsOverseas, 10));
  }, [newsOverseas]);

  useEffect(() => {
    setExceptChunks(chunkArray(newsExcept, 10));
  }, [newsExcept]);

  // 인풋 창에 텍스트를 쓸 때마다 값이 저장됨
  const handleDomesticKeywordChange = e => {
    setDomesticKeyword(e.target.value);
  };
  const handleOverseasKeywordChange = e => {
    setOverseasKeyword(e.target.value);
  };

  const handleExceptKeywordChange = e => {
    setExceptKeyword(e.target.value);
  };

  const handleDomesticDropDownClick = clickedOption => {
    setDomesticKeyword(`${clickedOption.keyword}`);
  };

  const handleOverseasDropDownClick = clickedOption => {
    setOverseasKeyword(`${clickedOption.keyword}`);
  };

  const handleExceptDropDownClick = clickedOption => {
    setExceptKeyword(`${clickedOption.keyword}`);
  };

  // 등록버튼 클릭 시
  const handleRegisterDomestic = async () => {
    const regex = /^[a-zA-Z0-9ㄱ-ㅎ가-힣 ]+$/; //특수문자 변수

    // 입력값이 없으면 아무 동작 하지 않음
    if (domesticKeyword.trim() === '' || !regex.test(domesticKeyword)) {
      setBlankKeywordsPopup(true);
      return;
    }

    // 문자열 길이가 28보다 크면 알림창
    if (domesticKeyword.length > 28) {
      setLimitKeywordLengthPopup(true);
      return;
    }

    // 기존 키워드 갯수 확인
    const currentDomesticKeywords = news_keywords_domestic
      .split(',')
      .filter(keyword => keyword.trim() !== '');
    if (currentDomesticKeywords.length >= 5) {
      // 5개 이상이면 더 이상 등록하지 못하도록 막음
      setLimitKeywordsPopup(true);
      return;
    }
    try {
      const result = await news_keywords('domestic', domesticKeyword, id); //등록하는내용
      setTagKeyword(result);
      let params = { user_email: user_email, division: 'news_domestic', keyword: domesticKeyword };
      const reusdd = await insert_latest_keywords_api(params);
      setNewsDomesticChunks(chunkArray(reusdd, 10));
      toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
    } catch (error) {
      console.error('Error updating domestic keywords:', error);
    } finally {
      setDomesticKeyword('');
    }
  };

  const handleRegisterOverseas = async () => {
    const regex = /^[a-zA-Z0-9ㄱ-ㅎ가-힣 ]+$/; //특수문자 변수

    if (overseasKeyword.trim() === '' || !regex.test(overseasKeyword)) {
      setBlankKeywordsPopup(true);
      return;
    }

    // 문자열 길이가 28보다 크면 알림창
    if (overseasKeyword.length > 28) {
      setLimitKeywordLengthPopup(true);
      return;
    }
    const currentOverseasKeywords = news_keywords_overseas
      .split(',')
      .filter(keyword => keyword.trim() !== '');
    if (currentOverseasKeywords.length >= 5) {
      setLimitKeywordsPopup(true);
      return;
    }
    try {
      const result = await news_keywords('overseas', overseasKeyword, id);
      setTagKeyword(result);
      let params = { user_email: user_email, division: 'news_overseas', keyword: overseasKeyword };
      const saveOversea = await insert_latest_keywords_api(params);
      setNewsOverseasChunks(chunkArray(saveOversea, 10));
      toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
    } catch (error) {
      console.error('Error updating overseas keywords:', error);
    } finally {
      setOverseasKeyword('');
    }
  };

  const handleRegisterExcept = async () => {
    const regex = /^[a-zA-Z0-9ㄱ-ㅎ가-힣 ]+$/; //특수문자 변수

    if (exceptKeyword.trim() === '' || !regex.test(exceptKeyword)) {
      setBlankKeywordsPopup(true);
      return;
    }

    // 문자열 길이가 28보다 크면 알림창
    if (exceptKeyword.length > 28) {
      setLimitKeywordLengthPopup(true);
      return;
    }

    if (!except_keywords) {
      // except_keywords가 undefined이면 빈 문자열로 처리
      except_keywords = '';
    }

    const currentExceptKeywords = except_keywords
      .split(',')
      .filter(keyword => keyword.trim() !== '');
    if (currentExceptKeywords.length >= 5) {
      setLimitKeywordsPopup(true);
      return;
    }

    try {
      const result = await except_keyword_update(exceptKeyword, id);
      setTagKeyword(result);
      let params = { user_email: user_email, division: 'except', keyword: exceptKeyword };
      const saveExcept = await insert_latest_keywords_api(params);
      setExceptChunks(chunkArray(saveExcept, 10));
      toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
    } catch (error) {
      console.error('Error updating overseas keywords:', error);
    } finally {
      setExceptKeyword('');
    }
    // alert('기능 준비 중입니다.');
  };

  // Enter키 입력 감지
  const handleDomesticOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleRegisterDomestic();
      setDomesticDropdownView(false);
    }
  };

  const handleOverseasOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleRegisterOverseas();
      setOverseasDropdownView(false);
    }
  };

  const handleExceptOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleRegisterExcept();
      setExceptDropdownView(false);
    }
  };

  const closePopup = () => {
    setLimitKeywordsPopup(false);
    setLimitKeywordLengthPopup(false);
    setBlankKeywordsPopup(false);
    setAiRecommendPopup(false);
  };

  // 삭제 버튼 눌렀을때
  const handleRemove = async (e, keyword, location) => {
    const remove = e.target.value;
    const removedKeyword = keyword;

    try {
      const result = await news_keywords(location, removedKeyword, id, remove);
      setTagKeyword(result);
    } catch (error) {
      console.error('Error updating domestic keywords:', error);
    } finally {
      setDomesticKeyword('');
      setOverseasKeyword('');
    }
  };

  const handleExceptRemove = async (e, keyword, location) => {
    const remove = e.target.value;
    const removedKeyword = keyword;

    try {
      const result = await except_keyword_update(removedKeyword, id, remove);
      setTagKeyword(result);
    } catch (error) {
      console.error('Error updating domestic keywords:', error);
    } finally {
      setExceptKeyword('');
    }
  };

  // 키워드 삭제 버튼추가
  const renderKeywords = (keywords, location) => {
    return keywords.split(',').map((keyword, index, array) => {
      const handleClick = e => {
        handleRemove(e, keyword, location);
      };
      return (
        <span key={index} data-span-value={keyword}>
          {keyword}
          <button value="remove" onClick={e => handleClick(e, keyword)} className="remove"></button>
        </span>
      );
    });
  };

  const renderExceptKeywords = (keywords, location) => {
    if (!keywords) {
      return [];
    }
    return keywords.split(',').map((keyword, index, array) => {
      const handleClick = e => {
        handleExceptRemove(e, keyword, location);
      };
      return (
        <span key={index} data-span-value={keyword}>
          {keyword}
          <button value="remove" onClick={e => handleClick(e, keyword)} className="remove"></button>
        </span>
      );
    });
  };

  const deleteDomesticKeyword = e => {
    const handleClick = async e => {
      let params = { user_email: user_email, division: 'news_domestic', id: e.id };
      const deletereturn = await delete_latest_keyword_api(params);
      setNewsDomesticChunks(chunkArray(deletereturn, 10));
    };

    // 키워드 등록일 표기용
    const date = formatDateToYYmm(new Date(e.created_at));

    return (
      <span key={e.index} data-span-value={e.keyword} className="keywords_drop_wrap">
        <div
          className="d-between"
          onClick={() => {
            handleDomesticDropDownClick(e);
          }}
        >
          <span>{e.keyword}</span>
          <span className="gray3">{date}</span>
        </div>
        <button value="remove" onClick={() => handleClick(e)} className="remove"></button>
        {/* {index < array.length - 1 && ','} */}
      </span>
    );
  };

  const deleteOverseasKeyword = e => {
    const handleClick = async e => {
      let params = { user_email: user_email, division: 'news_overseas', id: e.id };
      const deletereturn = await delete_latest_keyword_api(params);
      setNewsOverseasChunks(chunkArray(deletereturn, 10));
    };

    // 키워드 등록일 표기용
    const date = formatDateToYYmm(new Date(e.created_at));

    return (
      <span key={e.index} data-span-value={e.keyword} className="keywords_drop_wrap">
        <div
          className="d-between"
          onClick={() => {
            handleOverseasDropDownClick(e);
          }}
        >
          <span>{e.keyword}</span>
          <span className="gray3">{date}</span>
        </div>
        <button value="remove" onClick={() => handleClick(e)} className="remove"></button>
      </span>
    );
  };

  const deleteExceptKeyword = e => {
    const handleClick = async e => {
      let params = { user_email: user_email, division: 'except', id: e.id };
      const deletereturn = await delete_latest_keyword_api(params);
      setExceptChunks(chunkArray(deletereturn, 10));
    };

    // 키워드 등록일 표기용
    const date = formatDateToYYmm(new Date(e.created_at));

    return (
      <span key={e.index} data-span-value={e.keyword} className="keywords_drop_wrap">
        <div
          className="d-between"
          onClick={() => {
            deleteExceptKeyword(e);
          }}
        >
          <span>{e.keyword}</span>
          <span className="gray3">{date}</span>
        </div>
        <button value="remove" onClick={() => handleClick(e)} className="remove"></button>
      </span>
    );
  };

  const handleDomesticClickContainer = () => {
    setDomesticDropdownView(!isDomesticDropdownView);
  };

  const handleOverseasClickContainer = () => {
    setOverseasDropdownView(!isOverseasDropdownView);
  };

  const handleExceptClickContainer = () => {
    setExceptDropdownView(!isExceptDropdownView);
  };

  const handleDomesticBlurContainer = () => {
    setTimeout(() => {
      setDomesticDropdownView(false);
    }, 150);
  };

  const handleOverseasBlurContainer = () => {
    setTimeout(() => {
      setOverseasDropdownView(false);
    }, 150);
  };

  const handleExceptBlurContainer = () => {
    setTimeout(() => {
      setExceptDropdownView(false);
    }, 150);
  };

  // AI 추천 버튼 함수
  const handleAIRecommendation = async e => {
    // console.log('AI 추천 버튼');
    // console.log(e.target.name);

    const options = {
      user_email: user_email,
      type: 'news',
      division: e.target.name, // 국내 또는 해외임
    };

    const saveOptions = {
      user_email: user_email,
      using_location: e.target.name,
      using_time: new Date().toString(),
    };

    // gpt에게 추천 키워드를 리턴 받음
    const result = await openai_api(options);
    await openai_using_api(saveOptions);
    const keywordsArray = result.split(',').map(keyword => keyword.trim());

    if (e.target.name === 'news_domestic') {
      setAiNewsKeywords_domestic(keywordsArray);
      setAiRecommendPopup(true);
    } else {
      setAiNewsKeywords_overseas(keywordsArray);
      setAiRecommendPopup_overseas(true);
    }
    // console.log(result);
  };

  // AI 추천 키워드 일괄 적용 '네' 버튼 클릭 시 처리할 로직
  const handleAiYesClick = async e => {
    let aiChange = true;

    let keywordsString =
      e.target.name === 'news_domestic'
        ? aiNewsKeywords_domestic.join(', ')
        : aiNewsKeywords_overseas.join(', ');

    if (e.target.name === 'news_domestic') {
      setAiRecommendPopup(false);
      try {
        // 국내 뉴스 키워드 등록하는내용,
        // aiChange = true로 넘어가면 기존 키워드 없어지고 ai가 추천한 키워드가 일괄 설정됨.
        const result = await news_keywords('domestic', keywordsString, id, '', aiChange);

        setTagKeyword(result);
        let params = {
          user_email: user_email,
          division: 'news_domestic',
          keyword: keywordsString,
        };
        const reusdd = await insert_latest_keywords_api(params);
        setNewsDomesticChunks(chunkArray(reusdd, 10));
        toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
      } catch (error) {
        console.error('Error updating domestic keywords:', error);
      } finally {
        setDomesticKeyword('');
        setAiNewsKeywords_domestic([]);
      }
    } else {
      setAiRecommendPopup_overseas(false);
      try {
        // 해외 뉴스 키워드 등록하는내용,
        // aiChange = true로 넘어가면 기존 키워드 없어지고 ai가 추천한 키워드가 일괄 설정됨.
        const result = await news_keywords('overseas', keywordsString, id, '', aiChange);

        setTagKeyword(result);
        let params = {
          user_email: user_email,
          division: 'news_overseas',
          keyword: keywordsString,
        };
        const reusdd = await insert_latest_keywords_api(params);
        setNewsOverseasChunks(chunkArray(reusdd, 10));
        toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
      } catch (error) {
        console.error('Error updating overseas keywords:', error);
      } finally {
        setOverseasKeyword('');
        setAiNewsKeywords_overseas([]);
      }
    }
  };

  // AI 추천 키워드 일괄 적용 '아니오' 버튼 클릭 시 처리할 로직
  const handleAiNoClick = () => {
    // console.log('아니오 버튼 클릭됨');
    setAiRecommendPopup(false);
    setAiRecommendPopup_overseas(false);
    setAiNewsKeywords_domestic([]);
    setAiNewsKeywords_overseas([]);
  };

  // 페이지가 로드될때 키워드 보이게
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await news_keywords('', '', id);
        setTagKeyword(result);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Container className="setting_box" ref={newsRef => (ref.current[4] = newsRef)}>
      <Text className="heading1">뉴스 검색 설정</Text>
      <Title className="body1">
        입력한 키워드(각각 최대 5개)와 관련된 뉴스가 Board화면에 나타납니다.
      </Title>

      <div className="keyword_box">
        <p className="label1" style={{ color: '#ff5757', fontSize: '16px' }}>
          제외 키워드
        </p>
        <div className="input_box">
          <Input
            type="text"
            placeholder="뉴스에서 제외 할 키워드가 있나요?"
            value={exceptKeyword}
            onClick={handleExceptClickContainer}
            onChange={handleExceptKeywordChange}
            onKeyPress={handleExceptOnKeyPress}
            onBlur={handleExceptBlurContainer}
          />
          <button onClick={handleRegisterExcept} className="remove btn">
            등록
          </button>
        </div>
        {isExceptDropdownView && (
          <DropDown
            ref={dropdownRef}
            options={exceptChunks[0]} //1은 중복제거된값들이 있음
            handleComboBox={handleExceptDropDownClick}
            deleteLatestKeyword={deleteExceptKeyword}
          />
        )}

        <span className="keywords">
          {except_keywords === ''
            ? '현재 등록된 키워드가 없습니다.'
            : renderExceptKeywords(except_keywords, 'except')}
        </span>
      </div>

      <div className="keyword_box">
        <div className="d-flex">
          <SubTitle className="label1" style={{ marginBottom: '6px' }}>
            국내 뉴스 키워드
          </SubTitle>
          <div className="Ai_recommend">
            <button onClick={handleAIRecommendation} className="setting_btn" name="news_domestic">
              AI 추천
            </button>
            <div className="tooltip_wrap tooltip_ai">
              <Tooltip data={tooltipK} />
            </div>
          </div>
        </div>

        <div className="input_box">
          <Input
            type="text"
            placeholder="국내 뉴스에서 찾아봐야 할 키워드가 있나요?"
            value={domesticKeyword}
            onClick={handleDomesticClickContainer}
            onChange={handleDomesticKeywordChange}
            onKeyPress={handleDomesticOnKeyPress}
            onBlur={handleDomesticBlurContainer}
          />
          <Btn onClick={handleRegisterDomestic} className="btn">
            등록
          </Btn>
        </div>
        {isDomesticDropdownView && (
          <DropDown
            ref={dropdownRef}
            options={newsDomesticChunks[0]} //1은 중복제거된값들이 있음
            handleComboBox={handleDomesticDropDownClick}
            deleteLatestKeyword={deleteDomesticKeyword}
          />
        )}

        <span className="keywords">
          {news_keywords_domestic === ''
            ? '현재 등록된 키워드가 없습니다.'
            : renderKeywords(news_keywords_domestic, 'domestic')}
        </span>
      </div>

      <div className="keyword_box">
        <div className="d-flex">
          <SubTitle className="label1" style={{ marginBottom: '0px' }}>
            해외 뉴스 키워드
          </SubTitle>
          <div className="Ai_recommend">
            <div className="tooltip_wrap tooltip_ai">
              <Tooltip data={tooltipG} />
            </div>
            <button onClick={handleAIRecommendation} className="setting_btn" name="news_overseas">
              AI 추천
            </button>
          </div>
        </div>
        <div className="input_box">
          <Input
            type="text"
            placeholder="해외 뉴스에서 찾아봐야 할 키워드가 있나요?"
            value={overseasKeyword}
            onClick={handleOverseasClickContainer}
            onChange={handleOverseasKeywordChange}
            onKeyPress={handleOverseasOnKeyPress}
            onBlur={handleOverseasBlurContainer}
          />
          <Btn onClick={handleRegisterOverseas} className="btn">
            등록
          </Btn>
        </div>
        {isOverseasDropdownView && (
          <DropDown
            ref={dropdownRef}
            options={newsOverseasChunks[0]}
            handleComboBox={handleOverseasDropDownClick}
            deleteLatestKeyword={deleteOverseasKeyword}
          />
        )}

        <span className="keywords">
          {news_keywords_overseas === ''
            ? '현재 등록된 키워드가 없습니다.'
            : renderKeywords(news_keywords_overseas, 'overseas')}
        </span>
      </div>
      {limitKeywordsPopup && (
        <KeywordPopup close={closePopup} header="최대 5개까지만 등록 가능합니다." />
      )}
      {blankKeywordPopup && (
        <KeywordPopup close={closePopup} header="특수문자를 제외한 키워드를 입력 해 주세요." />
      )}
      {limitKeywordLengthPopup && (
        <KeywordPopup close={closePopup} header="키워드는 28자 보다 크면 안됩니다." />
      )}
      {AiRecommendPopup && (
        <KeywordPopup
          close={handleAiYesClick}
          close_no={handleAiNoClick}
          name="news_domestic"
          no="아니오"
          confirmText="네"
          title="해당 키워드를 바로 추가 하시겠습니까?"
          header="해당 키워드는 일괄로 추가되며, 기존 설정된 키워드는 사라집니다."
          body={aiNewsKeywords_domestic.length > 0 ? aiNewsKeywords_domestic.join(', ') : null}
        />
      )}
      {AiRecommendPopup_overseas && (
        <KeywordPopup
          close={handleAiYesClick}
          close_no={handleAiNoClick}
          name="news_overseas"
          no="아니오"
          confirmText="네"
          title="해당 키워드를 바로 추가 하시겠습니까?"
          header="해당 키워드는 일괄로 추가되며, 기존 설정된 키워드는 사라집니다."
          body={aiNewsKeywords_overseas.length > 0 ? aiNewsKeywords_overseas.join(', ') : null}
        />
      )}
    </Container>
  );
});

export default NewsSetting;

export const DropDown = React.forwardRef(({ options, deleteLatestKeyword }, ref) => {
  if (!options || !Array.isArray(options) || options.length === 0) {
    return null; // 옵션이 없을 경우 컴포넌트를 숨깁니다.
  }

  return (
    <DropDownContainer ref={ref}>
      {options.map((option, index) => (
        <li key={index} className="news_setting_keywords">
          <div className="keywords">
            {option.keyword === '' ? '과거키워드없음' : deleteLatestKeyword(option)}
          </div>
        </li>
      ))}
    </DropDownContainer>
  );
});

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;
const Title = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const SubTitle = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Btn = styled.button`
  background: ${props => props.theme.colors.btnBg};
  color: ${props => props.theme.colors.bgColor};
`;

const Input = styled.input`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border: 1px solid ${props => props.theme.colors.navTitleColor};
`;
