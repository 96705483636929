// -- basic -- //
import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * setting_keyword
 * 유저별 세팅된 키워드 값 가져오는 API --- 토큰을 전달하여 유저 정보를 식별하고 가져오는 방식으로 수정 진행
 * ─────────────────────────────────────────────────────────────
 */

export async function setting_keyword(options) {
  const user_email = options.user_email;
  const query = `user_email=${user_email}`;

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-keywords/?${query}`);
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}

/* ─────────────────────────────────────────────────────────────
 * userSelect
 * 유저 정보 조회 API --- 회원 정보 찾기 등.. 사용할 예정
 * ─────────────────────────────────────────────────────────────
 */

export async function userSelect(options) {
  const { input_name, user_email, user_id } = options;

  // 쿼리 작성.
  let query;
  if (input_name === 'email') {
    query = `user_email=${user_email}`;
  } else if (input_name === 'name') {
    query = `user_id=${user_id}`;
  }

  const response = await fetch(`${BASE_URL}/tsb-user/user-select/?${query}`);

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error('회원 정보를 불러오는데 실패했습니다.');
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

/* ─────────────────────────────────────────────────────────────
 * userSelectSub
 * 유저 정보 조회 API --- 회원 정보 찾기 등.. 사용할 예정
 * ─────────────────────────────────────────────────────────────
 */

export async function userSelectSub(options) {
  const { input_name, user_email, user_id, admin_id } = options;

  // console.log(options);

  // 쿼리 작성.
  let query;
  if (admin_id) {
    query = `admin_id=${admin_id}`;
  }

  if (input_name === 'email') {
    query = `user_email=${user_email}`;
  } else if (input_name === 'name') {
    query = `user_id=${user_id}`;
  }

  const response = await fetch(`${BASE_URL}/tsb-user_sub/user-select/?${query}`);

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error('회원 정보를 불러오는데 실패했습니다.');
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

/* ─────────────────────────────────────────────────────────────
 * userCreate
 * 유저 생성 API -- 회원가입 사용
 * ─────────────────────────────────────────────────────────────
 */

export async function userCreate(uploadData) {
  // console.log(formData);

  const response = await fetch(`${BASE_URL}/tsb-user/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uploadData),
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error('회원을 생성하는데 실패했습니다.');
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

/* ─────────────────────────────────────────────────────────────
 * userCreateSub
 * 유저 생성 API -- 관리자 계정에서 부계정 생성시 사용하는 API
 * ─────────────────────────────────────────────────────────────
 */

export async function userCreateSub(uploadData) {
  console.log(uploadData);

  const response = await fetch(`${BASE_URL}/tsb-user_sub/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uploadData),
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error('회원을 생성하는데 실패했습니다.');
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

/* ─────────────────────────────────────────────────────────────
 * userDeleteSub
 * 유저 삭제 API -- 관리자 계정에서 부계정 생성시 사용하는 API
 * ─────────────────────────────────────────────────────────────
 */

export async function userDeleteSub(uploadData) {
  console.log(uploadData);

  const response = await fetch(`${BASE_URL}/tsb-user_sub/user-delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uploadData),
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error('회원을 삭제하는데 실패했습니다.');
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

/* ─────────────────────────────────────────────────────────────
 * login
 * 로그인 API
 * ─────────────────────────────────────────────────────────────
 */

export async function login(options) {
  // console.log(options);

  const response = await fetch(`${BASE_URL}/tsb-login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  });

  // response 응답 체크 후 에러 발생로직
  // console.log('응답::', response);
  if (!response.ok) {
    throw new Error('로그인 실패했습니다.');
  }
  const body = await response.json();
  console.log('로그인확인바디 :: ', body);
  return body;
}

/* ─────────────────────────────────────────────────────────────
 * TSB설정 페이지 개인정보 업데이트
 * 키워드 부분 아님X, 유저 정보만
 * ─────────────────────────────────────────────────────────────
 */

export async function updateUserInfo(id, options) {
  console.log('업데이트api:: ', options);
  try {
    let path = '';
    if (options.user_role === '사용자') {
      path = 'tsb-user_sub/user-update-info';
    } else {
      path = 'tsb-user/user-update-info';
    }

    const response = await fetch(`${BASE_URL}/${path}/${id}`, {
      method: 'PUT', // 또는 'PATCH'에 따라서 사용
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    if (response.ok) {
      // 서버에서 업데이트 된 사용자 정보를 받아와서 상태 업데이트
      const updatedUser = await response.json();
      console.log('update success :: ', updatedUser);
    } else {
      console.error('업데이트 실패:', response.statusText);
    }
  } catch (error) {
    console.error('업데이트 에러 발생:', error);
  }
}

/* ─────────────────────────────────────────────────────────────
 * 사용자 관리 페이지
 * 비밀번호 초기화 눌러서 임시 비번 발급하는 API
 * ─────────────────────────────────────────────────────────────
 */

export async function updateUserPwInfo(id, options) {
  // console.log(options.user_role);
  // console.log(id);
  try {
    let path = '';
    if (options.user_role === '관리자') {
      path = 'tsb-user_sub/user-update-temp-pw';
    }

    const response = await fetch(`${BASE_URL}/${path}/${id}`, {
      method: 'PUT', // 또는 'PATCH'에 따라서 사용
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    if (response.ok) {
      // 서버에서 업데이트 된 사용자 정보를 받아와서 상태 업데이트
      const updatedUser = await response.json();
      console.log('update success :: ', updatedUser);
    } else {
      console.error('업데이트 실패:', response.statusText);
    }
  } catch (error) {
    console.error('업데이트 에러 발생:', error);
  }
}

/* ─────────────────────────────────────────────────────────────
 * update 된 이미지BLOB  - 실패로인해 사용안함. 시간없어서패배함.
 * get img API
 * ─────────────────────────────────────────────────────────────
 */
export async function getImgUrl(options) {
  const user_email = options;
  // console.log('유저이메일 :: ', user_email);

  try {
    // const param = { user_email: user_email };

    const url = new URL(`${BASE_URL}/tsb-user/getimgblob`);
    url.searchParams.append('user_email', user_email);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}
