import { useAuth } from '../../contexts/AuthProvider';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useEffect, useState, useRef } from 'react';
import { DropDownContainer } from '../settingPage/StyleComponent';
import { toast } from 'react-toastify';

// api
import { laws_keywords } from '../../apis/tsb_setting_api';
import { insert_latest_keywords_api } from '../../apis/latest_keywords_api';
import { delete_latest_keyword_api } from '../../apis/latest_keywords_api';

//common
import { formatDateToYYmm } from '../../utils/dateFunction';

// component
import KeywordPopup from './Popup';

/* ───────────────────────────────────────────────────────────────
 * LawsSetting
 * - 법규 키워드로 검색 후 설정 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const LawsSetting = forwardRef(({ lawsDomestic, lawsOverseas }, ref) => {
  const { user } = useAuth();
  const { id, user_email } = user.result;

  const dropdownRef = useRef(); //ul에 대한 ref
  const [isDomesticDropdownView, setDomesticDropdownView] = useState(false);
  const [isOverseasDropdownView, setOverseasDropdownView] = useState(false);

  const [domesticKeyword, setDomesticKeyword] = useState('');
  const [overseasKeyword, setOverseasKeyword] = useState('');

  const [tagKeyword, setTagKeyword] = useState({
    message: '',
    result: {
      laws_keywords_domestic: '',
      laws_keywords_overseas: '',
    },
  });

  const [limitKeywordsPopup, setLimitKeywordsPopup] = useState(false);
  const [blankKeywordPopup, setBlankKeywordsPopup] = useState(false);

  const { laws_keywords_domestic, laws_keywords_overseas } = tagKeyword.result;

  function chunkArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  // const lawsDomesticChunks = chunkArray(lawsDomestic, 5);
  // const lawsOverseasChunks = chunkArray(lawsOverseas, 5);
  //드롭다운용
  const [lawsDomesticChunks, setLawsDomesticChunks] = useState('');
  const [lawsOverseasChunks, setLawsOverseasChunks] = useState('');
  useEffect(() => {
    setLawsDomesticChunks(chunkArray(lawsDomestic, 10));
  }, [lawsDomestic]);

  useEffect(() => {
    setLawsOverseasChunks(chunkArray(lawsOverseas, 10));
  }, [lawsOverseas]);

  // 인풋 창에 텍스트를 쓸 때마다 값이 저장됨
  const handleDomesticKeywordChange = e => {
    setDomesticKeyword(e.target.value);
  };
  const handleOverseasKeywordChange = e => {
    setOverseasKeyword(e.target.value);
  };

  const handleDomesticDropDownClick = clickedOption => {
    setDomesticKeyword(`${clickedOption.keyword}`);
  };

  const handleOverseasDropDownClick = clickedOption => {
    setOverseasKeyword(`${clickedOption.keyword}`);
  };

  // 등록버튼 클릭 시
  const handleRegisterDomestic = async () => {
    const regex = /^[a-zA-Z0-9ㄱ-ㅎ가-힣]*$/; //특수문자 변수
    // 입력값이 없으면 아무 동작 하지 않음
    if (domesticKeyword.trim() === '' || !regex.test(domesticKeyword)) {
      setBlankKeywordsPopup(true);
      return;
    }
    // 기존 키워드 갯수 확인
    const currentDomesticKeywords = laws_keywords_domestic
      .split(',')
      .filter(keyword => keyword.trim() !== '');
    if (currentDomesticKeywords.length >= 5) {
      // 5개 이상이면 더 이상 등록하지 못하도록 막음
      setLimitKeywordsPopup(true);
      return;
    }
    try {
      const result = await laws_keywords('domestic', domesticKeyword, id);
      setTagKeyword(result);
      let params = { user_email: user_email, division: 'laws_domestic', keyword: domesticKeyword };
      const insertLaws = await insert_latest_keywords_api(params);
      setLawsDomesticChunks(chunkArray(insertLaws, 10));
      toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
    } catch (error) {
      console.error('Error updating domestic keywords:', error);
    } finally {
      setDomesticKeyword('');
    }
  };

  const handleRegisterOverseas = async () => {
    const regex = /^[a-zA-Z0-9ㄱ-ㅎ가-힣]*$/; //특수문자 변수
    if (overseasKeyword.trim() === '' || !regex.test(overseasKeyword)) {
      setBlankKeywordsPopup(true);
      return;
    }
    const currentOverseasKeywords = laws_keywords_overseas
      .split(',')
      .filter(keyword => keyword.trim() !== '');
    if (currentOverseasKeywords.length >= 5) {
      setLimitKeywordsPopup(true);
      return;
    }
    try {
      const result = await laws_keywords('overseas', overseasKeyword, id);
      setTagKeyword(result);
      let params = { user_email: user_email, division: 'laws_overseas', keyword: overseasKeyword };
      const insertLawOversea = await insert_latest_keywords_api(params);
      setLawsOverseasChunks(chunkArray(insertLawOversea, 10));
      toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
    } catch (error) {
      console.error('Error updating overseas keywords:', error);
    } finally {
      setOverseasKeyword('');
    }
  };

  // Enter키 입력 감지
  const handleDomesticOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleRegisterDomestic();
      setDomesticDropdownView(false);
    }
  };

  const handleOverseasOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleRegisterOverseas();
      setOverseasDropdownView(false);
    }
  };

  const closePopup = () => {
    setLimitKeywordsPopup(false);
    setBlankKeywordsPopup(false);
  };

  // 삭제 버튼 눌렀을때
  const handleRemove = async (e, keyword, location) => {
    const remove = e.target.value;
    const removedKeyword = keyword;

    try {
      const result = await laws_keywords(location, removedKeyword, id, remove);
      setTagKeyword(result);
    } catch (error) {
      console.error('Error updating domestic keywords:', error);
    } finally {
      setDomesticKeyword('');
      setOverseasKeyword('');
    }
  };

  // 키워드 삭제 버튼추가
  const renderKeywords = (keywords, location) => {
    return keywords.split(',').map((keyword, index, array) => {
      const handleClick = e => {
        handleRemove(e, keyword, location);
      };
      return (
        <span key={index} data-span-value={keyword}>
          {keyword}
          <button value="remove" onClick={e => handleClick(e, keyword)} className="remove"></button>
        </span>
      );
    });
  };

  const deleteDomesticKeyword = e => {
    const handleClick = async e => {
      // console.log(e.keyword, e.id);
      let params = { user_email: user_email, division: 'laws_domestic', id: e.id };
      const deletereturn = await delete_latest_keyword_api(params);
      setLawsDomesticChunks(chunkArray(deletereturn, 10));
    };

    // 키워드 등록일 표기용
    const date = formatDateToYYmm(new Date(e.created_at));

    return (
      <span key={e.index} data-span-value={e.keyword} className="keywords_drop_wrap">
        <div
          className="d-between"
          onClick={() => {
            handleDomesticDropDownClick(e);
          }}
        >
          <span>{e.keyword}</span>
          <span className="gray3">{date}</span>
        </div>
        <button value="remove" onClick={() => handleClick(e)} className="remove"></button>
        {/* {index < array.length - 1 && ','} */}
      </span>
    );
  };

  const deleteOverseasKeyword = e => {
    const handleClick = async e => {
      let params = { user_email: user_email, division: 'laws_overseas', id: e.id };
      const deletereturn = await delete_latest_keyword_api(params);
      setLawsOverseasChunks(chunkArray(deletereturn, 10));
    };

    // 키워드 등록일 표기용
    const date = formatDateToYYmm(new Date(e.created_at));

    return (
      <span key={e.index} data-span-value={e.keyword} className="keywords_drop_wrap">
        <div
          className="d-between"
          onClick={() => {
            handleOverseasDropDownClick(e);
          }}
        >
          <span>{e.keyword}</span>
          <span className="gray3">{date}</span>
        </div>
        <button value="remove" onClick={() => handleClick(e)} className="remove"></button>
      </span>
    );
  };

  const handleDomesticClickContainer = () => {
    setDomesticDropdownView(!isDomesticDropdownView);
  };

  const handleOverseasClickContainer = () => {
    setOverseasDropdownView(!isOverseasDropdownView);
  };

  const handleDomesticBlurContainer = () => {
    setTimeout(() => {
      setDomesticDropdownView(false);
    }, 150);
  };

  const handleOverseasBlurContainer = () => {
    setTimeout(() => {
      setOverseasDropdownView(false);
    }, 150);
  };

  // 페이지가 로드될때 키워드 보이게
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await laws_keywords('', '', id);
        setTagKeyword(result);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Container className="setting_box" ref={lawRef => (ref.current[7] = lawRef)}>
      <Text className="heading1">법규 검색 설정</Text>
      <Title className="body1">
        입력한 키워드(각각 최대 5개)와 관련된 법규가 Board화면에 나타납니다.
      </Title>

      <div className="keyword_box">
        <SubTitle className="label1">국내 법규 키워드</SubTitle>
        <div className="input_box">
          <Input
            type="text"
            placeholder="국내 법규에서 찾아봐야 할 키워드가 있나요?"
            value={domesticKeyword}
            onClick={handleDomesticClickContainer}
            onChange={handleDomesticKeywordChange}
            onKeyPress={handleDomesticOnKeyPress}
            onBlur={handleDomesticBlurContainer}
          />
          <Btn onClick={handleRegisterDomestic} className="btn">
            등록
          </Btn>
        </div>
        {isDomesticDropdownView && (
          <DropDown
            ref={dropdownRef}
            options={lawsDomesticChunks[0]}
            handleComboBox={handleDomesticDropDownClick}
            deleteLatestKeyword={deleteDomesticKeyword}
          />
        )}

        <span className="keywords">
          {laws_keywords_domestic === ''
            ? '현재 등록된 키워드가 없습니다.'
            : renderKeywords(laws_keywords_domestic, 'domestic')}
        </span>
      </div>

      <div className="keyword_box">
        <SubTitle className="label1">해외 법규 키워드</SubTitle>
        <div className="input_box">
          <Input
            type="text"
            placeholder="해외 법규에서 찾아봐야 할 키워드가 있나요?"
            value={overseasKeyword}
            onClick={handleOverseasClickContainer}
            onChange={handleOverseasKeywordChange}
            onKeyPress={handleOverseasOnKeyPress}
            onBlur={handleOverseasBlurContainer}
          />
          <Btn onClick={handleRegisterOverseas} className="btn">
            등록
          </Btn>
        </div>
        {isOverseasDropdownView && (
          <DropDown
            ref={dropdownRef}
            options={lawsOverseasChunks[0]}
            handleComboBox={handleOverseasDropDownClick}
            deleteLatestKeyword={deleteOverseasKeyword}
          />
        )}

        <span className="keywords">
          {laws_keywords_overseas === ''
            ? '현재 등록된 키워드가 없습니다.'
            : renderKeywords(laws_keywords_overseas, 'overseas')}
        </span>
      </div>
      {limitKeywordsPopup && (
        <KeywordPopup close={closePopup} header="최대 5개까지만 등록 가능합니다." />
      )}
      {blankKeywordPopup && (
        <KeywordPopup close={closePopup} header="특수문자를 제외한 키워드를 입력 해 주세요." />
      )}
    </Container>
  );
});

export default LawsSetting;

export const DropDown = React.forwardRef(
  ({ options, handleComboBox, deleteLatestKeyword }, ref) => {
    if (!options || !Array.isArray(options) || options.length === 0) {
      return null; // 옵션이 없을 경우 컴포넌트를 숨깁니다.
    }

    return (
      <DropDownContainer ref={ref}>
        {options.map((option, index) => (
          <li key={index} className="news_setting_keywords">
            <div className="keywords">
              {option.keyword === '' ? '과거키워드없음' : deleteLatestKeyword(option)}
            </div>
          </li>
        ))}
      </DropDownContainer>
    );
  },
);

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;
const Title = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const SubTitle = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Btn = styled.button`
  background: ${props => props.theme.colors.btnBg};
  color: ${props => props.theme.colors.bgColor};
`;

const Input = styled.input`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border: 1px solid ${props => props.theme.colors.navTitleColor};
`;
