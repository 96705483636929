import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthProvider';
import styled from 'styled-components';

// img
// import profile from '../../assets/person.png';
import setting from '../../assets/setting.png';

function SignInOutButton() {
  const { user } = useAuth();

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  // const { user } = useAuth();
  // const { user_thumb_img } = user.result;

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  return (
    <>
      <div className="dropdown">
        {/* {user_thumb_img ? (
          <img src={user_thumb_img} alt="UserThumbnail" id="person" onClick={toggleDropdown} />
        ) : (
          <img src={profile} alt="Person" id="person" onClick={toggleDropdown} />
        )} */}
        <IconImg src={setting} alt="setting icon" id="person" onClick={toggleDropdown} />
        {dropdownVisible && (
          <div className={`dropdown-content ${dropdownVisible ? 'visible' : ''}`}>
            {/* NavLink 클릭 시 다운드랍박스를 닫도록 closeDropdown 함수를 전달 */}

            <NavLink to="/setting" className="navlink body2" onClick={closeDropdown}>
              설정
            </NavLink>

            {user.role === '관리자' && (
              <NavLink to="/user_management" className="navlink body2" onClick={closeDropdown}>
                사용자 관리
              </NavLink>
            )}
            <a href="#!" className="body2" onClick={handleLogout}>
              로그아웃
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default SignInOutButton;

const IconImg = styled.img`
  filter: ${props => props.theme.imgFilter};
`;
