// basic
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

//common
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';
import Tooltip from '../../common/Tooltip.js';

//api
import { reports_api } from '../../../apis/reports_api.js';

//components
import RegionButtons from '../../common/RegionButtons.js';

//css
import '../../../styles/Reports.css';

//img
import news from '../../../assets/news.png';

/* ───────────────────────────────────────────────────────────────
 * Reports
 * 기업리포트  화면 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Reports() {
  const tooltipValue =
    '<p>자동으로 수집한 최신 기업 분석 보고서를 확인해보세요.<br />클릭 시 해당 분석 보고서를 제공하는 출처 페이지로 이동합니다.<br /><br /><span class="primary">[정보 출처]</span><br />- 국내 보고서: 팍스넷<br />- 해외 보고서: 미래에셋<br /><br /><span class="primary">[수집 시간]</span><br />- 국내 보고서: 10:00 ~ 12:00 수집<br />- 해외 보고서: 00:00 ~ 05:00 수집</p>';

  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  const [reportsData, setReportsData] = useState(null);

  //   해외 국내 분류
  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const handleRegionChange = region => {
    setSelectedRegion(region);
  };

  // -- 리포트 클릭시 상세 페이지 열기 -- //
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('기업 리포트 URL이 없습니다.');
    }
  };

  // 해외 일때는 최신순 10개만 보이고 국내 일때는 10일전

  const fetchData = useCallback(async () => {
    const options = { post_date: today, division: selectedRegion };

    try {
      const [reportsResult] = await Promise.all([reports_api(options)]);
      setReportsData(reportsResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, today]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!reportsData || reportsData.length === 0) {
    // console.log('데이터가 없습니다.');
    return null; // 혹은 다른 처리 방법을 선택할 수 있습니다.
  }
  // name 값을 기준으로 객체를 그룹화하여 중복 제거
  const uniqueNameMap = reportsData.reduce((accumulator, currentValue) => {
    accumulator[currentValue.name] = currentValue;
    return accumulator;
  }, {});

  // 중복이 제거된 객체들을 배열로 변환
  const uniqueName = Object.values(uniqueNameMap);

  return (
    <div className="reports_wrap">
      <div className="d-flex">
        {/* 타이틀 */}
        <TextTitle>기업 리포트</TextTitle>

        {/* 툴팁 */}
        <div className="tooltip_one">
          <Tooltip data={tooltipValue} />
        </div>
      </div>

      <Container className="container">
        <ContTitle className="sub_title d-between">
          <div className="d-flex">
            <img src={news} alt="뉴스 아이콘 이미지" />
            {selectedRegion === regionOption[0] ? (
              <Text className="title2">
                <span className="span">국내</span>기업 리포트
              </Text>
            ) : (
              <Text className="title2">
                <span className="span">해외</span>기업 리포트
              </Text>
            )}
          </div>

          {/* 국내/해외 */}
          <RegionButtons
            options={regionOption}
            selectedRegion={selectedRegion}
            onRegionChange={handleRegionChange}
          />
        </ContTitle>

        <table>
          <Thead className="label3">
            <tr>
              <th>종목명</th>
              <th>제목</th>
              <th>제공출처</th>
            </tr>
          </Thead>

          {uniqueName && uniqueName.length > 0 ? (
            <tbody className="scroll body2">
              {uniqueName.map(item => (
                <Border key={item.id} onClick={() => detailed_page_load(item)}>
                  <TitleOver>{item.name}</TitleOver>
                  <TdOver>{item.title}</TdOver>
                  <TdOver>{item.source}</TdOver>
                </Border>
              ))}
            </tbody>
          ) : (
            <tbody className="scroll body2">
              <Border>
                <td></td>
                <TdOver>데이터를 불러오지 못했습니다.</TdOver>
                <td></td>
              </Border>
            </tbody>
          )}
        </table>

        {selectedRegion === regionOption[0] ? (
          <SubTitle className="body3 d-end">출처 : PAXNET (https://www.paxnet.co.kr)</SubTitle>
        ) : (
          <SubTitle className="body3 d-end">출처 : 미래에셋증권 (miraeasset.com)</SubTitle>
        )}
      </Container>
    </div>
  );
}

export default Reports;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const TextTitle = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Thead = styled.thead`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border-top: 1px solid ${props => props.theme.colors.border};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;
const TitleOver = styled.td`
  color: ${props => props.theme.colors.titleColor};
`;

const TdOver = styled.td`
  color: ${props => props.theme.colors.subTitleColor};
`;
const Border = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const SubTitle = styled.span`
  color: ${props => props.theme.colors.subTitleColor};
`;
