// -- basic -- //
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

// -- api -- //
import GoogleKeyword from './GoogleKeyword';
// import NaverKeyword from './NaverKeyword';
import NaverTrend from './NaverTrend';
import GoogleRelatedKeywrod from './GoogleRelatedKeyword.js';
import { google_keywords_api } from '../../../apis/google_keywords_api.js';

// -- components -- //
import Tooltip from '../../common/Tooltip';

// -- css -- //
import '../../../styles/Reset.css';
import '../../../styles/Common.css';
import '../../../styles/SocialTrend.css';

//img
import news from '../../../assets/news.png';
import naver from '../../../assets/naver_logo.png';
import google from '../../../assets/google.png';

/* ───────────────────────────────────────────────────────────────
 * SocialTrend
 * - 구글 네이버 키워드 보여주는 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

export default function SocialTrend() {
  const tooltipValue =
    '<p>오늘의 인기 검색어에 따른 연관 검색어 및 검색량 변화를 보여줍니다.</p><br /><span class="primary">[실시간 인기 검색어]</span><br />- Google에서 제공하는 실시간 인기 검색어를 보여줍니다.<br />- 해당 검색어를 클릭하면, 주요 뉴스 2건을 제공합니다.<br /><br /><span class="primary">[검색량 추이]</span><br />- 최근 일주일 동안 해당 키워드가 언급된 추이를 보여줍니다.<br />- 키워드 언급량은 상대 지표로 최대값이 100입니다.';
  const [param, setParam] = useState({});
  const [changeIndex, setChangeIndex] = useState(0);

  const fetchData = useCallback(async () => {
    const result = await google_keywords_api();
    const item = result.rss.channel[0];

    setParam(item);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDataFromChild = index => {
    // console.log('Data from child:', data);
    // console.log('Data from child:', index);
    setChangeIndex(index);
  };

  return (
    <div className="social_wrap">
      <div className="d-flex">
        {/* 타이틀 */}
        <TextTitle>소셜 트렌드 현황</TextTitle>

        {/* 툴팁 */}
        <div className="tooltip_one">
          <Tooltip data={tooltipValue} />
        </div>
      </div>

      <Container className="container">
        <ContTitle className="sub_title d-between">
          <div className="d-flex">
            <img src={news} alt="뉴스 아이콘 이미지" />
            <Text className="title2">Today 인기 검색어 TOP 10</Text>
          </div>
        </ContTitle>

        <div>
          <GoogleKeyword data={param} onDataFromChild={handleDataFromChild} />
        </div>

        <ContTitle className="sub_title d-between">
          <div className="d-flex">
            <img src={news} alt="뉴스 아이콘 이미지" />
            <Text className="title2">소셜 기반 키워드 현황</Text>
          </div>
        </ContTitle>

        <ChartBg className="d-flex detail_social">
          {/* <NaverKeyword data={{ param, changeIndex }} />{' '} */}
          <div className="naverkeyword_wrap">
            <div className="logo google_logo">
              <img src={google} alt="아이콘 이미지" />
              <Text className="label3">연관 검색어</Text>
            </div>
            <GoogleRelatedKeywrod data={{ param, changeIndex }} />
            {/* <NaverKeyword data={{ param, changeIndex }} /> */}
          </div>
          <div className="navertrend_wrap">
            <div className="logo naver_logo">
              <img src={naver} alt="아이콘 이미지" />
              <Text className="label3">검색량 추이</Text>
            </div>
            <NaverTrend data={{ param, changeIndex }} />
          </div>
        </ChartBg>
      </Container>
    </div>
  );
}

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const TextTitle = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const ChartBg = styled.div`
  background: ${props => props.theme.colors.chartBg};
`;
