// -- basic -- //
import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * invests_api
 * 혁신의 숲 데이터 (국내 투자 정보)
 * https://www.innoforest.co.kr/invest?period=30&page=
 * ─────────────────────────────────────────────────────────────
 */

export async function invests_api(options) {
  let query = null;

  if (options.collection_date) {
    query = `collection_date=${options.collection_date}`;
  }

  if (options.division) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&division=${options.division}` : `division=${options.division}`;
  }

  // console.log(query);
  try {
    const response = await fetch(`${BASE_URL}/invests/?${query}`);
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}

/* ─────────────────────────────────────────────────────────────
 * invests_keyword_api
 * 혁신의 숲 데이터 (국내투자정보)를 참고하여 GPT 추출한 키워드 데이터.
 * ─────────────────────────────────────────────────────────────
 */
export async function invests_keyword_api(options) {
  let query = null;

  if (options.collection_date) {
    query = `collection_date=${options.collection_date}`;
  }

  try {
    const response = await fetch(`${BASE_URL}/invests_keywords/?${query}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}
