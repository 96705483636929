//basic
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import NewsLoadMore from './NewsLoadMore.js';

//components
import downIcon from '../../../assets/keyboard_arrow_down.png';
import upIcon from '../../../assets/keyboard_arrow_up.png';

//img
import noImage from '../../../assets/no-image.png';
import ForeignLogo from '../../../assets/news_foreign_logo.png';
import noKeyword from '../../../assets/noKeyword.png';

/* ───────────────────────────────────────────────────────────────
 * NewsList
 * 뉴스 리스트 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const NewsList = ({ data, selectedRegion }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [noDataRendered, setNoDataRendered] = useState(false);

  // 관련뉴스 더보기 토글 이미지
  const [isDropdownOpen, setIsDropdownOpen] = useState(
    Array.isArray(data) ? data.reduce((acc, _, index) => ({ ...acc, [index]: false }), {}) : {},
  );
  const [prevSelectedIndex, setPrevSelectedIndex] = useState(null);

  // const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD
  // const today_postdate = formatDate(new Date()); //뉴스리스트에 들어갈 날짜

  // 뉴스 url 클릭
  const detailedPageLoad = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('뉴스 URL이 없습니다.');
    }
  };

  // 별점표시
  const getStarRating = rating => {
    const clampedRating = Math.min(5, Math.round(rating));
    return '★'.repeat(clampedRating) + '☆'.repeat(5 - clampedRating);
  };

  const handleRelatedNewsClick = (e, item, index) => {
    e.stopPropagation();
    if (prevSelectedIndex !== null && prevSelectedIndex !== index) {
      setIsDropdownOpen(prevStates => ({ ...prevStates, [prevSelectedIndex]: false }));
    }
    setSelectedItem(prevSelectedItem => (prevSelectedItem === item ? null : item));
    setIsDropdownOpen(prevStates => ({ ...prevStates, [index]: !prevStates[index] }));
    setPrevSelectedIndex(index);
  };

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      const noDataInSelectedRegion = !data.some(
        item => item.division === selectedRegion || selectedRegion === '',
      );

      setNoDataRendered(noDataInSelectedRegion);
    } else {
      setNoDataRendered(true);
    }
  }, [data, selectedRegion]);

  // 렌더링할 데이터 그룹핑
  const groupedData = {};
  const nonZeroLabelData = []; // label이 0이 아닌 데이터를 모으는 배열

  if (data === '결과가 없습니다') {
    data = [];
  }

  if (data) {
    data.forEach(item => {
      const label = item.label || 'unknown';

      if (label === '0') {
        // console.log(label);
        nonZeroLabelData.push([item]); // label이 0인 경우에도 개별 그룹으로 만듦
      } else {
        // label이 0이 아닌 경우에만 그룹화
        if (!groupedData[label]) {
          groupedData[label] = [];
        }
        groupedData[label].push(item);
        // console.log(label);
      }
    });

    // 중복된 title 제거
    for (const label in groupedData) {
      const uniqueTitles = new Set();
      groupedData[label] = groupedData[label].filter(item => {
        if (!uniqueTitles.has(item.title)) {
          uniqueTitles.add(item.title);
          return true;
        }
        return false;
      });
    }
  }

  // groupedData를 라벨의 0번째 항목의 rating을 기준으로 정렬
  const sortedGroupedData = Object.values(groupedData).sort((a, b) => {
    const ratingA = a[0] && a[0].rating ? a[0].rating : 0;
    const ratingB = b[0] && b[0].rating ? b[0].rating : 0;
    return ratingB - ratingA; // 내림차순으로 정렬
  });

  // label이 0인 데이터를 sortedGroupedData에 추가
  if (nonZeroLabelData.length > 0) {
    sortedGroupedData.push(...nonZeroLabelData); // label이 0인 데이터를 sortedGroupedData에 추가
  }

  return (
    <div className="cont_box">
      {noDataRendered ? (
        <div className="news_nodata">
          <img src={noKeyword} alt="이미지"></img>
          <p className="label3">뉴스 키워드를 설정해주세요.</p>
          <p className="label4">
            해당 키워드로 수집된 뉴스가 없는 경우
            <br />
            자정부터 수집을 시작합니다.
          </p>
        </div>
      ) : (
        // eslint-disable-next-line
        Object.entries(sortedGroupedData).map(([index, items]) => {
          // items.map(item => {
          const item = items[0];
          const emotionClass =
            item.emotion_value === '긍정' ? 'yes' : item.emotion_value === '부정' ? 'no' : 'same';

          if (item.division === selectedRegion || selectedRegion === '') {
            if (item.post_date) {
              return (
                <Wrap className="news_card_wrap" key={item.id}>
                  <div className="d-between">
                    <ul className="news_thum" onClick={() => detailedPageLoad(item)}>
                      <li className="d-between">
                        <div className="d-flex">
                          {selectedRegion === '국내' && (
                            <img
                              src={
                                item.media_logo_img_url ||
                                'https://via.placeholder.com/70x30?text=No+Logo'
                              }
                              onError={e => {
                                e.target.src = 'https://via.placeholder.com/70x30?text=No+Logo';
                              }}
                              alt="회사로고"
                              className="news_logo_icon"
                            />
                          )}

                          {selectedRegion === '해외' && (
                            <img src={ForeignLogo} alt="뉴욕타임즈" className="news_logo_icon" />
                          )}
                          <MediaTitle className="label3">{item.media}</MediaTitle>
                        </div>

                        <div className="d-flex">
                          <p className={`news_icon news_icon_${emotionClass}`}>
                            {item.emotion_value !== null ? item.emotion_value : '중립'}
                          </p>
                          <p className="rating">{getStarRating(item.rating)}</p>
                        </div>
                      </li>
                      <Title className="news_title title3">{item.title}</Title>
                      <li className="news_date body3">
                        {item.post_date.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')}
                      </li>
                    </ul>

                    <ul>
                      <li className="news_img">
                        <img
                          src={item.thumb_img_url || noImage}
                          onError={e => {
                            e.target.src = noImage;
                          }}
                          alt="썸네일"
                        />
                      </li>
                    </ul>
                  </div>

                  {items.length === 1 ? null : (
                    <div className="news_related_button">
                      <button
                        className="news_open label2 d-flex"
                        onClick={e => handleRelatedNewsClick(e, item, index)}
                      >
                        관련 뉴스 {items.length - 1}건
                        <img
                          className="news_drop_icon d-flex"
                          src={isDropdownOpen[index] ? upIcon : downIcon}
                          alt="드롭다운 아이콘 이미지"
                        />
                      </button>
                      {isDropdownOpen[index] && selectedItem && item === selectedItem && (
                        <NewsLoadMore
                          data={items.slice(1)} // 그룹의 모든 항목들 중 0번째를 제외한 나머지 항목들을 전달
                          selectedRegion={selectedRegion}
                        />
                      )}
                    </div>
                  )}
                </Wrap>
              );
            } else {
              return null;
            }
          } else {
            return null;
          }
          // });
        })
      )}
    </div>
  );
};

export default NewsList;

const Title = styled.li`
  color: ${props => props.theme.colors.titleColor};
`;

const MediaTitle = styled.span`
  color: ${props => props.theme.colors.subTitleColor};
`;

const Wrap = styled.div`
  border: 1px solid ${props => props.theme.colors.border};
`;
