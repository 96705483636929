// -- basic -- //
import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * event_schedule_api
 *
 */

// 이벤트 스케쥴 api
export async function event_schedule_api(options) {
  let query = null;

  if (options.division) {
    query = `?division=${options.division}`;
  }

  if (options.keyword) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&keyword=${options.keyword}` : `keyword=${options.keyword}`;
  }

  try {
    const response = await fetch(`${BASE_URL}/event_schedules/${query}`);
    const data = await response.json();
    // console.log('이벤트스케쥴 :: ', data);
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}
