import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../components/common/Footer';

//img
import naver from '../../assets/naver_icon.png';
// import facebook from '../../assets/facebook_icon.png';
import news from '../../assets/news_icon.png';
import youtube from '../../assets/youtube_icon.png';

export default function FindPassword() {
  return (
    <>
      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>Rainbowbrain Trend Sensing Board</title>
      </Helmet>

      <div className="login_wrap find_wrap">
        <div className="login_container">
          <p className="title1">임시 비밀번호 발급</p>

          <div className="find_cont">
            <p className="sub_tit label1">이메일 계정 입력</p>
            {/* 계정 입력란 */}
            <label>
              <input
                type="text"
                name="username"
                placeholder="가입할 때 입력하신 이메일 주소를 입력하세요."
              />
            </label>

            <button className="label1 login" type="submit">
              임시 비밀번호 발급
            </button>
          </div>

          <div className="link body3">
            <a
              href="https://blog.naver.com/rainbow-brain"
              className="d-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={naver} alt="로고 이미지" />
              <p>네이버 블로그 방문</p>
            </a>
            {/* <a
              href="https://www.facebook.com/rbrain.co.kr"
              className="d-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="로고 이미지" />
              <p>페이스북 채널 방문</p>
            </a> */}
            <a
              href="https://page.stibee.com/subscriptions/146545"
              className="d-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={news} alt="로고 이미지" />
              <p>뉴스레터 구독</p>
            </a>
            <a
              href="https://www.youtube.com/channel/UCoVz-GdDv4hkz6el7k7ogfg"
              className="d-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} alt="로고 이미지" />
              <p>YouTube 채널 방문</p>
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
