import React, { useState, useEffect } from 'react';
import countryImages from '../../../utils/countryImg';

const DashPatent = ({ data }) => {
  const uniqueTitleMap = (data || []).reduce((accumulator, currentValue) => {
    accumulator[currentValue.title] = currentValue;
    return accumulator;
  }, {});

  // 중복이 제거된 객체들을 배열로 변환
  const uniqueName = Object.values(uniqueTitleMap);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex < uniqueName.length - 1 ? prevIndex + 1 : 0));
    }, 2000);

    return () => clearInterval(interval);
  }, [uniqueName.length]);

  return (
    <div className="dash_legi_wrap dash_patent_wrap">
      {uniqueName.length > 0 ? (
        <div className="slide-container">
          <div className="slide-indicator">
            <span>{currentIndex + 1}</span> / 총 {uniqueName.length}건
          </div>

          <div className="slide">
            <ul className="d-flex">
              <li>
                <img
                  className="country"
                  src={countryImages.find(img => img.alt === uniqueName[currentIndex].country)?.src}
                  alt={uniqueName[currentIndex].country}
                />
              </li>
              <li>
                <p>{uniqueName[currentIndex].title}</p>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <p className="label2">No Patent Data Available</p>
      )}
    </div>
  );
};

export default DashPatent;
