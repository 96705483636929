// basic
import { React, useState } from 'react';
import styled from 'styled-components';
import Chatbot from 'react-chatbot-kit';
import config from '../../utils/chatbotConfig';
import MessageParser from '../../utils/MessageParser';
import ActionProvider from '../../utils/ActionProvider';

// css
import '../../styles/Chatbot.css';

// img
import ChatbotImg from '../../assets/chatbot.gif';

function TsbChatbot() {
  const [showBot, toggleBot] = useState(false);

  return (
    <div className="d-between">
      {showBot && (
        <Container className="app-chatbot-container">
          <Title className="title2" style={{ marginBottom: '24px' }}>
            Trend Sensing Board
          </Title>
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            placeholderText="현재는 지원하지 않는 기능입니다."
          />
        </Container>
      )}

      <button className="app-chatbot-button" onClick={() => toggleBot(prev => !prev)}>
        <img src={ChatbotImg} alt="chatbot icon" />
        <div className="chatbot-button-text">문의하기</div>
      </button>
    </div>
  );
}

export default TsbChatbot;

const Container = styled.div`
  background: ${props => props.theme.colors.bgColor};
  border: 1px solid ${props => props.theme.colors.chartBorder};
  box-shadow: 5px 5px 10px ${props => props.theme.colors.border};
`;

const Title = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;