import React, { useEffect, useMemo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { login } from '../../apis/users_api.js';
import { useCookies } from 'react-cookie';

/* ─────────────────────────────────────────────────────
 * LoginForm:  로그인 form
 * ─────────────────────────────────────────────────────
 */

function LoginForm() {
  // const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['rememberEmail']);
  const [isRemember, setIsRemember] = useState(false);

  const checkOption = useMemo(() => [0, 1, 2], []);
  const loginErrorMessage = {
    0: '계정과 비밀번호 모두 입력 해 주시기 바랍니다.',
    1: (
      <>
        등록된 정보가 일치하지 않습니다. <br />
        관리자 또는 사용자 선택을 확인하셨습니까? <br />
        정확한 정보로 다시 입력하시기 바랍니다.
      </>
    ),
    2: (
      <>
        계정 또는 비밀번호를 5회 이상 잘못 입력하였습니다. <br />
        임시 비밀번호를 발급 받으시길 바랍니다.
      </>
    ),
  };
  const [loginValidState, setLoginValidState] = useState(checkOption[3]);
  const [loginAttempts, setLoginAttempts] = useState(0);

  // const handleFind = () => {
  //   navigate('/password');
  // };

  // const handleSignup = () => {
  //   navigate('/signup');
  // };

  // 로그인 형태(관리자 / 사용자 )
  const [userRole, setUserRole] = useState('관리자');

  // console.log(userRole);

  // 계정(이메일)
  const [userEmail, setUserEmail] = useState('');

  // 비밀번호
  const [password, setPassword] = useState('');

  // 로그인 형태 선택 함수
  const handleUserRoleChange = event => {
    setUserRole(event.target.value);
  };

  // 계정 입력 함수
  const handleUsernameChange = e => {
    setUserEmail(e.target.value);
  };

  // 비밀번호 입력 함수
  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  // 로그인 버튼 클릭 함수
  const onLoginClick = async () => {
    const options = {
      user_role: userRole,
      user_email: userEmail,
      user_pw: password,
    };

    const users = await login(options);
    // console.log('users :: ', users.token);

    if (!userEmail && !password) {
      setLoginValidState(checkOption[0]);
      return;
    } else if (!userEmail || !password || users.token === undefined) {
      setLoginAttempts(loginAttempts + 1);

      if (loginAttempts > 4) {
        // 5번 이상 틀릴 시
        setLoginValidState(checkOption[2]);
        return;
      }

      setLoginValidState(checkOption[1]);
      return;
    }

    // 유저 권한이 없으면 로그인 막고 이메일 인증으로 진행
    if (!users.foundUser.user_role) {
      // console.log(users.foundUser.user_role);
      alert('이메일 인증을 해야합니다.');
      return;
    }

    if (users.token) {
      localStorage.setItem('token', users.token); // 토큰을 로컬 스토리지에 저장
      // alert('로그인에 성공 했습니다.');
      window.location.href = '/';
    }

    if (!users.token) {
      alert('로그인에 실패 했습니다.');
    }
  };

  // handleAuthorization();

  // Enter 키 입력 감지
  const handleFormSubmit = async event => {
    if (isRemember) {
      setCookie('rememberEmail', userEmail);
    }

    event.preventDefault();
    await onLoginClick();
  };

  // 아이디 저장 체크
  const handleOnChange = e => {
    setIsRemember(e.target.checked);
    // console.log(username);
    if (e.target.checked) {
      setCookie('rememberEmail', userEmail);
    } else {
      removeCookie('rememberEmail');
    }
  };

  useEffect(() => {
    if (cookies.rememberEmail !== undefined) {
      setUserEmail(cookies.rememberEmail);
      setIsRemember(true);
    }
  }, [cookies.rememberEmail, setUserEmail, setIsRemember]);
  return (
    <div className="column">
      <form onSubmit={handleFormSubmit}>
        {/* 계정 입력란 */}
        <div className="login_input column">
          <label>
            <div className="d-flex">
              <input
                className="login_set_input"
                type="radio"
                id="admin"
                name="userRole"
                value="관리자"
                checked={userRole === '관리자'}
                onChange={handleUserRoleChange}
              />
              <label htmlFor="관리자" for="admin">
                관리자
              </label>
            </div>
            <div className="d-flex">
              <input
                className="login_set_input"
                type="radio"
                id="user"
                name="userRole"
                value="사용자"
                checked={userRole === '사용자'}
                onChange={handleUserRoleChange}
              />
              <label htmlFor="사용자" for="user">
                사용자
              </label>
            </div>
          </label>

          <label>
            <input
              type="text"
              name="username"
              placeholder="이메일 계정"
              value={userEmail}
              onChange={handleUsernameChange}
            />
          </label>

          {/* 비밀번호 입력란 */}
          <label>
            <input
              type="password"
              name="password"
              placeholder="비밀번호"
              value={password}
              onChange={handlePasswordChange}
            />
          </label>

          <p className="error_text body3">{loginErrorMessage[loginValidState]}</p>

          <label className="checkbox body2">
            <input type="checkbox" onChange={handleOnChange} checked={isRemember} />
            아이디 저장
          </label>

          <button className="label1 login" type="submit">
            로그인
          </button>
        </div>

        {/* <div className="find_wrap">
          <button className="body2" onClick={handleSignup}>
            회원가입&nbsp;&nbsp;&#124;&nbsp;&nbsp;
          </button>
          <button className="body2" onClick={handleFind}>
            임시 비밀번호 발급
          </button>
        </div> */}
      </form>
    </div>
  );
}

export default LoginForm;
