const DashLegislation = ({ data }) => {
  return (
    <div className="dash_legi_wrap">
      {data ? (
        <div>
          {/* 임시 */}
          <p style={{ color: '#666', marginBottom: '10px' }}>
            <span style={{ color: '#1eb3b8', fontSize: '18px', fontWeight: '600' }}>1</span> / 총
            100건
          </p>
          <p
            className="body3 line-height"
            dangerouslySetInnerHTML={{
              __html: data.content,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default DashLegislation;
