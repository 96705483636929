// -- basic -- //
import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * /password
 * 비번확인 API
 * ─────────────────────────────────────────────────────────────
 */

export async function password_confirm(options) {
  console.log(options);

  const response = await fetch(`${BASE_URL}/password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  });

  // console.log('응답::', response);
  if (!response.ok) {
    throw new Error('비번확인 실패했습니다.');
  }
  const body = await response.json();
  // console.log('로그인확인바디 :: ', body);
  return body;
}
