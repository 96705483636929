import styled from 'styled-components';

//img
import countryImages from '../../../utils/countryImg';
import lockImg from '../../../assets/lock_img04.png';

/* ───────────────────────────────────────────────────────────────
 * PatentsContent
 * 특허 정보 내용 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const PatentsContent = ({ data, selectedRegion }) => {
  // -- 특허 클릭시 상세 페이지 열기 -- //
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('URL이 없습니다.');
    }
  };

  const uniqueTitleMap = (data || []).reduce((accumulator, currentValue) => {
    accumulator[currentValue.title] = currentValue;
    return accumulator;
  }, {});

  // 중복이 제거된 객체들을 배열로 변환
  const uniqueName = Object.values(uniqueTitleMap);
  // console.log('결과: ', patentData);

  return (
    <div>
      {uniqueName && uniqueName.length > 0 ? (
        uniqueName.map(item => {
          if (item.division === selectedRegion || selectedRegion === '') {
            return (
              <Border
                className="patents_wrap d-between"
                key={item.id}
                onClick={() => detailed_page_load(item)}
              >
                <ul>
                  {item.thumb_img_url == null || item.thumb_img_url === '' ? (
                    <li className="patents_img slider-container">
                      <img src="https://via.placeholder.com/150?text=No+Image" alt="샘플이미지" />
                    </li>
                  ) : (
                    <li className="patents_img slider-container">
                      <img src={item.thumb_img_url} alt="썸네일" />
                    </li>
                  )}
                </ul>

                <div className="patents_text">
                  <ul className="d-flex">
                    {data[0].division === '해외' ? (
                      <li>
                        <img
                          src={countryImages.find(img => img.alt === item.country)?.src}
                          alt={item.country}
                          className="body2"
                        />
                      </li>
                    ) : null}
                    <li>
                      <Text className="label1 secondary">{item.title}</Text>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Title className="body2 line-height gray2">
                        인공지능 요약 : {item.summary}
                      </Title>
                    </li>
                  </ul>
                </div>
              </Border>
            );
          }
          return null;
        })
      ) : (
        <div className="no-news">
          <img src={lockImg} alt="요약중 이미지" />
          <Text className="label1">
            수집된 정보가 없습니다.
            <br />
            특허 키워드를 설정해주세요. <br />
            해당 설정로 수집된 정보가 없다면
            <br />
            다음 날 최신 정보를 수집하여 보여 드립니다.
          </Text>
        </div>
      )}
    </div>
  );
};
export default PatentsContent;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;
const Title = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const Border = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;
