// basic
import { React } from 'react';
// import { useNavigate } from 'react-router-dom';

// css
import '../../styles/Chatbot.css';

// img
import HomeIcon from '../../assets/home.png';

function HomeBtn() {
  // const navigate = useNavigate();

  const handleClick = () => {
    // 페이지 맨 위로 스크롤
    // window.scrollTo(0, 0);
    window.location.replace('/');

    // navigate('/');
  };

  return (
    <div className="home-btn-container d-flex">
      <img src={HomeIcon} alt="home button" onClick={handleClick} style={{ cursor: 'pointer' }} />
      <div className="home-button-text">홈으로 이동하기</div>
    </div>
  );
}

export default HomeBtn;
