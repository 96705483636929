// -- basic -- //
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

//common
import { formatDateToYYYYmmdd_ko } from '../../utils/dateFunction.js';
import { useAuth } from '../../contexts/AuthProvider';

//css
import '../../styles/HomePage.css';
import SignInOutButton from '../signupPage/SignInOutButton';

/* ───────────────────────────────────────────────────────────────
 * NavigationBar
 * 네브바 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const NavigationBar = () => {
  // const { user } = useAuth();
  // const { user_name } = user.result;

  const navigate = useNavigate();

  const { user } = useAuth();
  const { user_company } = user.result;

  const today_ko = formatDateToYYYYmmdd_ko(new Date());

  const handleClick = () => {
    // 페이지 맨 위로 스크롤
    window.scrollTo(0, 0);

    navigate('/');

    window.location.reload();
  };

  return (
    <Nav className="nav_container">
      <div className="nav">
        <Title className="title2" onClick={handleClick} style={{ cursor: 'pointer' }}>
          Trend Sensing Board
        </Title>

        <div className="d-flex">
          <Text className="body2" style={{ paddingRight: '15px' }}>
            [{user_company}]
          </Text>
          <Text className="body2">{today_ko}</Text>
          <SignInOutButton></SignInOutButton>
        </div>
      </div>
    </Nav>
  );
};

export default NavigationBar;

const Nav = styled.div`
  background: ${props => props.theme.colors.navBg};
  color: ${props => props.theme.colors.logoColor};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const Title = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;
