// basic
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// common
import { formatDateToYYYYmmdd } from '../../../../utils/dateFunction.js';

// img
import lockImg from '../../../../assets/lock_img01.png';

/* ───────────────────────────────────────────────────────────────
 * NewsSummary : 뉴스 요약 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const NewsSummary = ({ data, selectedRegion }) => {
  const today = formatDateToYYYYmmdd(new Date()); // YYYY년 MM월 DD일
  const [showMessage, setShowMessage] = useState(true);

  // const filteredNews = data
  //   ? data.filter(item => item.post_date === today && item.division === selectedRegion)
  //   : [];

  // 데이터가 여러개여도 상단 데이터만 보이게
  // const topMostItem = filteredNews.length > 0 ? [filteredNews[0]] : [];

  useEffect(() => {
    if (data && data.length > 0) {
      const hasSummaryData = data && data.post_date === today && data.division === selectedRegion;

      setShowMessage(!hasSummaryData);
    } else {
      setShowMessage(true);
    }
  }, [data, selectedRegion, today]);

  // 요약 수집기준 시간 포멧
  const formatCreatedAt = createdAt => {
    const date = new Date(createdAt);
    const formattedDate = `${date.getFullYear()}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')} ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;

    return formattedDate;
  };

  return (
    <div className="maker cont_box">
      {data ? (
        <div>
          <div key={data.id}>
            <p className="label2 primary">
              ※ {data.division} 요약 뉴스 ({formatCreatedAt(data.created_at)} 기준)
            </p>
            <div>
              <Text
                className="body3 line-height"
                dangerouslySetInnerHTML={{
                  __html: data.content,
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        showMessage && (
          <div className="no-news">
            <img src={lockImg} alt="요약중 이미지" />
            <Text className="label1">
              최신 뉴스 정보를
              <br />
              요약 중입니다.
            </Text>
          </div>
        )
      )}
    </div>
  );
};

export default NewsSummary;

const Text = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;
