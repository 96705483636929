import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoginForm from '../../components/loginPage/LoginForm';
import Footer from '../../components/common/Footer';

//img
import naver from '../../assets/naver_icon.png';
// import facebook from '../../assets/facebook_icon.png';
import news from '../../assets/news_icon.png';
import youtube from '../../assets/youtube_icon.png';

function LoginPage() {
  // console.log('로그인 페이지');
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };
  return (
    <>
      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>Rainbowbrain Trend Sensing Board</title>
      </Helmet>

      <div className="login_wrap">
        <div className="login_container">
          <p className="title1" onClick={handleHomeClick} style={{ cursor: 'pointer' }}>
            Trend Sensing Board
          </p>
          <LoginForm />

          <div className="link body3">
            <a
              href="https://blog.naver.com/rainbow-brain"
              className="d-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={naver} alt="로고 이미지" />
              <p>네이버 블로그 방문</p>
            </a>
            {/* <a
              href="https://www.facebook.com/rbrain.co.kr"
              className="d-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="로고 이미지" />
              <p>페이스북 채널 방문</p>
            </a> */}
            <a
              href="https://page.stibee.com/subscriptions/146545"
              className="d-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={news} alt="로고 이미지" />
              <p>뉴스레터 구독</p>
            </a>
            <a
              href="https://www.youtube.com/channel/UCoVz-GdDv4hkz6el7k7ogfg"
              className="d-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} alt="로고 이미지" />
              <p>YouTube 채널 방문</p>
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default LoginPage;
