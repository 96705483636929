import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

//contexts
import { useAuth } from '../../contexts/AuthProvider.js';

// components
import UserManagementForm from '../../components/userManagementPage/UserManagementForm.js';
import HomeBtn from '../../components/common/HomeBtn';

// css
import '../../styles/Setting.css';

function UserManagementPage() {
  const { user } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    // 관리자 계정이 아닌데 세팅페이지 접근하려고 하면 메인페이지로 이동시킴
    if (user.role !== '관리자') {
      navigate('/');
    }
  });

  return (
    <>
      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>Rainbowbrain Trend Sensing Board</title>
      </Helmet>

      <div className="setting_page_wrap userManage_wrap">
        <div className="setting_box information_wrap">
          <h1 className="heading1">사용자 관리</h1>
          <p className="body1" style={{ marginBottom: '50px' }}>
            회사에 소속된 읽기 전용 계정을 추가, 삭제 및 관리합니다.
          </p>

          {/* 사용자 등록 & 조회 */}
          <UserManagementForm user={user} />
          <HomeBtn />
        </div>
      </div>
    </>
  );
}

export default UserManagementPage;
