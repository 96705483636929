/* ───────────────────────────────────────────────────────────────
 * dateFunction : 날짜 설정 함수
 * ───────────────────────────────────────────────────────────────
 */

// 날짜형식을 YYYY-mm-dd로 변환 //
export function formatDate(date) {
  return date.toISOString().split('T')[0];
}

// 날짜형식을 YYYYmmdd로 변환 //
export function formatDateToYYYYmmdd(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
}

// 날짜형식을 YYYY년 mm월 dd일로 변환 //
export function formatDateToYYYYmmdd_ko(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}년 ${month}월 ${day}일`;
}

// 날짜형식을 YYYY년 mm월 dd일로 변환 //
export function formatDateToYYmm(dateStr) {
  // 입력된 날짜 문자열을 Date 객체로 변환
  const date = new Date(dateStr);

  // 년도에서 뒤의 두 자리만 추출
  // const year = String(date.getFullYear() % 100).padStart(2, '0');

  // 월과 일을 추출하고 2자리로 맞추기
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // 결과 문자열 반환
  return `${month}.${day}`;
}

// 날짜 '일' 계산기 //
export function addDaysToDate(date, days) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return formatDate(newDate);
}

// 날짜 '월' 계산기 //
export function addMonthsToDate(date, months) {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return formatDate(newDate);
}

// 날짜 '년' 계산기 //
export function addYearsToDate(date, years) {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return formatDate(newDate);
}
