import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { userSelect, userCreate } from '../../apis/users_api.js';
import { passwordValid } from '../../utils/passwordValid.js';

import ConsentForm from '../../components/signupPage/ConsentForm.js';
import SignUpPopup from '../settingPage/Popup.js';

// css
import '../../styles/SignupPage.css';

/* ─────────────────────────────────────────────────────
 * SignUpForm:  회원가입 form 컴포넌트
 *              여기서 회원정보를 입력하여 회원가입을 진행 함.
 * ─────────────────────────────────────────────────────
 */

function SignUpForm({ onSuccess }) {
  // 회원가입 버튼 활성 체크
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [failedPopup, setFailedPopup] = useState(false);
  const [signUpPopup, setSignUpPopUp] = useState(false);
  const [idDoubleCheck, setIdDoubleCheck] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [agreedCheck, setAgreedCheck] = useState(false);

  // 동의 상태 변수
  const [agreed, setAgreed] = useState([false, false, false]);
  // 체크 옵션 = 0: 중복or사용불가, 1:사용가능, 2:기본, 3:경고1, 4:경고2.
  const checkOption = useMemo(() => [0, 1, 2, 3, 4], []);

  // 이메일
  const [userEmail, setUserEmail] = useState('');
  const [userEmailCheck, setUserEmailCheck] = useState(checkOption[2]); // email 중복 체크
  const [userEmailValidState, setUserEmailValidState] = useState(checkOption[2]); // email 유효성

  // 비밀번호
  const [userPw, setUserPw] = useState('');
  const [userPwConfirm, setUserPwConfirm] = useState('');
  const [userPwMatched, setUserPwMatched] = useState(checkOption[2]); // 비밀번호 일치 여부
  const [userPwValidState, setUserPwValidState] = useState(checkOption[2]); // 비밀번호 유효성 상태

  // 유저 성명
  const [userName, setUserName] = useState('');

  // 유저 회사
  const [userCompany, setUserCompany] = useState('');

  // 유저 부서
  const [userDepartment, setUserDepartment] = useState('');

  // 회사명 (GPT용)
  const [collectionCompany, setCollectionCompany] = useState('');

  // 회사 소개 (GPT용)
  const [collectionCompanyInfo, setCollectionCompanyInfo] = useState('');

  const emailMessages = {
    0: '이미 등록된 이메일 주소입니다. 다시 입력해주시기 바랍니다.',
    1: '사용 가능한 이메일 형식 입니다.',
    2: 'abc@email.com 형식으로 작성 해 주세요.',
    3: '올바른 이메일 주소가 아닙니다. 이메일 주소를 확인해 주세요.',
    4: '이메일 주소를 입력하지 않으셨습니다. 이메일 주소를 입력해주시기 바랍니다.',
  };

  const passwordMessages = {
    0: '사용할 수 없는 비밀번호입니다. 규칙에 맞게 다시 입력해주시기 바랍니다.',
    1: '사용 가능한 비밀번호입니다.',
    2: '영문자, 숫자, 특수기호를 포함하여 최소 8글자를 입력 해 주세요',
  };

  const confirmPasswordMessages = {
    0: '비밀번호와 일치하지 않습니다. 다시 입력해주시기 바랍니다.',
    1: '비밀번호를 확인하였습니다.',
    2: '',
  };

  //───────────────────────────────────────────────────[ ▲ 기본 변수 선언 ]───────────────────────────────────────────────────

  // 이메일 유효성 검사 함수
  const isEmailValid = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // 입력된 이메일 변수설정 및 유효성 검사 함수, true or false
  const handleEmailChange = e => {
    const newEmail = e.target.value;

    setUserEmail(e.target.value);

    if (!newEmail) {
      setUserEmailValidState(checkOption[4]);
      return;
    }

    // 입력된 이메일 유효성 체크
    setUserEmailValidState(isEmailValid(newEmail) ? checkOption[1] : checkOption[3]); // 1 : 사용가능형식, 3 : 올바른형식 X
  };

  // email 중복확인 함수
  const checkDuplicate = async e => {
    const inputName = e.target.name; // email or username

    const getOptions = {
      input_name: inputName,
      user_email: userEmail,
    };

    try {
      const users = await userSelect(getOptions);

      // getUsers 함수에서 반환된 결과(users)를 사용하여 중복 여부를 판단
      if (users.length > 0) {
        // 중복된 경우 처리
        if (inputName === 'email') {
          setUserEmailValidState(checkOption[0]); // 이메일 중복 체크.
        }

        alert(`사용 불가능한 ${inputName} 입니다.`);
      } else {
        // 중복되지 않은 경우 처리
        if (inputName === 'email') {
          setUserEmailCheck(checkOption[1]); // 이메일 사용가능 체크.
        }
        alert(`사용 가능한 ${inputName} 입니다.`);
      }
    } catch (error) {
      console.error('중복확인 에러:', error);
    }
  };

  // 입력된 비밀번호 변수설정 및 유효성 검사 함수, true or false
  const handlePasswordChange = e => {
    const { name } = e.target;
    const newPassword = e.target.value;

    if (name === 'password') {
      setUserPw(e.target.value);

      // 입력된 비밀번호 유효성 체크
      setUserPwValidState(passwordValid(newPassword) ? checkOption[1] : checkOption[0]); // true: 1, false: 0
    } else if (name === 'confirmPassword') {
      setUserPwConfirm(e.target.value);

      // 입력된 비밀번호가 정확히 입력됐는지 확인.
      setUserPwMatched(newPassword === userPw ? checkOption[1] : checkOption[0]); // true: 1, false: 0
    }
  };

  // 회원가입 버튼 클릭시 실행되는 함수.
  const handleSubmit = async () => {
    // 버튼을 비활성화 상태로 변경
    setIsSubmitting(true);

    // 필수 항목 누락되면 알림창 발생.
    if (!userEmail || !userPw || !userPwConfirm || !userName || !userCompany) {
      // 버튼을 활성화 상태로 변경
      setIsSubmitting(false);
      setFailedPopup(true);
      return;
    }

    // 이메일 중복확인 안했으면 알림창 발생.
    if (userEmailCheck !== 1) {
      // 버튼을 활성화 상태로 변경
      setIsSubmitting(false);
      setIdDoubleCheck(true);
      return;
    }

    // 약관동의 하지않을 시 알림창 발생
    if (agreed.filter(value => value === true).length < 2) {
      setIsSubmitting(false);
      setAgreedCheck(true);
      return;
    }

    const uploadData = {
      user_name: userName, // 유저 명
      user_company: userCompany, // 유저 회사
      user_department: userDepartment, // 유저 부서

      collection_company: collectionCompany, // 회사명(GPT용),
      collection_company_info: collectionCompanyInfo, // 회사 소개(GPT용)

      user_pw: userPw,
      user_email: userEmail,
    };

    try {
      await userCreate(uploadData);
      // onSuccess();

      setSignUpPopUp(true);
    } catch (error) {
      // 에러 로그 설정
      console.error('회원가입 에러:', error);

      // 에러가 발생한 경우에 대한 알림창을 표시
      setErrorPopup(true);
    } finally {
      // 버튼을 활성화 상태로 변경
      setIsSubmitting(false);
    }
  };

  const closePopup = () => {
    setSignUpPopUp(false);
    setFailedPopup(false);
    setIdDoubleCheck(false);
    setErrorPopup(false);
    setAgreedCheck(false);
  };

  const handleClick = () => {
    // 페이지 맨 위로 스크롤
    window.scrollTo(0, 0);
  };

  return (
    <div className="signupForm_wrap">
      {/* 이메일 계정 */}
      <div className="form_box">
        <label className="label1">
          이메일 계정 <span>*</span>
        </label>
        <div className="d-flex">
          <input
            type="email"
            name="email"
            className="email"
            value={userEmail}
            onChange={handleEmailChange}
          />
          <button
            name="email"
            onClick={checkDuplicate}
            // disabled={isEmailValidState !== 1}
            className="btn"
          >
            중복확인
          </button>
        </div>
        <p className="body3">{emailMessages[userEmailValidState]}</p>
      </div>

      {/* 비밀번호 */}
      <div className="form_box">
        <label className="label1">
          비밀번호 <span>*</span>
        </label>
        <input type="password" name="password" value={userPw} onChange={handlePasswordChange} />
        <p className="body3">{passwordMessages[userPwValidState]}</p>
      </div>

      {/* 비밀번호 확인 */}
      <div className="form_box">
        <label className="label1">
          비밀번호 확인 <span>*</span>
        </label>
        <input
          type="password"
          name="confirmPassword"
          value={userPwConfirm}
          onChange={handlePasswordChange}
        />
        <p className="body3">{confirmPasswordMessages[userPwMatched]}</p>
      </div>

      {/* 성명 */}
      <div className="form_box">
        <label className="label1">
          성명 <span>*</span>
        </label>
        <input type="text" value={userName} onChange={e => setUserName(e.target.value)} />
      </div>

      {/* 소속 회사 */}
      <div className="form_box">
        <label className="label1">
          소속 회사 <span>*</span>
        </label>
        <input type="text" value={userCompany} onChange={e => setUserCompany(e.target.value)} />
      </div>

      {/* 소속 부서 */}
      <div className="form_box">
        <label className="label1">
          소속 부서 <span>*</span>
        </label>
        <input
          type="text"
          value={userDepartment}
          onChange={e => setUserDepartment(e.target.value)}
        />
      </div>

      <h1 className="heading1">TSB 맞춤 정보 기준</h1>
      {/* 회사명*/}
      <div className="form_box">
        <label className="label1">회사명</label>
        <input
          type="text"
          value={collectionCompany}
          onChange={e => setCollectionCompany(e.target.value)}
        />
      </div>

      {/* 회사소개 */}
      <div className="form_box">
        <label className="label1">회사소개</label>
        <textarea
          className="scroll_box"
          value={collectionCompanyInfo}
          onChange={e => setCollectionCompanyInfo(e.target.value)}
        ></textarea>
      </div>

      <h1 className="heading1">약관 동의</h1>
      <ConsentForm agreed={agreed} setAgreed={setAgreed} />

      {/* 가입신청 버튼 */}
      <button onClick={handleSubmit} disabled={isSubmitting} className="sign_btn label1">
        가입 신청
      </button>

      <Link to="/" onClick={handleClick}>
        {signUpPopup && (
          <SignUpPopup
            close={closePopup}
            title="회원가입이 완료 되었습니다!🎉"
            header="회원님이 등록하신 이메일계정으로 인증메일이 발송되었습니다."
            body="이메일 인증 완료 후 로그인이 가능합니다."
          />
        )}
      </Link>
      {failedPopup && (
        <SignUpPopup
          close={closePopup}
          title="필수 항목을 입력하지 않으셨습니다."
          header="모두 입력한 후에 다시 시도해 주시기 바랍니다."
        />
      )}
      {idDoubleCheck && <SignUpPopup close={closePopup} header="이메일 중복 확인을 해 주세요." />}
      {errorPopup && (
        <SignUpPopup
          close={closePopup}
          header="회원가입 중 에러가 발생했습니다."
          body="잠시 후 다시 시도해주세요."
        />
      )}
      {agreedCheck && (
        <SignUpPopup
          close={closePopup}
          header="이용약관 및 개인정보 처리방침에"
          body="모두 동의하지 않으면 회원 가입이 불가합니다."
        />
      )}
    </div>
  );
}

export default SignUpForm;
