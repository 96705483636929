// import { Fragment } from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
// import { createStore } from 'redux';
// import { persistStore } from 'redux-persist';
// import { Provider } from 'react-redux';
// import rootReducer from './hooks/UserStore';

/*
 * 각각의 컴포넌트는 Main.js 에서 호출하도록 설계.
 */

// const store = createStore(rootReducer);

// const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <App />
  </>
);
