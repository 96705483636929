import React from 'react';

//img
import logo from '../../assets/logo_white.png';

const Footer = () => {
  return (
    <div className="footer_wrap">
      <div className="footer_container">
        <img src={logo} alt="로고 이미지" />

        <div className="d-flex label3">
          <p>이용약관</p>
          <p>개인정보 처리방침</p>
        </div>

        <p className="body3">
          (주)레인보우브레인&nbsp;&nbsp;&nbsp;&nbsp;서울시 영등포구 은행로37 기계회관 본관 4층
          (07238)
        </p>
        <p className="body3">
          전화&nbsp;&nbsp;&#124;&nbsp;&nbsp;070-4680-6090&nbsp;&nbsp;&nbsp;&nbsp;팩스&nbsp;&nbsp;&#124;&nbsp;&nbsp;070-0415-3929
        </p>

        <p className="copy body3">Copyright ⓒ 2024 RAINBOW BRAIN. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
