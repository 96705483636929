// -- basic -- //
import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * stock_api
 * 네이버페이 증권 데이터 (주식 정보) // 국내
 * https://finance.naver.com/
 * ─────────────────────────────────────────────────────────────
 */

export async function stock_api(options) {
  let query = null;
  if (options.stock_collection_date) {
    query = `stock_collection_date=${options.stock_collection_date}`;
  }
  if (options.user_email) {
    query = query
      ? `${query}&user_email=${options.user_email}`
      : `user_email=${options.user_email}`;
  }

  try {
    const response = await fetch(`${BASE_URL}/stock_status/?${query}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}

/* ─────────────────────────────────────────────────────────────
 * stock_api
 * 주식 데이터를 GPT에게 요약 답변 받은 데이터
 * ─────────────────────────────────────────────────────────────
 */
export async function stock_summary_api(options) {
  // console.log('스톡파람 :: ', options);
  let query = '';
  if (options) {
    query += `summary_date=${options.stock_collection_date}&user_email=${options.user_email}`;
  }
  try {
    const response = await fetch(`${BASE_URL}/stock_summary?${query}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}
