import React from 'react';
import styled from 'styled-components';

function Button({ title, click }) {
  return (
    <Btn onClick={click} className="darkmode-btn-container">
      <Bg>
        <SettingBar />
        <SettingBar2 />
        <SettingBar3 />
      </Bg>
      <div className="darkmode-button-text">{title}</div>
    </Btn>
  );
}

export default Button;

const Btn = styled.button`
  // color: ${props => props.theme.colors.titleColor};
`;

const Bg = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: ${props => props.theme.colors.settingBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const SettingBar = styled.span`
  background: ${props => props.theme.colors.btnBg};
  width: 67%;
  height: 4px;
  display: block;
  border-radius: 10px;
  margin: 10px 0px 0 7px;
`;

const SettingBar2 = styled.span`
  background: ${props => props.theme.colors.bgColor};
  width: 40%;
  height: 4px;
  display: block;
  border-radius: 10px;
  margin: 5px 0px 0 7px;
`;

const SettingBar3 = styled.span`
  background: ${props => props.theme.colors.navTitleColor};
  width: 60%;
  height: 4px;
  display: block;
  border-radius: 10px;
  margin: 6px 0px 0 7px;
`;
