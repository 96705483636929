import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * auth_token
 * 유저 인가(Authorization) API
 * 서버로 Token을 전달하여, 유효성을 체크하고, 유저 정보를 받는 API.
 * token은 헤더로 서버에게 전달한다.
 * ─────────────────────────────────────────────────────────────
 */

export async function auth_token(options) {
  const { token } = options;

  try {
    const response = await fetch(`${BASE_URL}/auth/tsb-user`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}
