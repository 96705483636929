import Options from './Options';

const GeneralOptions = props => {
  const options = [
    {
      name: 'TSB에서 데이터 수집은 어떻게 설정합니까?', // 이게 질문 버튼 이름임 (1-3번)
      handler: props.actionProvider.handleChatAnswers_01_03, // 답변 실행 함수 임(ActionProvider.js)
      id: 18, // 버튼 나오는 순서
    },
    {
      name: 'TSB에서 제공하는 정보는 어떤 것들이 있습니까?', // 이게 질문 버튼 이름임 (1-1번)
      handler: props.actionProvider.handleChatAnswers_01_01, // 답변 실행 함수 임(ActionProvider.js)
      id: 19, // 버튼 나오는 순서
    },
    {
      name: 'TSB에서 제공하는 정보는 어떻게 자동으로 수집됩니까?', // 이게 질문 버튼 이름임 (1-2번)
      handler: props.actionProvider.handleChatAnswers_01_02, // 답변 실행 함수 임(ActionProvider.js)
      id: 20, // 버튼 나오는 순서
    },
    {
      name: '더 이상 궁금한 점이 없습니다.', // 이게 질문 버튼 이름임 (1-5번)
      handler: props.actionProvider.handleChatAnswers_01_05, // 답변 실행 함수 임(ActionProvider.js)
      id: 21, // 버튼 나오는 순서
    },
  ];
  return <Options options={options} title="Options" {...props} />;
};

export default GeneralOptions;
