//basic
import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { animateScroll as scroll } from 'react-scroll';

//api
import { market_api, market_summary_api } from '../../../apis/market_api.js';
import { stock_api, stock_summary_api } from '../../../apis/stock_api.js';

//components
import MarketList from './market/MarketList.js';
import StockList from './stock/StockList.js';
import StockSummary from './stock/StockSummary.js';
import MarketSummary from './market/MarketSummary.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

//external library
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';
import Tooltip from '../../common/Tooltip.js';

// img
import plus from '../../../assets/plus.png';

/* ───────────────────────────────────────────────────────────────
 * MarketStockStatus
 * 주식 & 금융 요약 내용, 리스트 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function MarketStockStatus(editBtn) {
  const screen_edit = editBtn.editBtn;

  const { user } = useAuth();

  const user_email = user.result.user_email;

  const tooltipValue =
    '<p>자동으로 수집한 최신 주요 금융 정보를 <span class="primary">AI</span>로 요약해드립니다.<br />시장 정보(선물, 주식)는 사용자가 설정한 항목만 보여드립니다.<br/>우측 상단 [아이콘]을 클릭하여 [설정 - 금융 지수 설정 / 주식 종목 설정]에서 설정해보세요.<br/><br/><span class="primary">[정보 출처]</span><br/>- 금융 정보: 기획재정부<br/>- 시장 정보: 네이버 증권<br/><br/><span class="primary">[수집 시간]</span><br/>- 금융 정보: 00:00 ~ 05:00 수집<br/>- 시장 정보: 10:00 ~ 15:00 사이 <span class="primary">매시간 업데이트</span></p>';

  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  const [marketData, setMarketData] = useState(null);
  const [marketSumData, setMarketSumData] = useState(null);
  const [stockData, setStockData] = useState(null);
  const [stockSumData, setStockSumData] = useState(null);

  //   // -- 같은 조건으로 금융 주식 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = {
      market_collection_date: today,
      stock_collection_date: today,
      user_email: user_email,
    };

    try {
      const [marketResult, stockResult, marketSumResult, stockSumResult] = await Promise.all([
        market_api(options),
        stock_api(options),
        market_summary_api(options),
        stock_summary_api(options),
      ]);

      setMarketData(marketResult);
      setStockData(stockResult);
      setMarketSumData(marketSumResult);
      setStockSumData(stockSumResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [today, user_email]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleContactButtonClick = () => {
    scroll.scrollTo(1214, {
      //스크롤값
      duration: 100,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 0,
      top: 0,
    });
  };

  return (
    <div>
      <Container className="cont_wrap w980">
        <ContTitle className="cont_title d-between">
          {/* screen_edit 변수가 true 일때만 가려지는 css 적용 */}
          {screen_edit && <div className="arrange_wrap"></div>}

          <div className="d-flex">
            {/* 타이틀 */}
            <Text className="title3">금융 및 시장 현황</Text>

            {/* 툴팁 */}
            <Tooltip data={tooltipValue} />
          </div>

          <div className="d-flex">
            {/* 더보기 버튼 */}
            <Link to="/detail">
              <PlusBtn className="plus_btn label4" onClick={handleContactButtonClick}>
                더보기 <IconImg src={plus} alt="아이콘 이미지" />
              </PlusBtn>
            </Link>
          </div>
        </ContTitle>

        <div className="d-flex">
          <div className="NewsSummary scroll">
            <MarketSummary data={marketSumData} />
            <StockSummary data={stockSumData} />
          </div>

          <Border className="StockList">
            <MarketList data={marketData} />
          </Border>

          <div className="StockList">
            <StockList data={stockData} />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MarketStockStatus;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Border = styled.div`
  border-left: 1px solid ${props => props.theme.colors.border};
  border-right: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const PlusBtn = styled.button`
  background: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.subTitleColor};
`;

const IconImg = styled.img`
  filter: ${props => props.theme.imgFilter};
`;
