import React, { useState, useEffect } from 'react';

// 이미지 임포트
import google from '../../../assets/google_logo.png';
import naver from '../../../assets/social_naver_logo.png';

const DashSocialTrend = ({ news, title, twoItems, setTwoItems, newsItems }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleChangeNews = index => {
    setTwoItems(newsItems[index]);
  };

  useEffect(() => {
    if (title.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % title.length);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [title]);

  useEffect(() => {
    if (title.length > 0) {
      handleChangeNews(currentIndex);
    }
  }, [currentIndex, title]);

  // 뉴스기사 특수기호 전환 처리
  const decodeHtmlEntities = text => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  };

  return (
    <div>
      <div className="dash_social_wrap d-flex">
        <p className="trend_title d-flex">
          <img src={google} alt="구글 로고 이미지" />
          Google 인기 검색어
        </p>
        <div>
          {title.length > 0 ? (
            <div key={currentIndex} className="d-flex">
              <p className="trend_num">{currentIndex + 1}</p>
              <p className="trend_data">{title[currentIndex]}</p>
            </div>
          ) : (
            <p className="label2">No Data</p>
          )}
        </div>
      </div>

      <div className="dash_trend_wrap d-flex">
        <p className="trend_title d-flex">
          <img src={google} alt="구글 로고 이미지" />
          검색어 연관 뉴스
        </p>
        <div>
          {twoItems.length > 0 ? (
            twoItems.map((twoContents, index) => (
              <div key={index} className="google_box d-flex">
                <p className="body2">{twoContents.newsSource}</p>
                <p className="body2">{decodeHtmlEntities(twoContents.newsTitle)}</p>
              </div>
            ))
          ) : (
            <p className="label2">현재 데이터 수집 중입니다.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashSocialTrend;
