//basic
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

//common
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';
import Tooltip from '../../common/Tooltip.js';

//api
import { stock_api, stock_summary_api } from '../../../apis/stock_api.js';

//components
import StockSummary from './StockSummary.js';
import StockList from './StockList.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

//css
import '../../../styles/Reset.css';
import '../../../styles/Common.css';

//img
import dollar from '../../../assets/dollar.png';

/* ───────────────────────────────────────────────────────────────
 * Stock
 * 주식 현황 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Stock() {
  const { user } = useAuth();
  const user_email = user.result.user_email;

  const tooltipValue =
    '<p>자동으로 수집한 최신 주요 금융 정보를 <span class="primary">AI</span>로 요약해드립니다.<br />주식 종목은 사용자가 설정한 항목만 보여드립니다.<br />우측 상단 [아이콘]을 클릭하여 [설정 - 주식 종목 설정]에서 설정해보세요.<br /><br /><span class="primary">[정보 출처]</span><br />- 법규 정보: 행정안전부<br /><br /><span class="primary">[수집 시간]</span><br />- 주식 정보: 10:00 ~ 15:00 사이 <span class="primary">매시간 업데이트<br /></p>';

  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  const [stockData, setStockData] = useState(null);
  const [summaryStockData, setSummaryStockData] = useState(null);
  //   같은 조건으로 주식 api 호출
  const fetchData = useCallback(async () => {
    const options = { stock_collection_date: today, user_email: user_email };

    try {
      const [stockResult, summaryResult] = await Promise.all([
        stock_api(options),
        stock_summary_api(options),
      ]);

      setStockData(stockResult);
      setSummaryStockData(summaryResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [today, user_email]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="stock_wrap market_wrap">
      <div className="d-flex">
        {/* 타이틀 */}
        <TextTitle>주식 현황</TextTitle>

        {/* 툴팁 */}
        <div className="tooltip_one">
          <Tooltip data={tooltipValue} />
        </div>
      </div>

      <Container className="container">
        <ContTitle className="sub_title d-between">
          <div className="d-flex">
            <img src={dollar} alt="달러 아이콘 이미지" />
            <Text className="title2">현황 요약</Text>
          </div>
        </ContTitle>

        <div className="d-between">
          <Border className="cont_left">
            <div className="scroll maker">
              {/* 주식 요약 */}
              <StockSummary data={summaryStockData} />
            </div>
          </Border>

          <div className="cont_right">
            <StockList data={stockData} />
          </div>
        </div>
      </Container>
    </div>
  );
}
export default Stock;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Border = styled.div`
  border-right: 1px solid ${props => props.theme.colors.border};
`;
const TextTitle = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;