//basic
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

//api
import { google_keywords_api } from '../../../apis/google_keywords_api.js';

//css
import '../../../styles/Reset.css';
import '../../../styles/Common.css';
import '../../../styles/SocialTrend.css';

//img
import google from '../../../assets/google.png';

/* ───────────────────────────────────────────────────────────────
 * GoogleKeyword
 * - 구글 오늘의 인기검색어 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

export default function GoogleKeyword(props) {
  const [titles, setTitles] = useState([]); //키워드5개용
  const [isActive, setIsActive] = useState(0);

  const handleClick = index => {
    setIsActive(index);

    // const data = 'Hello from child!';
    props.onDataFromChild(index);
  };

  const fetchData = useCallback(async () => {
    const result = await google_keywords_api();

    const items = result.rss.channel[0].item;

    const titless = items ? items.slice(0, 10).flatMap(item => item.title) : [];
    // console.log('키워드 :: ', titless);
    setTitles(titless);
  }, [setTitles]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="googlekeyword_wrap">
      <div className="d-between">
        <ul className="keyword_list">
          <li className="google_img">
            <img src={google} alt="아이콘 이미지" />
            <Text className="label3">Today 인기 검색어 TOP 10</Text>
          </li>
          <li>
            {titles.map((data, index) => (
              <div key={index} className="d-flex" style={{ paddingBottom: '8px' }}>
                <p className="num label2 d-center" onClick={() => handleClick(index)}>
                  {index + 1}
                </p>
                <SubText
                  className={`body3 ${index === isActive ? 'primary' : ''}`}
                  onClick={() => handleClick(index, data)}
                >
                  {data}
                </SubText>
              </div>
            ))}
          </li>
        </ul>
      </div>
    </div>
  );
}

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const SubText = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;
