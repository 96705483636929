// basic
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

// api
import { invests_api, invests_keyword_api } from '../../../apis/invests_api.js';

// utils
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// components
import InvestsContent from './InvestsContent.js';
import Tooltip from '../../common/Tooltip.js';
import KeywordsList from './InvestsKeywords.js';

// img
import news from '../../../assets/news.png';

/* ───────────────────────────────────────────────────────────────
 * Invests : 투자 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */
function Invests() {
  const tooltipValue =
    '<p>자동으로 수집한 신규 투자 유치 최신 정보를 확인해보세요.<br /><span class="primary">AI</span>가 분석한 <span class="primary">키워드</span>를 통해 현재 스타트업 트렌드를 파악할 수 있습니다.<br /><br /><span class="primary">[정보 출처]</span><br />- 투자 정보: 혁신의숲<br /><br /><span class="primary">[수집 시간]</span><br />- 투자 정보: 00:00 ~ 05:00 수집</p>';

  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  const [investsData, setInvestsData] = useState(null);
  const [investsKeyword, setInvestsKeyword] = useState(null);

  // -- 같은 조건으로 투자, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { collection_date: today };

    try {
      const [investsResult, keywordResult] = await Promise.all([
        invests_api(options),
        invests_keyword_api(options),
      ]);

      setInvestsData(investsResult);
      setInvestsKeyword(keywordResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [today]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="fieldKeywords_wrap invests_wrap">
      <div className="d-flex">
        {/* 타이틀 */}
        <TextTitle>국내 투자 정보</TextTitle>

        {/* 툴팁 */}
        <div className="tooltip_one">
          <Tooltip data={tooltipValue} />
        </div>
      </div>

      <Container className="container">
        <ContTitle className="sub_title d-flex">
          <div className="d-flex">
            <img src={news} alt="뉴스 아이콘 이미지" />
            <Text className="title2">최근 투자 기업 주요 키워드</Text>
          </div>
        </ContTitle>

        <div className="d-flex" style={{ alignItems: 'flex-start' }}>
          <Border className="keyword_list_wrap">
            <ul className="keyword_list" style={{ paddingTop: '8px' }}>
              {/* <li className="keyword_list_title d-flex">
                <p className="label2">번호</p>
                <p className="label2">키워드</p>
              </li> */}
              <li>
                <KeywordsList
                  keywords={
                    investsKeyword && investsKeyword.length > 0 ? investsKeyword[0].keywords : null
                  }
                />
              </li>
            </ul>
          </Border>

          <div className="table_wrap">
            {/* 표 */}
            {investsData && <InvestsContent data={investsData} />}

            <Title className="body3 d-end">출처 : 혁신의 숲 (https://www.innoforest.co.kr)</Title>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Invests;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const TextTitle = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;
const Title = styled.span`
  color: ${props => props.theme.colors.subTitleColor};
`;

const Border = styled.div`
  border-right: 1px solid ${props => props.theme.colors.border};
`;
