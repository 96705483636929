import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * naver_datalap_api
 * 네이버 공공 API 데이터랩
 * ─────────────────────────────────────────────────────────────
 */

export async function naver_datalap_api(uploaddata) {
  const response = await fetch(`${BASE_URL}/naverdatalap/data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uploaddata),
  });
  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error('NEWS 생성하는데 실패했습니다.');
  }
  const body = await response.json();
  // console.log(body);
  return body;
}
