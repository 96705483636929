import React, { useState, useEffect } from 'react';

// img
import noImage from '../../../assets/no-image.png';

const DashNews = ({ data }) => {
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    if (data && data.length > 0) {
      const interval = setInterval(() => {
        setStartIndex(prevIndex => (prevIndex + 1) % data.length);
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [data]);

  const getEmotionText = emotionValue => {
    return emotionValue === '긍정' ? '긍정적' : emotionValue === '부정' ? '부정적' : '중립적';
  };

  const getEmotionClass = emotionValue => {
    return emotionValue === '긍정' ? 'yes' : emotionValue === '부정' ? 'no' : 'same';
  };

  // 화면에 보일 뉴스 항목을 4개로 제한하고 순환 처리
  const visibleItems = (data || []).slice(startIndex, startIndex + 4);

  // 전체 데이터가 4개 미만일 경우, 순환하기 위해 시작 인덱스를 조정
  const itemsToRender =
    visibleItems.length < 4
      ? visibleItems.concat((data || []).slice(0, 4 - visibleItems.length))
      : visibleItems;

  return (
    <div className="dash_news_wrap">
      {itemsToRender.length > 0 ? (
        itemsToRender.map((items, index) => (
          <div
            key={index}
            className={`dash_news_cont d-flex ${index === 3 ? 'summary-on' : ''}`} // 4번째 항목에만 summary-on 적용
          >
            <div>
              <div className="d-flex">
                <img className="img" src={items.media_logo_img_url || noImage} alt="썸네일" />
                <span className="media">{items.media}</span>
              </div>
              {index === 3 && (
                <img className="news4" src={items.thumb_img_url || noImage} alt="썸네일" />
              )}
            </div>

            <div>
              <p className="title">{items.title}</p>
              {index === 3 && items.summary && (
                <>
                  <p className="reason">
                    인공지능은 해당 뉴스를 자사에
                    <span> {getEmotionText(items.emotion_value)}</span>
                    <span className={`news_icon news_icon_${getEmotionClass(items.emotion_value)}`}>
                      {items.emotion_value}
                    </span>
                    이라고 판단하고 있습니다.
                  </p>
                  <p className="summary">{items.summary}</p>
                </>
              )}
            </div>
          </div>
        ))
      ) : (
        <p className="label2">No News Data Available</p>
      )}
    </div>
  );
};

export default DashNews;
