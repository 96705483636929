import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * naver_keywords_api
 * 네이버 공공 API 키워드 언급량
 * ─────────────────────────────────────────────────────────────
 */

export async function google_trend_api(options) {
  let query = null;

  if (options.hintKeyword) {
    query = `hintKeyword=${options.hintKeyword}`;
  }

  // const queryParams = new URLSearchParams({ hintkeywords }).toString();
  // console.log('query :: ', query);

  try {
    const response = await fetch(`${BASE_URL}/googletrend/?${query}`);
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}
