import Options from './Options';

const GeneralOptions = props => {
  const options = [
    {
      name: 'TSB를 활용하여 무엇을 할 수 있습니까?', // 이게 질문 버튼 이름임 (최초 질문버튼)
      handler: props.actionProvider.handleChatAnswers_01, // 답변 실행 함수 임(ActionProvider.js)
      id: 1, // 버튼 나오는 순서
    },
    {
      name: 'TSB에서 제공하는 정보는 어떤 것들이 있습니까? ', // 이게 버튼 이름임 (최초 질문버튼)
      handler: props.actionProvider.handleChatAnswers_01_01, // 답변 실행 함수 임(ActionProvider.js)
      id: 2, // 버튼 나오는 순서
    },
    {
      name: 'TSB에서 제공하는 정보는 어떻게 자동으로 수집됩니까?', // 이게 버튼 이름임 (최초 질문버튼)
      handler: props.actionProvider.handleChatAnswers_01_02, // 답변 실행 함수 임(ActionProvider.js)
      id: 3, // 버튼 나오는 순서
    },
    {
      name: '더 이상 궁금한 점이 없습니다.', // 이게 버튼 이름임 (1-5번)
      handler: props.actionProvider.handleChatAnswers_01_05, // 답변 실행 함수 임(ActionProvider.js)
      id: 4, // 버튼 나오는 순서
    },
  ];
  return <Options options={options} title="Options" {...props} />;
};

export default GeneralOptions;
