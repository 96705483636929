// basic
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// common
import { formatDateToYYYYmmdd } from '../../../../utils/dateFunction.js';

// img
import lockImg from '../../../../assets/lock_img01.png';

/* ───────────────────────────────────────────────────────────────
 * MarketSummary
 * 금융 및 시장 요약 내용 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const MarketSummary = ({ data }) => {
  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD
  const [showMessage, setShowMessage] = useState(true);

  // console.log('금융데이터 :: ', data);

  // const filteredMarket = data ? data.filter(item => item.summary_date === today) : [];
  // // 데이터가 여러개여도 상단 데이터만 보이게
  // const topMostItem = filteredMarket.length > 0 ? [filteredMarket[0]] : [];

  useEffect(() => {
    if (data && data.length > 0) {
      const hasSummaryData = data.some(item => item.summary_date === today);
      setShowMessage(!hasSummaryData);
    } else {
      setShowMessage(true);
    }
  }, [data, today]);

  // 요약 수집기준 시간 포멧
  const formatCreatedAt = createdAt => {
    const date = new Date(createdAt);
    const formattedDate = `${date.getFullYear()}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')} ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;

    return formattedDate;
  };

  return (
    <div className="maker cont_box">
      {data && data.length > 0 ? (
        <div>
          <p className="label2 primary">
            ※ 금융 및 시장 현황 요약 ({formatCreatedAt(data[0].created_at)} 기준)
          </p>
          <div key={data[0].id}>
            <div>
              <Text
                className="body3 line-height"
                dangerouslySetInnerHTML={{
                  __html: data[0].content,
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        showMessage && (
          <div className="no-news">
            <img src={lockImg} alt="요약중 이미지" />
            <Text className="label1">
              최신 금융 정보를
              <br />
              요약 중입니다.
            </Text>
          </div>
        )
      )}
    </div>
  );
};

export default MarketSummary;

const Text = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;
