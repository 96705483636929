// basic
import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import styled from 'styled-components';

// api
import { legislation_api, legislation_summary_api } from '../../../apis/legislation_api.js';

// components
import Tooltip from '../../common/Tooltip.js';
import RegionButtons from '../../common/RegionButtons.js';
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// img
import plus from '../../../assets/plus.png';
import lockImg from '../../../assets/lock_img03.png';
import download from '../../../assets/download.png';

/* ───────────────────────────────────────────────────────────────
 * Legislation
 * 규제 정보 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

export default function Legislation(editBtn) {
  const screen_edit = editBtn.editBtn;

  const tooltipValue =
    '<p>자동으로 수집하여 <span class="primary">AI</span>로 분류한 규제 정보를 확인해보세요.<br />다운로드 버튼을 클릭하여 규제 정보 원자료를 받을 수 있습니다.<br /><br /><span class="primary">[정보 출처]</span><br />- 한국행정연구원 최신 규제 동향<br/><br/><span class="primary">[수집 시간]</span><br/>- 규제 정보: 00:00 ~ 05:00 수집</p> ';

  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const today = formatDateToYYYYmmdd(new Date());

  const [data, setData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);

  const handleRegionChange = region => {
    setSelectedRegion(region);
  };

  // -- 같은 조건으로 규제, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { division: selectedRegion, summary_date: today, collection_date: today };

    try {
      const [legislationResult, summaryResult] = await Promise.all([
        legislation_api(options),
        legislation_summary_api(options),
      ]);
      // console.log(summaryResult);

      setData(legislationResult);
      setSummaryData(summaryResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [today, selectedRegion]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const regionData = data ? data.filter(item => item.division === selectedRegion) : [];

  // -- 다운로드 버튼 클릭시 링크 열기 -- //
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('다운로드 파일이 없습니다.');
    }
  };

  const handleContactButtonClick = () => {
    scroll.scrollTo(2975, {
      //스크롤값
      duration: 100,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 0,
      top: 0,
    });
  };

  return (
    <Container className="Laws legislation cont_wrap">
      <ContTitle className="cont_title d-between">
        {/* screen_edit 변수가 true 일때만 가려지는 css 적용 */}
        {screen_edit && <div className="arrange_wrap"></div>}

        <div className="d-flex">
          {/* 타이틀 */}
          <Text className="title3">규제 정보</Text>

          {/* 툴팁 */}
          <Tooltip data={tooltipValue} />
        </div>

        <div className="d-flex">
          {/* 국내/해외 */}
          <div className="sm_global_box">
            <RegionButtons
              options={regionOption}
              selectedRegion={selectedRegion}
              onRegionChange={handleRegionChange}
            />
          </div>

          {/* 더보기 버튼 */}
          <Link to="/detail">
            <PlusBtn className="plus_btn label4" onClick={handleContactButtonClick}>
              더보기 <IconImg src={plus} alt="아이콘 이미지" />
            </PlusBtn>
          </Link>
        </div>
      </ContTitle>

      {/* 본문 */}
      <div className="scroll">
        <div className="maker">
          {summaryData ? (
            <div>
              <Text className="label2">{summaryData.title}</Text>
              <SubText
                className="body3 line-height"
                dangerouslySetInnerHTML={{
                  __html: summaryData.content,
                }}
              />
            </div>
          ) : (
            <div className="no-news" style={{ height: '246px' }}>
              <img src={lockImg} alt="요약중 이미지" style={{ marginTop: '40px' }} />
              <p className="label1">
                수집된 정보가 없습니다.
                <br />
                잠시만 기다려주세요.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* 다운로드 버튼 */}
      <div className="d-end">
        {regionData &&
          regionData.length > 0 &&
          regionData.map(item => (
            <div key={item.id}>
              <button onClick={() => detailed_page_load(item)} className="download">
                <img src={download} alt="뉴스 아이콘 이미지" />
                {/* {item.file_name} */}
                다운로드
              </button>
            </div>
          ))}
        {(!regionData || regionData.length === 0) && null}
      </div>
    </Container>
  );
}

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const SubText = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const PlusBtn = styled.button`
  background: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.subTitleColor};
`;

const IconImg = styled.img`
  filter: ${props => props.theme.imgFilter};
`;
