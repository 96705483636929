import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

// component
import Footer from '../../components/common/Footer';

// css
import '../../styles/Common.css';
import '../../styles/Intro.css';

//img
import intro01 from '../../assets/intro01.png';
import intro02 from '../../assets/intro02.png';
import intro03 from '../../assets/intro03.png';
import intro04 from '../../assets/intro04.png';
import intro05 from '../../assets/intro05.png';

//api
import { login } from '../../apis/users_api';

function TsbIntroductionPage() {
  const navigate = useNavigate();
  // console.log('TSB 소개 페이지');

  const handleLogin = () => {
    navigate('/login');
  };

  const testLogin1 = async () => {
    const options = {
      user_role: '사용자',
      user_email: 'test1_test@rbrain.co.kr',
      user_pw: 'tlqkf12!',
    };

    const users = await login(options);

    if (users.token) {
      localStorage.setItem('token', users.token); // 토큰을 로컬 스토리지에 저장
      // alert('로그인에 성공 했습니다.');
      window.location.href = '/';
    }
  };

  const testLogin2 = async () => {
    const options = {
      user_role: '사용자',
      user_email: 'test2_test@rbrain.co.kr',
      user_pw: 'tlqkf12!',
    };

    const users = await login(options);

    if (users.token) {
      localStorage.setItem('token', users.token); // 토큰을 로컬 스토리지에 저장
      // alert('로그인에 성공 했습니다.');
      window.location.href = '/';
    }
  };

  const testLogin3 = async () => {
    const options = {
      user_role: '사용자',
      user_email: 'test3_test@rbrain.co.kr',
      user_pw: 'tlqkf12!',
    };

    const users = await login(options);

    if (users.token) {
      localStorage.setItem('token', users.token); // 토큰을 로컬 스토리지에 저장
      // alert('로그인에 성공 했습니다.');
      window.location.href = '/';
    }
  };

  const testLogin4 = async () => {
    const options = {
      user_role: '사용자',
      user_email: 'H_Energy_test1@rbrain.co.kr',
      user_pw: 'fpdlsqhdn1!',
    };

    const users = await login(options);

    if (users.token) {
      localStorage.setItem('token', users.token); // 토큰을 로컬 스토리지에 저장
      // alert('로그인에 성공 했습니다.');
      window.location.href = '/';
    }
  };

  return (
    <>
      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>Rainbowbrain Trend Sensing Board</title>
      </Helmet>

      <div className="intro_login nav_container">
        <div className="nav">
          <h1 className="title2" style={{ cursor: 'pointer' }}>
            Trend Sensing Board
          </h1>
          <button className="label3" onClick={handleLogin}>
            로그인/가입
          </button>
        </div>
      </div>

      <div className="intro_wrap">
        <div className="section section1 d-between">
          <div>
            <h1>
              다양한 데이터 소스를 통해
              <br />
              <span>새로운 트렌드</span>를 감지하는
              <br />
              기회를 마련해보세요!
            </h1>
            <p>
              Trend Sensing Board는 <strong>트렌드를 빠르게 파악</strong>하기 위해 필요한 데이터를
              자동으로
              <br />
              수집하고 분석 및 요약하는 서비스 툴입니다. Trend Sensing Board는 빠른 트렌드 파악
              <br />
              뿐만 아니라 <strong>데이터를 기반하여 비즈니스 의사결정</strong>을 할 수 있도록
              지원합니다.
            </p>
            {/* <button className="body2" onClick={handleLogin}>
              시작해보기
            </button> */}
            {/* 제조 */}
            <button className="body2" onClick={testLogin1}>
              서비스 체험하기(제조)
            </button>
            {/* 화학 */}
            <button className="body2" onClick={testLogin2}>
              서비스 체험하기(화학)
            </button>
            {/* 금융 */}
            <button className="body2" onClick={testLogin3}>
              서비스 체험하기(금융)
            </button>
            {/* 환경*/}
            <button className="body2" onClick={testLogin4}>
              서비스 체험하기(환경)
            </button>
          </div>
          <div>
            <img src={intro01} alt="예시 이미지" />
          </div>
        </div>

        <div className="section section2 d-between">
          <div className="text_box">
            <h1>
              뉴스 관련 정보를
              <br />
              한눈에 파악합니다.
            </h1>
            <p>
              연관 키워드를 설정만 하면 국내 및 해외 뉴스를
              <br />
              자동으로 수집하고, 수집한 뉴스의 요약 및 분석을 제공합니다.
              <br />
              <br />
              (전문 요약, 중요도, 연관 뉴스, 워드 클라우드 등 제공)
            </p>
          </div>
          <div>
            <img src={intro02} alt="예시 이미지" />
          </div>
        </div>

        <div className="section section3 d-flex">
          <div>
            <img src={intro03} alt="예시 이미지" />
          </div>

          <div className="text_box">
            <h1>
              경제 현황을 파악하는
              <br />
              도구로 활용합니다.
            </h1>
            <p>
              정부기관에서 제공하는 일일 금융 지수는 물론
              <br />
              다양한 경제 지표를 한번에 제공하여
              <br />
              경제 흐름 파악을 도와 드립니다.
            </p>
          </div>
        </div>

        <div className="section section4 d-between">
          <div className="text_box">
            <h1>
              비즈니스와 관련된
              <br />
              최신 정보를 확인합니다.
            </h1>
            <p>
              연관 키워드 설정을 통해 국내외 특허 및 법규, 투자 등에
              <br />
              대한 정보를 제공합니다. 이런 최신 정보를 통해 비즈니스에
              <br />
              영향을 주는 요인이 무엇인지 파악이 가능합니다.
            </p>
          </div>
          <div>
            <img src={intro04} alt="예시 이미지" />
          </div>
        </div>

        <div className="section5">
          <div className="d-between">
            <h1>
              현재 관심을 받고 있는
              <br />
              주제를 파악합니다.
            </h1>
            <p>
              실시간 인기 검색어를 토대로 연관 뉴스, 연관 검색어, 연관 검색량을
              <br />
              제공하여 최신 뜨거운 감자가 무엇인지 확인합니다.
              <br />
              <br />
              이외에 다양한 데이터를 제공하는 것을 목표로 하고 있으며
              <br />
              점진적으로 서비스 업데이트가 진행될 예정입니다.
            </p>
          </div>
          <div className="d-center">
            <img src={intro05} alt="예시 이미지" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TsbIntroductionPage;
