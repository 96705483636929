//basic
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

//api
import { news_api, news_summary } from '../../../apis/news_api.js';
import { setting_keyword } from '../../../apis/users_api.js';

//common
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

//components
import NewsSummary from './NewsSummary.js';
import NewsList from './NewsList.js';
import TagKeyword from '../../common/TagKeyword.js';
import Tooltip from '../../common/Tooltip.js';
import RegionButtons from '../../common/RegionButtons.js';

//img
import news from '../../../assets/news.png';

/* ───────────────────────────────────────────────────────────────
 * News : 뉴스 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function News() {
  const { user } = useAuth();
  const { user_email } = user.result;
  const tooltipValue =
    '<p>수집한 뉴스 데이터는 <span class="primary">AI</span>로 요약해드립니다.<br/>우측 상단 [아이콘]을 클릭하여 [설정 - 뉴스 검색 설정]에서 키워드를 설정해보세요.<br/><br/><span class="primary">[정보 출처]</span><br/>- 국내: 네이버 뉴스<br/>- 해외: New York Times<br/><br/><span class="primary">[수집 시간]</span><br/>- 국내: 07:00 ~ 09:00 수집<br/>- 해외: 07:00 ~ 09:00 수집</p>';

  const today = formatDateToYYYYmmdd(new Date());

  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);

  // TagKeyword 컴포넌트를 사용하는 변수
  const tagOption = ['news_keywords_domestic', 'news_keywords_overseas'];
  const [tagValue, setTagValue] = useState(tagOption[0]);

  const [newsData, setNewsData] = useState(null);
  const [summaryNewsData, setSummaryNewsData] = useState(null);

  const [tagKeyword, setTagKeyword] = useState({});

  const domesticKeywords = user?.result?.news_keywords_domestic || '';
  const overseasKeywords = user?.result?.news_keywords_overseas || '';
  let newsKeywords = '';

  if (domesticKeywords && overseasKeywords) {
    newsKeywords = `${domesticKeywords},${overseasKeywords}`;
  } else if (domesticKeywords) {
    newsKeywords = domesticKeywords;
  } else if (overseasKeywords) {
    newsKeywords = overseasKeywords;
  }

  // console.log(tagKeyword);

  const handleRegionChange = region => {
    setSelectedRegion(region);

    if (region === regionOption[1]) {
      setTagValue(tagOption[1]);
    } else {
      setTagValue(tagOption[0]);
    }

    // fetchData();
  };

  // -- 같은 조건으로 뉴스, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = {
      division: selectedRegion,
      user_email: user_email,
      keyword: newsKeywords,
      post_date: today,
      func: '요약',
      header: 'True',
    };

    try {
      const [newsResult, summaryResult, keywordResult] = await Promise.all([
        news_api(options),
        news_summary(options),
        setting_keyword(options),
      ]);

      setNewsData(newsResult);
      setSummaryNewsData(summaryResult);
      setTagKeyword(keywordResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, user_email, newsKeywords, today]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="news_wrap">
      <div className="d-flex">
        {/* 타이틀 */}
        <Text>업계 동향 및 경쟁 정보</Text>

        {/* 툴팁 */}
        <div className="tooltip_one">
          <Tooltip data={tooltipValue} />
        </div>
      </div>

      <Container className="container">
        <ContTitle className="sub_title d-between">
          <div className="d-flex">
            <img src={news} alt="뉴스 아이콘 이미지" />
            <Title className="title2">뉴스 요약</Title>
          </div>

          {/* 국내/해외 */}
          <RegionButtons
            options={regionOption}
            selectedRegion={selectedRegion}
            onRegionChange={handleRegionChange}
          />
        </ContTitle>

        <div>
          {/* ----- 뉴스 ----- */}
          <div className="d-between">
            <Border className="cont_left">
              <div className="scroll" style={{ paddingRight: '10px' }}>
                <div className="keyword_wrap d-flex">
                  {/* 키워드 */}
                  <p className="label2">키워드</p>
                  {tagKeyword.length > 0 ? (
                    <TagKeyword data={tagKeyword} tag={tagValue} />
                  ) : (
                    <p>No Keywords</p>
                  )}
                </div>

                <div className="maker">
                  {/* 좌측 뉴스요약 */}
                  <NewsSummary
                    data={summaryNewsData}
                    selectedRegion={selectedRegion}
                    onRegionChange={handleRegionChange}
                  />
                </div>
              </div>
            </Border>

            <div className="cont_right">
              <div className="scroll">
                {/* 우측 뉴스 리스트 */}
                <NewsList
                  data={newsData}
                  selectedRegion={selectedRegion}
                  onRegionChange={handleRegionChange}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default News;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Border = styled.div`
  border-right: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;
const Title = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;
