import React, { useState, useEffect, useCallback } from 'react';

// api
import { userSelectSub, userDeleteSub, updateUserPwInfo } from '../../apis/users_api.js';

// components

import Paging from '../common/Paging.js';
import UserAddModal from './UserAddModal.js';

// css
import '../../styles/Setting.css';

function UserManagementForm({ user }) {
  const admin_id = user.result.user_email;
  // console.log(user.role);

  const [searchDepartment, setSearchDepartment] = useState();
  const [searchName, setSearchName] = useState();

  const [userData, setUserData] = useState([]);

  const [userDataFilter, setUserDataFilter] = useState([]);

  // console.log(userData);
  // console.log(userDataFilter);

  const [selectedRows, setSelectedRows] = useState([]);

  // const limitOption = ['10', '30', '50'];
  // const [limit, setLimit] = useState(5);
  const limit = 5;

  // eslint-disable-next-line
  // const [offset, setOffset] = useState(0);

  const [page, setPage] = useState(1);

  const [showModal, setShowModal] = useState(false);

  // 검색 부서 입력값
  const handleDepartmentChange = e => {
    setSearchDepartment(e.target.value);
  };

  // 검색 이름 입력값
  const handleNameChange = e => {
    setSearchName(e.target.value);
  };

  // 검색 버튼
  const handleSearchBtn = e => {
    let filteredData = userData;

    // searchDepartment 값이 있을 때만 해당 컬럼으로 필터링
    if (searchDepartment) {
      filteredData = filteredData.filter(user => user.user_department.includes(searchDepartment));
    }

    // searchName 값이 있을 때만 해당 컬럼으로 필터링
    if (searchName) {
      filteredData = filteredData.filter(user => user.user_name.includes(searchName));
    }
    setUserDataFilter(filteredData.slice(0, limit));
    // 최종 필터링된 결과를 설정
    // if (!searchDepartment && !searchName) {
    //   setUserDataFilter(userData);
    // } else {
    //   setUserDataFilter(filteredData);
    // }
  };

  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter(rowId => rowId !== id));
    }
  };

  const handleSelectAll = e => {
    const checkboxes = document.querySelectorAll("#data_table tbody input[type='checkbox']");
    const currentPageData = userDataFilter; // 현재 페이지의 데이터만 사용

    checkboxes.forEach(checkbox => {
      checkbox.checked = e.target.checked;
    });

    // 선택된 행의 ID를 업데이트
    if (e.target.checked) {
      const allIds = currentPageData.map(row => row.id); // 현재 페이지의 데이터만 사용
      setSelectedRows(allIds);
    } else {
      setSelectedRows([]);
    }
  };

  const handleTempPw = async (e, row) => {
    // 알림 창 표시
    const confirmation = window.confirm('해당 사용자의 비밀번호를 초기화하시겠습니까?');

    // console.log(row.id);

    if (confirmation) {
      const options = { user_role: user.role, input_name: 'email', user_email: row.user_email };

      try {
        const result = await Promise.all([updateUserPwInfo(row.id, options)]);
        // setUserData(result[0]);
        console.log(result);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleUserDelete = async () => {
    // 알림 창 표시
    const confirmation = window.confirm('정말 삭제하시겠습니까?');

    // 사용자가 확인을 선택한 경우에만 삭제 실행
    if (confirmation) {
      const options = { ids: selectedRows };

      try {
        const result = await Promise.all([userDeleteSub(options)]);
        // setUserData(result[0]);
        console.log(result);

        // 삭제 후 페이지 새로고침
        window.location.reload();
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    } else {
      console.log('삭제 취소됨');
      // 여기에 사용자가 취소를 선택했을 때 실행할 코드를 작성할 수 있습니다.
    }
  };

  const handlePaging = page => {
    setPage(page); // 페이지 번호
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    setUserDataFilter(userData.slice(startIndex, endIndex));

    // if (page === 1) {
    //   setOffset(0);
    // } else {
    //   setOffset((page - 1) * limit);
    // }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSearchBtn();
    }
  };

  const fetchData = useCallback(async () => {
    const options = { admin_id: admin_id };
    try {
      const result = await userSelectSub(options);
      setUserData(result);
      setUserDataFilter(result.slice(0, limit)); // 페이지당 아이템 수만큼 데이터를 잘라서 초기화
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [admin_id, limit]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="d-start">
        <div className="d-flex">
          <p className="label1">소속부서</p>
          <input
            name="department"
            value={searchDepartment}
            onChange={handleDepartmentChange}
            onKeyDown={handleKeyDown}
          ></input>
        </div>

        <div className="d-flex">
          <p className="label1">성명</p>
          <input
            name="name"
            value={searchName}
            onChange={handleNameChange}
            onKeyDown={handleKeyDown}
          ></input>
        </div>

        <button className="setting_btn label1" onClick={handleSearchBtn}>
          검색
        </button>
      </div>

      <div style={{ marginTop: '50px' }}>
        <div className="d-between">
          <label style={{ marginRight: '10px' }}>총 {userData.length}건</label>
          {/* <select value={limit} onChange={e => setLimit(e.target.value)}>
            {limitOption.map((option, index) => (
              <option key={index} value={option}>{`${option}건`}</option>
            ))}
          </select> */}
          <div className="d-end">
            <button className="btn" onClick={handleUserDelete}>
              선택 삭제
            </button>
            <button className="btn" style={{ marginLeft: '10px' }} onClick={handleShowModal}>
              사용자 추가
            </button>
          </div>
          {showModal && <UserAddModal admin_id={admin_id} onClose={handleCloseModal} />}
        </div>
      </div>

      <table id="data_table">
        <thead>
          <tr>
            <th>
              <input type="checkbox" className="checkbox" onChange={handleSelectAll} />
            </th>
            <th>연번</th>
            <th>소속부서</th>
            <th>성명</th>
            <th>이메일</th>
            <th>관리</th>
          </tr>
        </thead>

        <tbody>
          {userDataFilter.map(row => (
            <tr key={row.id}>
              <td>
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={e => handleCheckboxChange(e, row.id)}
                />
              </td>
              <td>{row.id}</td>
              <td>{row.user_department}</td>
              <td>{row.user_name}</td>
              <td>{row.user_email}</td>
              <td>
                <button className="btn pass_btn" onClick={e => handleTempPw(e, row)}>
                  비밀번호 초기화
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* 페이징 컴포넌트 */}
      <div className="paging">
        <Paging items={userData} page={page} handlePaging={handlePaging} />
      </div>
    </>
  );
}

export default UserManagementForm;
