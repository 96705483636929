import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthProvider.js';

import App from './components/app/App.js';

import HomePage from './pages/homePage/HomePage.js';
import UserManagementPage from './pages/userManagementPage/UserManagementPage.js';
import DetailPage from './pages/detailPage/DetailPage.js';
import SettingPage from './pages/settingPage/SettingPage.js';
import Dashboard from './components/common/dashboard/Dashboard.js';

/*
 *  ─────────── [↓ APP ] ─────────────────────────────────────────────────
 */

function Main() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<HomePage />} />
              <Route path="user_management" element={<UserManagementPage />} />
              <Route path="setting" element={<SettingPage />} />
              <Route path="detail" element={<DetailPage />} />
            </Route>
            <Route path="dashboard" element={<Dashboard />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default Main;
