// basic
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import { animateScroll as scroll } from 'react-scroll';

//common
import Tooltip from '../../common/Tooltip.js';

//api
import { event_schedule_api } from '../../../apis/event_schedule_api.js';

//contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

// img
// import plus from '../../../assets/plus.png';
import lockImg from '../../../assets/lock_img03.png';
import noImage from '../../../../src/assets/no-image.png';

/* ───────────────────────────────────────────────────────────────
 * EventSchedule
 * 행사일정
 * ───────────────────────────────────────────────────────────────
 */

function EventSchedule(editBtn) {
  const screen_edit = editBtn.editBtn;

  const { user } = useAuth();

  const tooltipValue =
    '<p>설정한 <span class="primary">키워드</span>에 따라 자동으로 수집한 행사 정보를 확인해보세요.<br />우측 상단 [아이콘]을 클릭하여 [설정 - 행사 일정 설정]에서 키워드를 설정해보세요.<br/>행사 정보 클릭 시 해당 행사 상세 일정 페이지로 이동합니다.<br/><br/><span class="primary">[정보 출처]</span><br/>- 전자신문<br/>- 코엑스<br/><br/><span class="primary">[수집 시간]</span><br/>- 행사 일정: 00:00 ~ 05:00 수집</p>';

  // const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD
  const todayDate = new Date(); // 현재 날짜를 가져옵니다.

  // today 변수에 현재 날짜를 "YYYY-MM-DD" 형식으로 담습니다.
  const today = `${todayDate.getFullYear()}-${(todayDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${todayDate.getDate().toString().padStart(2, '0')}`;

  const eventKeywords = `${user?.result?.event_keywords || ''}`;
  // console.log('event: ', eventKeywords);
  const [eventData, setEventData] = useState(null);

  // -- 클릭시 상세 페이지 열기 -- //
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('행사일정 URL이 없습니다.');
    }
  };

  const fetchData = useCallback(async () => {
    const options = { division: '국내', keyword: eventKeywords };

    try {
      const [eventResult] = await Promise.all([event_schedule_api(options)]);
      setEventData(eventResult);
    } catch (error) {
      // Handle errors here
      console.error('Error Event Schedule : ', error);
    }
  }, [eventKeywords]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const handleContactButtonClick = () => {
  //   scroll.scrollTo(0, {
  //     //스크롤값
  //     duration: 100,
  //     delay: 0,
  //     smooth: 'easeInOutQuart',
  //     offset: 0,
  //     top: 0,
  //   });
  // };

  function calculateDateDifference(date1, date2) {
    // "yyyy-mm-dd" 형식의 문자열을 Date 객체로 변환
    const date1Obj = new Date(date1);
    const date2Obj = new Date(date2);

    // 두 날짜 간의 차이를 밀리초 단위로 계산
    const differenceInDays = Math.ceil((date1Obj - date2Obj) / (1000 * 60 * 60 * 24));

    // 차이가 양수면 현재 날짜 이후, 음수면 현재 날짜 이전
    const prefix = differenceInDays >= 0 ? 'D-' : 'D+';

    return `${prefix}${Math.abs(differenceInDays).toString().padStart(2, '0')}`;
  }

  return (
    <Container className="cont_wrap Laws event_wrap">
      <ContTitle className="cont_title d-between">
        {/* screen_edit 변수가 true 일때만 가려지는 css 적용 */}
        {screen_edit && <div className="arrange_wrap"></div>}

        <div className="d-flex">
          {/* 타이틀 */}
          <Text className="title3">행사 일정</Text>

          {/* 툴팁 */}
          <Tooltip data={tooltipValue} />
        </div>

        <div className="d-flex">
          {/* 더보기 버튼 */}
          {/* <Link to="/detail">
            <button className="plus_btn label4" onClick={handleContactButtonClick}>
              더보기 <img src={plus} alt="아이콘 이미지" />
            </button>
          </Link> */}
        </div>
      </ContTitle>

      {eventData && eventData.length > 0 ? (
        <div className="scroll">
          {eventData.map(item => (
            <div className="d-flex" key={item.id}>
              <ul>
                <li className="news_img">
                  <img
                    src={item.thumb_img_url}
                    onError={e => {
                      e.target.src = noImage;
                    }}
                    alt="이벤트img"
                  />
                </li>
              </ul>
              <ul
                className="body4"
                onClick={() => detailed_page_load(item)}
                style={{ cursor: 'pointer' }}
              >
                <li className="d-between">
                  <p className="gray2">
                    {item.event_schedule.length > 10
                      ? `${item.event_schedule.slice(0, 10)}`
                      : item.event_schedule}
                    &nbsp;&nbsp;{item.event_time}
                  </p>
                  <p className="primary-D">
                    {`${calculateDateDifference(
                      item.event_schedule.length > 10
                        ? item.event_schedule.slice(0, 10)
                        : item.event_schedule,
                      today,
                    )}`}
                  </p>
                </li>

                <Title className="label2 event_title" style={{ paddingTop: '2px' }}>
                  {item.event_name}
                </Title>

                <li className="d-between primary-D" style={{ paddingTop: '5px' }}>
                  {item.event_location}
                </li>
              </ul>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-news" style={{ height: '300px' }}>
          <img src={lockImg} alt="요약중 이미지" />
          <p className="label1">
            수집된 정보가 없습니다.
            <br />
            잠시만 기다려주세요.
          </p>
        </div>
      )}
    </Container>
  );
}

export default EventSchedule;
const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Title = styled.li`
  color: ${props => props.theme.colors.subTitleColor};
`;
