import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * google_keywords_api
 * 구글 RSS 데이터 (소셜 트렌드 현황)
 * https://trends.google.co.kr/trends/trendsearches/daily/rss?geo=KR
 * ─────────────────────────────────────────────────────────────
 */

export async function google_keywords_api() {
  // console.log('googlekeyword API start')

  const response = await fetch(`${BASE_URL}/google_keywords`);

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }
  const body = await response.json();
  // console.log(body);
  return body;
}
