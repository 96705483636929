//basic
import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

//api
import { news_api, news_summary } from '../../../apis/news_api.js';

//common
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

//components
import FieldKeywords from './news_keyword/FieldKeywords.js';
import NewsSummary from './news/NewsSummary.js';
import NewsList from './news/NewsList.js';

//external library
import Tooltip from '../../common/Tooltip.js';
import RegionButtons from '../../common/RegionButtons.js';

// img
import plus from '../../../assets/plus.png';

/* ───────────────────────────────────────────────────────────────
 * IndustryTrends : 뉴스, 주요 키워드 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function IndustryTrends(editBtn) {
  const screen_edit = editBtn.editBtn;

  const { user } = useAuth();

  const today = formatDateToYYYYmmdd(new Date());
  const tooltipValue =
    '<p><span class="primary">설정한 키워드</span>에 따라 자동으로 수집된 뉴스를 확인해보세요.<br />수집한 뉴스 데이터는 <span class="primary">AI</span>로 요약해드립니다.<br/>우측 상단 [아이콘]을 클릭하여 [설정 - 뉴스 검색 설정]에서 키워드를 설정해보세요.<br/><br/><span class="primary">[정보 출처]</span><br/>- 국내: 네이버 뉴스<br/>- 해외: New York Times<br/><br/><span class="primary">[수집 시간]</span><br/>- 국내: 07:00 ~ 10:00 수집<br/>- 해외: 07:00 ~ 10:00 수집</p>';

  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const [newsData, setNewsData] = useState(null);

  // console.log(newsData);
  // const tagOption = ['news_keywords_domestic', 'news_keywords_overseas'];
  // const [tagValue, setTagValue] = useState(tagOption[0]);

  const [summaryNewsData, setSummaryNewsData] = useState(null);

  const user_email = user.result.user_email || '';

  const domesticKeywords = user?.result?.news_keywords_domestic || '';
  const overseasKeywords = user?.result?.news_keywords_overseas || '';
  let newsKeywords = '';

  if (domesticKeywords && overseasKeywords) {
    newsKeywords = `${domesticKeywords},${overseasKeywords}`;
  } else if (domesticKeywords) {
    newsKeywords = domesticKeywords;
  } else if (overseasKeywords) {
    newsKeywords = overseasKeywords;
  }

  const handleRegionChange = region => {
    setSelectedRegion(region);

    setSelectedRegion(region === regionOption[1] ? regionOption[1] : regionOption[0]);
    // fetchData();
  };

  // -- 같은 조건으로 뉴스, 키워드 api 호출 -- //

  const fetchData = useCallback(async () => {
    const options = {
      division: selectedRegion,
      keyword: newsKeywords, // 사용 안하는데 일단 냅둠(2024 03 04)
      post_date: today,
      user_email: user_email,
      func: '요약',
      header: 'True',
    };

    try {
      const [newsResult, summaryResult] = await Promise.all([
        news_api(options),

        news_summary(options),
      ]);

      setNewsData(newsResult);

      setSummaryNewsData(summaryResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, newsKeywords, today, user_email]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container className="cont_wrap w980">
      <ContTitle className="cont_title d-between">
        {/* screen_edit 변수가 true 일때만 가려지는 css 적용 */}
        {screen_edit && <div className="arrange_wrap"></div>}

        <div className="d-flex">
          {/* 타이틀 */}
          <Text className="title3">업계 동향 및 경쟁 정보</Text>

          {/* 툴팁 */}
          <Tooltip data={tooltipValue} />
        </div>
        <div className="d-flex">
          {/* 국내/해외 */}
          <div className="sm_global_box">
            <RegionButtons
              options={regionOption}
              selectedRegion={selectedRegion}
              onRegionChange={handleRegionChange}
            />
          </div>

          {/* 더보기 버튼 */}
          <Link to="/detail">
            <PlusBtn className="plus_btn label4">
              더보기 <IconImg src={plus} alt="아이콘 이미지" />
            </PlusBtn>
          </Link>
        </div>
      </ContTitle>

      <div className="d-flex">
        <div className="NewsSummary scroll">
          <NewsSummary
            data={summaryNewsData}
            selectedRegion={selectedRegion}
            onRegionChange={handleRegionChange}
          />
        </div>

        <Border className="FieldKeywords">
          <FieldKeywords selectedRegion={selectedRegion} />
        </Border>

        <div className="NewsList scroll">
          <NewsList
            data={newsData}
            selectedRegion={selectedRegion}
            onRegionChange={handleRegionChange}
          />
        </div>
      </div>
    </Container>
  );
}

export default IndustryTrends;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Border = styled.div`
  border-left: 1px solid ${props => props.theme.colors.border};
  border-right: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const PlusBtn = styled.button`
  background: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.subTitleColor};
`;

const IconImg = styled.img`
  filter: ${props => props.theme.imgFilter};
`;
