// basic
import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import styled from 'styled-components';

// common
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';
import Tooltip from '../../common/Tooltip.js';

// api
import { reports_api } from '../../../apis/reports_api.js';

// components
import RegionButtons from '../../common/RegionButtons.js';

// css
import '../../../styles/Reports.css';

// img
import plus from '../../../assets/plus.png';
import lockImg from '../../../assets/lock_img03.png';
import move from '../../../assets/move.png';

/* ───────────────────────────────────────────────────────────────
 * Reports
 * 기업리포트 화면 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Reports(editBtn) {
  const screen_edit = editBtn.editBtn;

  const tooltipValue =
    '<p>자동으로 수집한 최신 기업 분석 보고서를 확인해보세요.<br />클릭 시 해당 분석 보고서를 제공하는 출처 페이지로 이동합니다.<br/><br/><span class="primary">[정보 출처]</span><br/>- 국내 보고서: 팍스넷<br/>- 해외 보고서: 미래에셋<br/><br/><span class="primary">[수집 시간]</span><br/>- 국내 보고서: 10:00 ~ 12:00 수집<br/>- 해외 보고서: 00:00 ~ 05:00 수집</p>';

  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  const [reportsData, setReportsData] = useState(null);
  // console.log(reportsData);

  //   해외 국내 분류
  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const handleRegionChange = region => {
    setSelectedRegion(region);
  };

  // -- 리포트 클릭시 상세 페이지 열기 -- //
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('기업 리포트 URL이 없습니다.');
    }
  };

  // 해외 일때는 한달기준 최신순 10개만 보이고 국내 일때는 10일전
  const year = parseInt(today.substring(0, 4));
  const month = parseInt(today.substring(4, 6)) - 1; // JavaScript의 월은 0부터 시작하므로 1을 빼줍니다.
  const day = parseInt(today.substring(6, 8));
  const today2 = new Date(year, month, day); // 문자열을 Date 객체로 변환
  today2.setDate(today2.getDate() - 30); // 현재 날짜에서 30일을 빼어 업데이트

  const fetchData = useCallback(async () => {
    const options = { post_date: today, division: selectedRegion };

    try {
      const [reportsResult] = await Promise.all([reports_api(options)]);
      // console.log(reportsResult);
      setReportsData(reportsResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, today]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleContactButtonClick = () => {
    scroll.scrollTo(4736, {
      //스크롤값
      duration: 100,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 0,
      top: 0,
    });
  };

  if (!reportsData || reportsData.length === 0) {
    // console.log('데이터가 없습니다.');
    return null; // 혹은 다른 처리 방법을 선택할 수 있습니다.
  }

  // name 값을 기준으로 객체를 그룹화하여 중복 제거
  const uniqueNameMap = reportsData.reduce((accumulator, currentValue) => {
    accumulator[currentValue.name] = currentValue;
    return accumulator;
  }, {});

  // 중복이 제거된 객체들을 배열로 변환
  const uniqueName = Object.values(uniqueNameMap);

  // console.log(uniqueName);

  return (
    <Container className="cont_wrap Laws report_wrap">
      <ContTitle className="cont_title d-between">
        {/* screen_edit 변수가 true 일때만 가려지는 css 적용 */}
        {screen_edit && <div className="arrange_wrap"></div>}

        <div className="d-flex">
          {/* 타이틀 */}
          <Text className="title3">기업 보고서</Text>

          {/* 툴팁 */}
          <Tooltip data={tooltipValue} />
        </div>

        <div className="d-flex">
          {/* 국내/해외 */}
          <div className="sm_global_box">
            <RegionButtons
              options={regionOption}
              selectedRegion={selectedRegion}
              onRegionChange={handleRegionChange}
            />
          </div>

          {/* 더보기 버튼 */}
          <Link to="/detail">
            <PlusBtn className="plus_btn label4" onClick={handleContactButtonClick}>
              더보기 <IconImg src={plus} alt="아이콘 이미지" />
            </PlusBtn>
          </Link>
        </div>
      </ContTitle>

      <table className="">
        <Thead className="label3">
          <Border>
            <th style={{ paddingLeft: '20px' }}>종목명</th>
            <th>출처</th>
            <th>상세</th>
          </Border>
        </Thead>

        {uniqueName && uniqueName.length > 0 ? (
          <tbody className="scroll body3">
            {uniqueName.map(item => (
              <Border key={item.id}>
                <TitleOver style={{ paddingLeft: '12px' }}>{item.name}</TitleOver>
                <TitleOver>{item.source}</TitleOver>
                <TitleOver onClick={() => detailed_page_load(item)}>
                  <IconImg src={move} alt="이동 아이콘 이미지" style={{ cursor: 'pointer' }} />
                </TitleOver>
              </Border>
            ))}
          </tbody>
        ) : (
          <div className="no-news" style={{ height: '240px' }}>
            <img src={lockImg} alt="요약중 이미지" />
            <p className="label1">
              수집된 정보가 없습니다.
              <br />
              잠시만 기다려주세요.
            </p>
          </div>
        )}
      </table>
    </Container>
  );
}

export default Reports;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const TitleOver = styled.td`
  color: ${props => props.theme.colors.titleColor};
`;

const Border = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const PlusBtn = styled.button`
  background: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.subTitleColor};
`;

const Thead = styled.thead`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const IconImg = styled.img`
  filter: ${props => props.theme.imgFilter};
`;
