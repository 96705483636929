import React, { useState, forwardRef, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthProvider';

// api
import { updateUserInfo, userSelectSub } from '../../apis/users_api.js';

// component
import SettingPopup from './Popup';

const UserSetting = forwardRef((props, ref) => {
  const { user } = useAuth();
  // console.log('------------', user.login_user); // user.login_user가 있다는건 "사용자" 권한으로 로그인 했다는 거.

  const [userInfo, setUserInfo] = useState({});
  const { id, user_name, user_email, user_company, user_department } = userInfo[0]
    ? userInfo[0]
    : user.result;

  // popup
  const [idCheck, setIdCheck] = useState(false);
  const [companyCheck, setCompanyCheck] = useState(false);
  const [teamCheck, setTeamCheck] = useState(false);
  const [pwCheck, setPwCheck] = useState(false);
  const [userChangeCheck, setUserChangeCheck] = useState(false);
  const [userError, setUserError] = useState(false);

  const nameInputRef = useRef();
  const [name, setName] = useState(user_name);

  const companyInputRef = useRef();
  const [company, setCompany] = useState(user_company);

  const departmentInputRef = useRef();
  const [department, setDepartment] = useState(user_department);

  const [mailTime, setMailTime] = useState(user.mail_receive_time);

  const mailCheckInputRef = useRef();
  const [mailCheck, setMailCheck] = useState(user.is_mail_received);

  // console.log(user);
  // console.log(mailTime);
  // console.log(mailCheck);

  // 메일시간이 널값이면 기본시간(11:00) 설정
  if (!mailTime) {
    setMailTime('11:00');
  }

  const handleNameChange = event => {
    const inputName = event.target.value;
    setName(inputName);
  };

  const handleCompanyChange = event => {
    const inputCompany = event.target.value;
    setCompany(inputCompany);
  };

  const handleDepartmentChange = event => {
    const inputDepartment = event.target.value;
    setDepartment(inputDepartment);
  };

  const handleEditBtnClick = async () => {
    if (!name) {
      setIdCheck(true);
      nameInputRef.current.focus();
      return;
    }

    if (!company) {
      setCompanyCheck(true);
      companyInputRef.current.focus();
      return;
    }

    if (!department) {
      setTeamCheck(true);
      departmentInputRef.current.focus();
      return;
    }

    const options = {
      user_name: name,
      user_company: company,
      user_department: department,
      user_role: user.role,
      mail_receive_time: mailTime,
      is_mail_received: mailCheck,
    };

    try {
      await updateUserInfo(id, options);
      setUserChangeCheck(true);
    } catch (error) {
      console.error('사용자 정보 업데이트 오류:', error);
      setUserError(true);
    }
  };

  const closePopup = () => {
    setIdCheck(false);
    setCompanyCheck(false);
    setTeamCheck(false);
    setPwCheck(false);
    setUserChangeCheck(false);
  };

  const handleMailTimeChange = event => {
    setMailTime(event.target.value);
  };

  const handleMailCheckboxChange = event => {
    const isChecked = event.target.checked;
    const value = isChecked ? 'Y' : 'N';
    setMailCheck(value);
  };

  const handleClick = () => {
    // 페이지 맨 위로 스크롤
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // console.log(user.role);
    if (user.role === '사용자') {
      const options = { input_name: 'email', user_email: user.login_user };
      const fetchData = async () => {
        try {
          const userData = await userSelectSub(options);
          setName(userData[0].user_name); // 자식 데이터 가져와서 바로 화면에 보이게 초기값으로
          setDepartment(userData[0].user_department); // 자식 데이터 가져와서 바로 화면에 보이게 초기값으로
          setUserInfo(userData);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchData();
    } else {
      setUserInfo(user.result);
    }
  }, [user.role, user.result, user.login_user]);

  return (
    <Container className="setting_box information_wrap" ref={newsRef => (ref.current[0] = newsRef)}>
      <Text className="heading1">개인 정보</Text>
      <Title className="body1" style={{ marginBottom: '50px' }}>
        개인정보를 변경할 수 있습니다.
      </Title>

      <div className="keyword_box">
        <SubTitle className="label1">이메일 계정</SubTitle>
        <Input type="text" id="disabledInput" value={user_email || ''} readOnly disabled />
      </div>

      <div className="keyword_box">
        <SubTitle className="label1">
          성명 <span className="point">*</span>
        </SubTitle>
        <Input id="name" value={name || ''} onChange={handleNameChange} ref={nameInputRef} />
      </div>

      <div className="keyword_box">
        <SubTitle className="label1">
          소속 회사 <span className="point">*</span>
        </SubTitle>

        <Input
          id="company"
          value={company || ''}
          onChange={handleCompanyChange}
          ref={companyInputRef}
          disabled={user.role === '사용자'}
        />
      </div>

      <div className="keyword_box">
        <SubTitle className="label1">
          소속 부서 <span className="point">*</span>
        </SubTitle>
        <Input
          id="department"
          value={department || ''}
          onChange={handleDepartmentChange}
          ref={departmentInputRef}
        />
      </div>

      <div className="keyword_box">
        <Title className="label1 gray2">[선택] TSB 정보 수신 알림(Bata.)</Title>
        <div className="d-flex" style={{ marginBottom: '5px' }}>
          <input
            className="emailCheck"
            type="checkbox"
            id="emailCheck"
            checked={mailCheck === 'Y'}
            ref={mailCheckInputRef}
            onChange={handleMailCheckboxChange}
          />
          <SubLabel for="emailCheck" className="body2 gray2">
            TSB에서 제공하는 소식을 이메일로 받아보실 수 있습니다.
          </SubLabel>
        </div>
        {mailCheck === 'Y' ? (
          <SubLabel className="body2 gray2" for="timeSelect">
            - 메일을{' '}
            <select id="timeSelect" name="time" value={mailTime} onChange={handleMailTimeChange}>
              <option value="10:00">10:00</option>
              <option value="11:00">11:00</option>
              <option value="12:00">12:00</option>
              <option value="13:00">13:00</option>
              <option value="14:00">14:00</option>
              <option value="15:00">15:00</option>
            </select>
            &nbsp;시에 받을게요.
          </SubLabel>
        ) : null}
      </div>

      <div className="d-end">
        <Btn onClick={handleEditBtnClick} className="setting_btn label1">
          설정 저장
        </Btn>
      </div>
      {idCheck && <SettingPopup close={closePopup} header="성명은 필수 항목 입니다." />}
      {companyCheck && <SettingPopup close={closePopup} header="소속 회사명은 필수 항목 입니다." />}
      {teamCheck && <SettingPopup close={closePopup} header="소속 부서명은 필수 항목 입니다." />}
      {pwCheck && <SettingPopup close={closePopup} header="비밀번호 형식이 올바르지 않습니다." />}
      <a href="/" onClick={handleClick}>
        {userChangeCheck && (
          <SettingPopup close={closePopup} header="입력하신 개인정보가 변경되었습니다." />
        )}
      </a>
      {userError && (
        <SettingPopup close={closePopup} header="사용자 정보 업데이트 중 오류가 발생했습니다." />
      )}
    </Container>
  );
});

export default UserSetting;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;
const Title = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;

const SubTitle = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const SubLabel = styled.label`
  color: ${props => props.theme.colors.navTitleColor};
`;

const Btn = styled.button`
  background: ${props => props.theme.colors.btnBg};
  color: ${props => props.theme.colors.bgColor};
`;

const Input = styled.input`
  background: ${props => props.theme.colors.tableBg};
  color: ${props => props.theme.colors.titleColor};
  border: 1px solid ${props => props.theme.colors.navTitleColor};
`;
