//basic
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

//api
import { legislation_api, legislation_summary_api } from '../../../apis/legislation_api.js';

//components
import Tooltip from '../../common/Tooltip.js';
import RegionButtons from '../../common/RegionButtons.js';
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

//img
import news from '../../../assets/news.png';
import lockImg from '../../../assets/lock_img03.png';
import download from '../../../assets/download.png';

/* ───────────────────────────────────────────────────────────────
 * Legislation
 * 규제 정보 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

export default function Legislation() {
  const tooltipValue =
    '<p>자동으로 수집하여 <span class="primary">AI</span>로 분류한 규제 정보를 확인해보세요.<br /><br /><span class="primary">[정보 출처]</span><br />- 한국행정연구원(KIPA) 최신 규제 동향<br /><br /><span class="primary">[수집 시간]</span><br />- 특허 정보: 00:00 ~ 05:00 수집</p>';

  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const today = formatDateToYYYYmmdd(new Date());

  const [data, setData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);

  const handleRegionChange = region => {
    setSelectedRegion(region);
  };

  // -- 같은 조건으로 규제, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { division: selectedRegion, summary_date: today, collection_date: today };

    try {
      const [legislationResult, summaryResult] = await Promise.all([
        legislation_api(options),
        legislation_summary_api(options),
      ]);

      setData(legislationResult);
      setSummaryData(summaryResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [today, selectedRegion]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const regionData = data ? data.filter(item => item.division === selectedRegion) : [];

  // -- 다운로드 버튼 클릭시 링크 열기 -- //
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('다운로드 파일이 없습니다.');
    }
  };

  return (
    <div className="legislation_wrap laws_wrap">
      <div className="d-flex">
        {/* 타이틀 */}
        <TextTitle>규제 정보</TextTitle>

        {/* 툴팁 */}
        <div className="tooltip_one">
          <Tooltip data={tooltipValue} />
        </div>
      </div>

      <Container className="container">
        <ContTitle className="sub_title d-between">
          <div className="d-flex">
            <img src={news} alt="뉴스 아이콘 이미지" />
            {selectedRegion === regionOption[0] ? (
              <Text className="title2">
                <span className="span">국내</span>규제 요약
              </Text>
            ) : (
              <Text className="title2">
                <span className="span">해외</span>규제 요약
              </Text>
            )}
          </div>

          {/* 국내/해외 */}
          <RegionButtons
            options={regionOption}
            selectedRegion={selectedRegion}
            onRegionChange={handleRegionChange}
          />
        </ContTitle>

        {/* 본문 */}
        <div className="laws_container scroll maker">
          {summaryData ? (
            <div>
              <Text className="label1">{summaryData.title}</Text>
              <Title
                className="body2 line-height"
                dangerouslySetInnerHTML={{
                  __html: summaryData.content,
                }}
              />
            </div>
          ) : (
            <div className="no-news" style={{ height: '390px' }}>
              <img src={lockImg} alt="요약중 이미지" style={{ marginTop: '50px' }} />
              <Text className="label1">
                수집된 정보가 없습니다.
                <br />
                잠시만 기다려주세요.
              </Text>
            </div>
          )}
        </div>

        {/* 다운로드 버튼 */}
        <div className="d-end">
          <span className="label3">출처 : KIPA규제동향</span>
          {regionData &&
            regionData.length > 0 &&
            regionData.map(item => (
              <div key={item.id}>
                <button onClick={() => detailed_page_load(item)} className="download">
                  <img src={download} alt="뉴스 아이콘 이미지" />
                  {/* {item.file_name} */}
                  다운로드
                </button>
              </div>
            ))}
          {(!regionData || regionData.length === 0) && null}
        </div>
      </Container>
    </div>
  );
}

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const TextTitle = styled.h1`
  color: ${props => props.theme.colors.titleColor};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;
const Title = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
`;
