import React, { useEffect } from 'react';
import styled from 'styled-components';

const KeywordPopup = props => {
  const { close, header, title, body, no, close_no, name, confirmText = '확인' } = props;

  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div className="popup_wrap">
      <Container className="popup">
        <ul>
          {title && <Title className="title2">{title}</Title>}
          {header && <Text className="body1">{header}</Text>}
          {body && (
            <>
              {' '}
              <Title>
                <br />
                {body}
              </Title>
            </>
          )}
        </ul>
        <div className="d-flex">
          <Btn className="close" onClick={close} name={name}>
            {confirmText}
          </Btn>
          {no && (
            <Btn className="close" onClick={close_no}>
              {no}
            </Btn>
          )}
        </div>
      </Container>
    </div>
  );
};

export default KeywordPopup;

const Container = styled.div`
  background: ${props => props.theme.colors.chartBg};
`;

const Title = styled.li`
  color: ${props => props.theme.colors.titleColor};
`;

const Text = styled.li`
  color: ${props => props.theme.colors.subTitleColor};
`;

const Btn = styled.button`
  border-top: 1px solid ${props => props.theme.colors.chartBorder};
  border-left: 1px solid ${props => props.theme.colors.chartBorder};
`;