export const light = {
  colors: {
    titleColor: '#0d1a43',
    navTitleColor: '#666',
    bgColor: '#f8fafb',
    navBg: '#fff',
    border: '#efefef',
    tableBg: '#f8fafb',
    chartBg: '#fff',
    chartBorder: '#efefef',
    btnBg: '#1eb3b8',
    settingBg: '#333',
  },
  imgFilter: 'invert(0%)',
};

export const dark = {
  colors: {
    titleColor: '#fff',
    logoColor: '#dff1f2',
    subTitleColor: '#cbcbcb',
    navTitleColor: '#cbcbcb',
    bgColor: '#333',
    navBg: '#3a3a3a',
    border: '#666',
    tableBg: '#666',
    chartBg: '#5f5f5f',
    chartBorder: '#4f4f4f',
    btnBg: '#2c959c',
    settingBg: '#fff',
  },
  imgFilter: 'invert(100%)',
};
