//basic
import React, { useEffect, useState } from 'react';

//api, common
import { naver_datalap_api } from '../../../apis/naver_datalap_api.js';
import { formatDate, addDaysToDate } from '../../../utils/dateFunction.js';

//external library
import { ResponsiveLine } from '@nivo/line';

//css/
import '../../../styles/Reset.css';
import '../../../styles/Common.css';
import '../../../styles/SocialTrend.css';

/* ───────────────────────────────────────────────────────────────
 * NaverKeyword
 * 네이버 키워드 그래프 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */
export default function NaverKeyword(parameter) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchDataAndLoad = async () => {
      try {
        const dataFromParam = parameter?.data?.param?.item;
        const changeIndex = parameter?.data?.changeIndex;

        if (dataFromParam) {
          const topTitle = dataFromParam[changeIndex]?.title;
          const trimmedKeywordOne =
            typeof topTitle[0] === 'string'
              ? topTitle[0].replace(/\s/g, '')
              : topTitle[0].replace(/\s/g, '');

          const today = formatDate(new Date());
          const oneYearAgo = addDaysToDate(today, -7);

          //혼성
          const loadData = await naver_datalap_api({
            startDate: oneYearAgo,
            endDate: today,
            timeUnit: 'date',
            keywordGroups: [
              {
                groupName: trimmedKeywordOne,
                keywords: [trimmedKeywordOne],
              },
            ],
            device: 'pc',
            gender: '',
          });

          // console.log('loadData:', loadData);
          setData(loadData);
        }
      } catch (error) {
        console.error('그래프 에러 발생:', error);
      }
    };

    fetchDataAndLoad();
  }, [parameter]);

  return (
    <div>
      {/* {data && data.results[0].data.length >= 2 ? ( */}
      {data && (
        <div className="navertrend_wrap d-flex">
          {/* 키워드 */}
          <div className="naver_box">
            <div style={{ width: '450px', height: '350px' }}>
              {data && (
                <ResponsiveLine
                  data={data.results.map((result, index) => ({
                    id: result.title,
                    data: result.data.map(item => ({
                      x: item.period,
                      y: item.ratio,
                    })),
                    item: {
                      id: result.title,
                      value: result.title,
                    },
                  }))}
                  margin={{ top: 50, right: 50, bottom: 80, left: 50 }}
                  xScale={{ type: 'point' }}
                  yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false, //데이터 쌓아서표현할지 따로표현할지
                    reverse: false, //축방향반전
                  }}
                  axisBottom={{
                    //legend: 'Value',
                    orient: 'left',
                    tickRotation: -45,
                    legendOffset: 0,
                    legendPosition: 'middle',
                    tickSize: 0,
                    tickPadding: 10, //x축범례가 얼마나 떨어지나
                  }}
                  colors={['#2C959C']}
                  legends={[
                    {
                      anchor: 'top-left',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemTextColor: '#81dbd1',
                      itemWidth: 60,
                      itemHeight: 20, //범례표시가 그래프위로 얼마에있나
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  enableSlices="x"
                  axisLeft={{
                    // legend: 'Value',
                    legendOffset: 0,
                    legendPosition: 'middle',
                    tickSize: 0,
                    tickPadding: 20, //그래프랑 세로범례가 얼마나 떨어지나
                  }}
                />
              )}
            </div>
          </div>
        </div>
        // ) : (
        //   <div>현재 데이터 수집 중입니다.</div>
      )}
    </div>
  );
}
