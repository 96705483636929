import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * patent_api
 * 키프리스 특허 정보 데이터 (특허 정보) // 국내, 해외
 * http://kpat.kipris.or.kr/kpat/searchLogina.do?next=MainSearch
 * ─────────────────────────────────────────────────────────────
 */

export async function patent_api(options) {
  let query = null;

  if (options.collection_date) {
    query = `collection_date=${options.collection_date}`;
  }

  if (options.division) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query ? `${query}&division=${options.division}` : `division=${options.division}`;
  }

  if (options.keyword) {
    // 이미 query가 있으면 &를 추가하고, 없으면 그대로 할당
    query = query
      ? `${query}&search_keyword=${options.keyword}`
      : `search_keyword=${options.keyword}`;
  }

  try {
    const response = await fetch(`${BASE_URL}/patents/?${query}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}
