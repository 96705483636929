// basic
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import { animateScroll as scroll } from 'react-scroll';

// api
import { techmeme_api } from '../../../apis/techmeme_api.js';

// components
import Tooltip from '../../common/Tooltip.js';

//common
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// img
import techmemeLogo from '../../../assets/techmeme.png';
import lockImg from '../../../assets/lock_img03.png';
// import plus from '../../../assets/plus.png';

/* ───────────────────────────────────────────────────────────────
 * Invests : 투자 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Techmeme(editBtn) {
  const screen_edit = editBtn.editBtn;

  const tooltipValue =
    '<p>자동으로 수집하고 AI로 번역한 기술 뉴스 전문 종합지 ‘테크미미’ 소식을 확인해보세요.<br />소식을 클릭하면 해당 원문 페이지로 이동합니다.<br/><br/><span class="primary">[정보 출처]</span><br/>- 테크미미(Techmeme)<br/><br/><span class="primary">[수집 시간]</span><br/>- 기술 뉴스: 00:00 ~ 05:00 수집</p>';

  const [techmemeData, setTechmemeData] = useState(null);

  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  // console.log(techmemeData);

  // 테크미미 페이지 열기
  const detailed_page_load = item => {
    const { tech_post_url } = item;
    if (tech_post_url) {
      window.open(tech_post_url, '_blank');
    } else {
      alert('해당 URL이 없습니다.');
    }
  };

  // -- 같은 조건으로 투자, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { collection_date: today, return_size: 50 }; // 최신순으로 50개만 리턴 받게 함

    try {
      const [techmemeResult] = await Promise.all([techmeme_api(options)]);
      setTechmemeData(techmemeResult);
    } catch (error) {
      console.error('Error techmeme data:', error);
    }
  }, [today]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const handleContactButtonClick = () => {
  //   scroll.scrollTo(0, {
  //     //스크롤값
  //     duration: 100,
  //     delay: 0,
  //     smooth: 'easeInOutQuart',
  //     offset: 0,
  //     top: 0,
  //   });
  // };

  function formatDate(dateString) {
    // "yyyymmdd"를 "yyyy-mm-dd"로 변경
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${year}.${month}.${day}`;
  }

  return (
    <Container className="cont_wrap techmeme_wrap">
      <ContTitle className="cont_title d-between">
        {/* screen_edit 변수가 true 일때만 가려지는 css 적용 */}
        {screen_edit && <div className="arrange_wrap"></div>}

        <div className="d-flex">
          {/* 타이틀 */}
          <h1 className="title3">
            <img src={techmemeLogo} alt="로고 이미지" />
          </h1>

          {/* 툴팁 */}
          <Tooltip data={tooltipValue} />
        </div>

        {/* 더보기 버튼 */}
        {/* <Link to="/detail">
          <button className="plus_btn label4" onClick={handleContactButtonClick}>
            더보기 <img src={plus} alt="아이콘 이미지" />
          </button>
        </Link> */}
      </ContTitle>

      {techmemeData && techmemeData.length > 0 ? (
        <div className="scroll techmeme_box_wrap">
          {techmemeData.map(item => (
            <div key={item.id}>
              <Bg className="techmeme_box body3" onClick={() => detailed_page_load(item)}>
                <Title>{item.tech_media}</Title>
                <Title className="label2">
                  {item.tech_writer && (
                    <TitleName className="label2">/{item.tech_writer}</TitleName>
                  )}
                </Title>
                {/* 얘사람이름이라서 있으면 "/이름" 들어가고 null이면 안나오게함 */}
                <Title className="gray3">
                  {formatDate(item.tech_date)}&nbsp;{item.tech_time}
                </Title>
                <Text>{item.tech_content_translation || 'translation data null'}</Text>
              </Bg>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-news" style={{ height: '300px' }}>
          <img src={lockImg} alt="요약중 이미지" />
          <p className="label1">
            수집된 정보가 없습니다.
            <br />
            잠시만 기다려주세요.
          </p>
        </div>
      )}
    </Container>
  );
}

export default Techmeme;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Title = styled.li`
  color: ${props => props.theme.colors.titleColor};
`;
const TitleName = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const Bg = styled.ul`
  background-color: ${props => props.theme.colors.tableBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.li`
  color: ${props => props.theme.colors.subTitleColor};
`;