import { useState, useEffect } from 'react';
import styled from 'styled-components';

const ToggleComponent = styled.div`
  width: 50px;
  position: relative;
  cursor: pointer;

  > .toggle-container {
    width: 50px;
    height: 24px;
    border-radius: 30px;
    background-color: rgb(233, 233, 234);
  }

  //.toggle--checked 클래스가 활성화 되었을 경우의 CSS를 구현
  > .toggle--checked {
    background-color: #1eb3b8;
    transition: 0.5s;
  }

  > .toggle-circle {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: rgb(255, 254, 255);
    transition: 0.5s;
  }

  //.toggle--checked 클래스가 활성화 되었을 경우의 CSS를 구현
  > .toggle--checked {
    left: 27px;
    transition: 0.5s;
  }
`;

export const Toggle = ({ index, onClick, array }) => {
  // console.log('index', index);
  // console.log('array', array); //배열로넘어옴 ['1','2']

  const [isOn, setisOn] = useState(true);

  // if (array.includes(String(index))) {
  //   console.log('인덱스와 일치하는 값이 있습니다.', index);
  //   setisOn(false);
  // }
  useEffect(() => {
    if (array.includes(String(index))) {
      // console.log('일치할때 꺼짐ㅇ');
      setisOn(false);
    } else {
      // 포함되어 있지 않다면 토글을 활성화시킵니다.
      setisOn(true);
    }
  }, [array, index]);

  const toggleHandler = () => {
    setisOn(!isOn);
    onClick();
  };

  return (
    <>
      <ToggleComponent onClick={toggleHandler}>
        <div className={`toggle-container ${isOn ? 'toggle--checked' : null}`} />
        <div className={`toggle-circle ${isOn ? 'toggle--checked' : null}`} />
      </ToggleComponent>
    </>
  );
};
