import { useEffect, useRef } from 'react';

// -- 페이지네이션 -- //
import Pagination from 'react-js-pagination';

// -- css -- //
import '../../styles/Paging.css';

const Paging = ({ items, page, handlePaging }) => {
  const secondLastPageButtonRef = useRef(null);

  // 이전 버튼 스타일을 추가하는 함수

  const addCustomClassToPagination = () => {
    const paginationList = document.querySelectorAll('ul.pagination li');
    const secondLastPageButton = paginationList[paginationList.length - 2];
    if (paginationList) {
      secondLastPageButtonRef.current = secondLastPageButton; // Ref에 버튼 요소 저장
    }
  };

  // 페이지 변경 시 클래스 재설정
  useEffect(() => {
    addCustomClassToPagination();
  }, [page]);

  return (
    <Pagination
      activePage={page} // 현재 페이지
      itemsCountPerPage={5} // 한 페이지당 보여줄 리스트 아이템의 개수
      totalItemsCount={items.length} // 총 아이템의 개수
      pageRangeDisplayed={5} // Paginator 내에서 보여줄 페이지의 범위
      prevPageText={'‹'}
      nextPageText={'›'}
      onChange={handlePaging} // 페이지가 바뀔 때 핸들링 해줄 함수
    />
  );
};

export default Paging;
