// basic
import React from 'react';
import styled from 'styled-components';

// img
import lockImg from '../../../assets/lock_img03.png';

/* ───────────────────────────────────────────────────────────────
 * KeywordsList
 * 투자 정보 키워드를 표시하는 컴포넌트, 공통 키워드 표시 컴포넌트랑 다름
 * ───────────────────────────────────────────────────────────────
 */

const KeywordsList = ({ keywords }) => {
  const displayKeywordsAndRank = keywords => {
    if (!keywords) {
      return (
        <tr className="no-news" style={{ width: '230px', height: '450px' }}>
          <td>
            <img src={lockImg} alt="요약중 이미지" />
          </td>
          <td className="label1">
            수집된 정보가 없습니다.
            <br />
            잠시만 기다려주세요.
          </td>
        </tr>
      );
    }

    const keywordArray = keywords.split(',');

    return (
      <>
        {Array.from({ length: 10 }).map((_, index) => (
          <tr key={index} className="d-flex" style={{ paddingTop: '13px' }}>
            <td className={`num body3 d-center ${index + 1 <= 3 ? 'highlight' : ''}`}>
              {index + 1}
            </td>
            <SubText className="body2">{index < keywordArray.length ? keywordArray[index] : '-'}</SubText>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="Keywords_list">
      <table>
        <tbody>{displayKeywordsAndRank(keywords)}</tbody>
      </table>
    </div>
  );
};

export default KeywordsList;

const SubText = styled.td`
  color: ${props => props.theme.colors.subTitleColor};
`;
