import { Helmet } from 'react-helmet';
import { React, useState, useEffect, useMemo } from 'react';

import IndustryTrends from '../../components/homePage/news&keyword/IndustryTrends';
import MarketStockStatus from '../../components/homePage/market&stock/MarketStockStatus';
import Patent from '../../components/homePage/patent/Patent';
import Legislation from '../../components/homePage/legislation/Legislation';
import Laws from '../../components/homePage/law/Laws';
import Invests from '../../components/homePage/invests/Invest';
import Reports from '../../components/homePage/report/Report';
import SocialTrend from '../../components/homePage/socialTrend/SocialTrend';
import EventSchedule from '../../components/homePage/eventSchedule/EventSchedule';
import Techmeme from '../../components/homePage/techmeme/techmeme';
// import Precedent from '../../components/homePage/ulex/Precedent.js';
// import LawQna from '../../components/homePage/ulex/LawQna.js';
// import RecommandLaws from '../../components/homePage/ulex/RecommandLaws.js';
// import LawSearch from '../../components/homePage/ulex/LawSearch.js';

import ScreenEditBtn from '../../components/common/ScreenEditBtn.js';

import Footer from '../../components/common/Footer';

// api
import { screen_index } from '../../apis/tsb_setting_api';

//css
import '../../styles/HomePage.css';

// contexts
import { useAuth } from '../../contexts/AuthProvider.js';

// 컴포넌트 움직이는 라이브러리.
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function HomePage() {
  const { user } = useAuth();
  const { id } = user.result;

  // console.log(user.role);

  const [editBtn, setEditBtn] = useState(false);
  // console.log(editBtn)

  let user_screen_index = user.result.screen_index;
  // console.log(user_screen_index); // TSB 유저 디비에 설정된 화면 순서.
  let user_screen_onoff = user.result.screen_onoff;

  // 화면 설정이 되어있지 않으면 기본 값으로
  if (!user_screen_index) {
    user_screen_index = '0,1,2,3,4,5,6,7,8,9,10';
  }

  const testArray = useMemo(
    () => user_screen_index.split(',').map(item => item.trim()),
    [user_screen_index],
  ); // 문자열을 쉼표로 분리하여 배열로 변환
  // console.log('그냥하면', testArray);

  // const user_screen_id = useMemo(() => testArray.map(item => `${item}`), [testArray]); // 숫자 주변에 작은따옴표 추가

  // console.log(user_screen_id); // 화면에 뿌려지는 컴포넌트 순서.(배열)

  const onoff_array = useMemo(
    () => user_screen_onoff.split(',').map(item => item.trim()),
    [user_screen_onoff],
  );

  // console.log('전체배열:: ', testArray);
  // console.log('온오프배열:: ', onoff_array);

  const screen_option = useMemo(
    () => [
      { id: '0', component: <IndustryTrends editBtn={editBtn} /> },
      { id: '1', component: <MarketStockStatus editBtn={editBtn} /> },
      { id: '2', component: <Patent editBtn={editBtn} /> },
      { id: '3', component: <Legislation editBtn={editBtn} /> },
      { id: '4', component: <Laws editBtn={editBtn} /> },
      { id: '5', component: <Invests editBtn={editBtn} /> },
      { id: '6', component: <Reports editBtn={editBtn} /> },
      { id: '7', component: <SocialTrend editBtn={editBtn} /> },
      { id: '8', component: <EventSchedule editBtn={editBtn} /> },
      { id: '9', component: <Techmeme editBtn={editBtn} /> },
      // { id: '10', component: <Precedent editBtn={editBtn}/> }, // 개발 되고 자물쇠 없앨때 활성화
      // { id: '11', component: <Ulex editBtn={editBtn}/> },
      // { id: '12', component: <RecommandLaws editBtn={editBtn}/> },
      // { id: '13', component: <LawSearch editBtn={editBtn}/> },
    ],
    [editBtn],
  );

  const [components, setComponents] = useState([]);
  const [components2, setComponents2] = useState([]);

  // components 배열에서 id 값만 추출하여 콘솔에 출력하고 배열 벗겨주기
  // console.log(components.id);
  // const ids = components.map(item => item.id).join(',');
  // console.log(ids);

  // screen_index(id, ids)
  //   .then(result => {
  //     console.log(result); // 결과 출력
  //   })
  //   .catch(error => {
  //     console.error('Error updating screen index:', error);
  //   });
  // console.log(ids);
  // console.log(id);

  useEffect(() => {
    const filteredArray = testArray.filter(item => !onoff_array.includes(item));
    // console.log('뺀배열:: ', filteredArray);

    const sortedComponents = filteredArray
      .map(id => {
        const screen = screen_option.find(option => option.id === id);
        return screen ? screen : null;
      })
      .filter(screen => screen !== null);

    // 사용자순서ID를 SCREEN_OPTION에서의ID와 비교해 같으면 그걸 통째로 SCREEN에 쳐넣음
    // 그걸다시 SET함
    setComponents(sortedComponents);
  }, [screen_option, testArray, onoff_array]);

  const onDragEnd = result => {
    if (!result.destination) return;

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    const updatedComponents = [...components];

    //startIndex에서 시작하여 1개요소제거 removed에 할당
    const [removed] = updatedComponents.splice(startIndex, 1);

    //endIndex에 해당하는 위치 이전에 제거한 요소(removed)를 삽입,
    //슬라이스속성으로 두번째변수0은 제거하지않고 삽입, 위치이동
    updatedComponents.splice(endIndex, 0, removed);

    const sortedComponents = onoff_array
      .map(id => {
        const screen = screen_option.find(option => option.id === id);
        return screen ? screen : null;
      })
      .filter(screen => screen !== null);

    const updatedComponentsWithOnOffArray = [...updatedComponents, ...sortedComponents];
    // console.log(updatedComponents);
    // console.log(onoff_array);
    // console.log('----------------', updatedComponentsWithOnOffArray);
    //움직임으로 바뀐 클론배열을 다시 컴포넌트에쳐넣음
    setComponents(updatedComponents);
    setComponents2(updatedComponentsWithOnOffArray);
  };

  const handleEditBtn = () => {
    if (editBtn) {
      setEditBtn(false);
      // console.log('1111', components);
      window.location.reload();
    } else {
      setEditBtn(true);
      // console.log('2222', components);
    }
  };

  useEffect(() => {
    // 컴포넌트가 업데이트될 때마다 호출되며, components 배열이 변경될 때마다 실행됩니다.
    const ids = components2.map(item => item.id).join(',');
    // console.log('합칭거 :: ', ids); //string

    // 저장 요청을 보냅니다.
    screen_index(id, ids)
      .then(result => {
        // console.log(result); // 결과 출력
      })
      .catch(error => {
        console.error('Error updating screen index:', error);
      });
  }, [components2, id]);

  return (
    <>
      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>Rainbowbrain Trend Sensing Board</title>
      </Helmet>

      <div className="homepage">
        <div className="home_container">
          {editBtn ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {components.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            id={item.id}
                            className="home_box"
                          >
                            {item.component}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div>
              {components.map((item, index) => (
                <div key={item.id} id={item.id} className="home_box">
                  {item.component}
                </div>
              ))}
            </div>
          )}
          {/* <div className="inlineFlex">
            <Precedent />
            <LawSearch />
          </div>
          <div className="inlineFlex">
            <RecommandLaws />
            <LawQna />
          </div> */}
        </div>
      </div>

      {/* 관리자 계정으로 로그인 했을때만 보임 */}
      {user.role === '관리자' && <ScreenEditBtn editbtn={editBtn} onClick={handleEditBtn} />}

      <Footer />
    </>
  );
}

export default HomePage;
