// -- basic -- //
import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * tsb_settings 테이블
    세팅 기준값 가져오는 api
 * ─────────────────────────────────────────────────────────────
 */
export async function settings_api(options = {}) {
  try {
    let division = '';
    let category = '';

    // const params = { division: division, category: category };

    const url = new URL(`${BASE_URL}/tsb-settings`);
    if (options.division) {
      division = options.division;
      url.searchParams.append('division', division);
    }
    if (options.category) {
      category = options.category;
      url.searchParams.append('category', category);
    }

    const response = await fetch(url, {
      method: 'GET', // 또는 'PATCH'에 따라서 사용
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }
    const body = await response.json();
    return body;
  } catch (error) {
    console.error('error get setting words :', error);
  }
}

export async function stock_codes_api() {
  try {
    const url = new URL(`${BASE_URL}/stock_crop_codes`);

    const response = await fetch(url, {
      method: 'GET', // 또는 'PATCH'에 따라서 사용
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }
    const body = await response.json();
    return body;
  } catch (error) {
    console.error('error get setting words :', error);
  }
}
