import React, { useState, useEffect } from 'react';

// img
import noImage from '../../../assets/no-image.png';

const DashStock = ({ data }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [flattenedItems, setFlattenedItems] = useState([]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const items = Object.values(data).flatMap(arr => arr);
      setFlattenedItems(items);
    }
  }, [data]);

  useEffect(() => {
    if (flattenedItems.length > 0) {
      const interval = setInterval(() => {
        setStartIndex(prevIndex => (prevIndex + 1) % flattenedItems.length);
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [flattenedItems]);

  const getVisibleItems = () => {
    if (flattenedItems.length === 0) return [];

    if (flattenedItems.length >= 4) {
      // 데이터가 4개 이상일 경우, 4개를 순서대로 가져오기
      return flattenedItems
        .slice(startIndex, startIndex + 4)
        .concat(flattenedItems.slice(0, Math.max(0, 4 - (flattenedItems.length - startIndex))));
    } else {
      // 데이터가 4개 미만일 경우, 중복 없이 표시하기
      const uniqueItems = [];
      for (let i = 0; i < flattenedItems.length; i++) {
        uniqueItems.push(flattenedItems[(startIndex + i) % flattenedItems.length]);
      }
      return uniqueItems;
    }
  };

  const visibleItems = getVisibleItems();

  if (visibleItems.length === 0) {
    return <p className="label2">No Data</p>;
  }

  const { graph_img_url } = visibleItems[0] || {};

  return (
    <div className="dash_stock_wrap d-flex">
      <div className="table_wrap_dash">
        <table>
          <thead>
            <tr>
              <th className="label2">종목</th>
              <th className="label2">현재가</th>
              <th className="label2">전일대비</th>
            </tr>
          </thead>
          <tbody>
            {visibleItems.map((item, index) => {
              const { company_name, stock_price, increase_rate, increase_status } = item;
              const icon =
                increase_status === '상승' ? '▲' : increase_status === '하락' ? '▼' : '-';
              const statusClass =
                increase_status === '상승' ? 'up' : increase_status === '하락' ? 'down' : 'same';
              const rowClass = index === 0 ? 'highlight' : '';

              return (
                <tr key={index} className={rowClass}>
                  <td className="company_name">
                    <p className="label2">{company_name}</p>
                  </td>
                  <td className="label2">{stock_price}원</td>
                  <td className={`label2 ${statusClass}`}>
                    {icon} {increase_rate}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="stock_img_wrap">
        <div className="stock_img">
          <img src={graph_img_url || noImage} alt="주식그래프" />
        </div>
      </div>
    </div>
  );
};

export default DashStock;
