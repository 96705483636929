// basic
import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { animateScroll as scroll } from 'react-scroll';

// api
import { invests_api, invests_keyword_api } from '../../../apis/invests_api.js';

// utils
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// components
import InvestsContent from './InvestsContent.js';
import Tooltip from '../../common/Tooltip.js';
import KeywordsList from './InvestsKeyword.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

// img
import plus from '../../../assets/plus.png';

/* ───────────────────────────────────────────────────────────────
 * Invests : 투자 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Invests(editBtn) {
  const screen_edit = editBtn.editBtn;

  const tooltipValue =
    '<p>자동으로 수집한 신규 투자 유치 최신 정보를 확인해보세요.<br /><span class="primary">AI</span>가 분석한 <span class="primary">키워드</span>를 통해 현재 스타트업 트렌드를 파악할 수 있습니다.<br/>업종을 변경하려면 우측 상단 [아이콘]을 클릭하여 [설정 - 투자 정보 설정]으로 이동하세요.<br/><br/><span class="primary">[정보 출처]</span><br/>- 투자 정보: 혁신의숲<br/><br/><span class="primary">[수집 시간]</span><br/>- 투자 정보: 00:00 ~ 05:00 수집</p>';

  const { user } = useAuth();

  const user_invests_keyword = user.result.invests_keywords;

  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  const [investsData, setInvestsData] = useState(null);
  const [investsKeyword, setInvestsKeyword] = useState(null);

  // console.log(user_invests_keyword);
  // console.log(investsData);

  // -- 같은 조건으로 투자, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { collection_date: today, division: user_invests_keyword };

    try {
      const [investsResult, keywordResult] = await Promise.all([
        invests_api(options),
        invests_keyword_api(options),
      ]);

      setInvestsData(investsResult);
      setInvestsKeyword(keywordResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [today, user_invests_keyword]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleContactButtonClick = () => {
    scroll.scrollTo(4149, {
      //스크롤값
      duration: 100,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 0,
      top: 0,
    });
  };

  return (
    <Container className="Patent cont_wrap invests_wrap">
      {/* screen_edit 변수가 true 일때만 가려지는 css 적용 */}
      {screen_edit && <div className="arrange_wrap"></div>}

      <ContTitle className="cont_title d-between">
        <div className="d-flex">
          {/* 타이틀 */}
          <Text className="title3">
            <span className="primary">{user_invests_keyword}</span> 투자 정보
          </Text>

          {/* 툴팁 */}
          <Tooltip data={tooltipValue} />
        </div>

        {/* 더보기 버튼 */}
        <Link to="/detail">
          <PlusBtn className="plus_btn label4" onClick={handleContactButtonClick}>
            더보기 <IconImg src={plus} alt="아이콘 이미지" />
          </PlusBtn>
        </Link>
      </ContTitle>

      <div className="d-flex">
        <Border className="keyword_list_wrap">
          <ul className="keyword_list">
            <li>
              <Text className="keyword_title label3">최근 투자 기업 주요 키워드</Text>
              <KeywordsList
                keywords={
                  investsKeyword && investsKeyword.length > 0 ? investsKeyword[0].keywords : null
                }
              />
            </li>
          </ul>
        </Border>

        <div className="table_wrap">
          {/* 표 */}
          <InvestsContent data={investsData} />
        </div>
      </div>
    </Container>
  );
}

export default Invests;

const Container = styled.div`
  background: ${props => props.theme.colors.navBg};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ContTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const Text = styled.p`
  color: ${props => props.theme.colors.titleColor};
`;

const PlusBtn = styled.button`
  background: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.subTitleColor};
`;

const IconImg = styled.img`
  filter: ${props => props.theme.imgFilter};
`;

const Border = styled.div`
  border-right: 1px solid ${props => props.theme.colors.border};
`;
