import styled from 'styled-components';

// css
import '../../../../styles/News.css';

// img
import lockImg from '../../../../assets/lock_img01.png';

/* ───────────────────────────────────────────────────────────────
 * RelatedNewsLoad : 관련 뉴스 리스트 표시하는 컴포넌트
 * 부모 컴포넌트에서 전달받은 item.lable 값으로 뉴스 api 호출해서 화면에 뿌립니다.
 * ───────────────────────────────────────────────────────────────
 */

const NewsLoadMore = ({ data, selectedRegion }) => {
  const pageLoad = data => {
    if (data.detail_page_url) {
      window.open(data.detail_page_url, '_blank');
    } else {
      alert('수집된 뉴스 URL이 없습니다.');
    }
  };

  return (
    <div className="news_related">
      {data ? (
        <ul>
          {data
            .filter(relatedItem => relatedItem.division === selectedRegion)
            // Use slice(1) to exclude the first item
            .slice(0)
            .map((relatedItem, index) => (
              <li key={index} onClick={() => pageLoad(relatedItem)}>
                <Title className="body4">
                  {relatedItem.media} &nbsp; &#124; &nbsp;{relatedItem.title}
                </Title>
              </li>
            ))}
        </ul>
      ) : (
        <div className="no-news">
          <img src={lockImg} alt="요약중 이미지" />
          <Title className="label1">
            최신 뉴스 정보를
            <br />
            요약 중입니다.
          </Title>
        </div>
      )}
    </div>
  );
};

export default NewsLoadMore;

const Title = styled.p`
  color: ${props => props.theme.colors.subTitleColor};
  border-top: 1px solid ${props => props.theme.colors.border};
`;
