import Options from './Options';

const GeneralOptions = props => {
  const options = [
    {
      name: '처음으로 돌아가기', // 이게 질문 버튼 이름임 (1-5번)
      handler: props.actionProvider.handleChatAnswers_01_06, // 답변 실행 함수 임(ActionProvider.js)
      id: 22, // 버튼 나오는 순서
    },
  ];
  return <Options options={options} title="Options" {...props} />;
};

export default GeneralOptions;
